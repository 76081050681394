import '../../style/internaltemplate.css';

import { Badge, Divider } from 'antd';
import React, { Fragment, ReactNode, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { AiFillSetting } from 'react-icons/ai';
import { FaArchive, FaBuffer, FaHome, FaRegBuilding, FaSignOutAlt, FaUsers } from 'react-icons/fa';
import { GiHistogram } from 'react-icons/gi';
import { NavLink } from 'react-router-dom';

import logo_bevi from '../../assets/images/bevi_logo.png';
import logoJapanese1 from '../../assets/images/logo-japanese-1.png';
import sokaLogoJapanese from '../../assets/images/soka-logo.jpg';
import { BeviFormattedLabel } from '../../components/BeviFormattedLabel';
import { LanguageSwitch } from '../../components/LanguageSwitch';
import {
    DASHBOARD_PAGE, FINANCIAL_REPORT_PAGE, LIST_USERS_PAGE, LOGIN_PAGE, ORGANIZATION_PAGE,
    PROGRAMS_PAGE, REPORT_PAGE, REPORT_PERMISSION, SAMPLE_DATA_REPORT_PAGE, TWO_FACTOR_AUTHENTICATOR, USER_PROFILE_PERMISSION, VIRTUAL_ACCOUNT
} from '../../router/pages';
import { TokenInfo } from '../../services/loginService';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { HasPermissionCapability, HasPermissionCapabilityProgramAdmin, IsAccountManager, IsInstitutional, IsParticipant, IsProgramAdmin, IsSuperAdmin } from '../../utils/userHelper';
import { MenuItem } from './components/MenuItem';
import { maintenanceStartDate, maintenanceEndDate } from '../../config/constants';
import { GetDate } from '../../utils/dateHelper';
import { UserPermissions } from '../../services/usersService';
import { ComparativeIndividualReportActions, ComparativeIndividualReportProps, comparativeIndividualReportLogic } from '../../redux/comparativeIndividualReportLogic';
import { useActions, useValues } from 'kea';

type Props = {
    userInfo: TokenInfo;
    component: ReactNode;
    isJapanese: boolean;
    onDashboardClicked: () => void;
    onLogoutClicked: () => void;
};

export const InternalTemplate = (
    { userInfo, isJapanese, component, onDashboardClicked, onLogoutClicked }: Props
): JSX.Element => {

    const { reportPermissionAmount }: ComparativeIndividualReportProps = useValues(comparativeIndividualReportLogic);

    const {
        getReportPermissionAmount
    }: ComparativeIndividualReportActions = useActions(comparativeIndividualReportLogic);

    useEffect(() => {
        if (IsParticipant(userInfo)) {
            getReportPermissionAmount();
        }
    }, [userInfo, getReportPermissionAmount])

    console.log(userInfo)

    return (
        <div className='internal-template-container'>
            <header className='internal-template-header shadow-md'>
                <div className='internal-template-header-row justify-content-between'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <NavLink to={LOGIN_PAGE}><Image src={logo_bevi} className='header__logo' /></NavLink>
                        {
                            isJapanese &&
                            <Fragment>
                                <Divider type={'vertical'} />
                                <Image src={logoJapanese1} className='header__logo' />
                                <Divider type={'vertical'} />
                                <Image src={sokaLogoJapanese} className='header__logo' />
                            </Fragment>
                        }
                    </div>
                    <div className='d-flex dropdown ml-auto'>
                        <LanguageSwitch />
                    </div>
                </div>
                <hr className='hr--gradient' />
            </header>

            <nav className='internal-template-nav-menu shadow-lg'>
                <div className='card border-0'>
                    {
                        maintenanceStartDate && maintenanceEndDate &&
                        <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.internaltemplate.system.maintenance_time').replace("{0}", GetDate(maintenanceStartDate)).replace("{1}", GetDate(maintenanceEndDate)) }} />
                    }
                    <h6>
                        {GetFormattedMessage('app.internaltemplate.welcome')}, {userInfo.name}
                    </h6>
                </div>
                <ul className='nav flex-column'>
                    {IsParticipant(userInfo) &&
                        <>
                            <MenuItem
                                labelId='app.internaltemplate.menu.dashboard'
                                icon={FaHome}
                                toPath={DASHBOARD_PAGE}
                                onItemClicked={onDashboardClicked}
                            />
                            <Badge style={{ top: 8 }} count={reportPermissionAmount}>
                                <MenuItem
                                    labelId='app.internaltemplate.menu.report_permission'
                                    icon={AiFillSetting}
                                    toPath={REPORT_PERMISSION}
                                />
                            </Badge>
                        </>
                    }
                    {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) &&
                        <MenuItem
                            labelId='app.internaltemplate.menu.institutions'
                            icon={FaRegBuilding}
                            toPath={ORGANIZATION_PAGE}
                        />
                    }
                    {!IsParticipant(userInfo) &&
                        <React.Fragment>
                            {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canVisualizeUser) && <MenuItem
                                labelId='app.internaltemplate.menu.users'
                                icon={FaUsers}
                                toPath={LIST_USERS_PAGE}
                            />}
                            {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canVisualizeProgramExperience) && <MenuItem
                                labelId='app.internaltemplate.menu.organization'
                                icon={FaBuffer}
                                toPath={PROGRAMS_PAGE}
                            />}
                            {HasPermissionCapabilityProgramAdmin(userInfo, UserPermissions.canVisualizeReport) && <MenuItem
                                labelId='app.internaltemplate.menu.report'
                                icon={GiHistogram}
                                toPath={REPORT_PAGE}
                            />}
                            <MenuItem
                                labelId='app.internaltemplate.menu.sample_report'
                                icon={GiHistogram}
                                toPath={SAMPLE_DATA_REPORT_PAGE}
                            />
                        </React.Fragment>
                    }
                    {(IsSuperAdmin(userInfo) || IsAccountManager(userInfo)) &&
                        <MenuItem
                            labelId='app.internaltemplate.menu.financial_report'
                            icon={FaArchive}
                            toPath={FINANCIAL_REPORT_PAGE}
                        />
                    }
                    {IsSuperAdmin(userInfo) &&
                        <MenuItem
                            labelId='app.internaltemplate.menu.permission_profile'
                            icon={AiFillSetting}
                            toPath={USER_PROFILE_PERMISSION}
                        />
                    }
                    {(IsSuperAdmin(userInfo) || HasPermissionCapability(userInfo, UserPermissions.canVisualizeVirtualAccount)) &&
                        <MenuItem
                            labelId='app.internaltemplate.menu.virtualAccount'
                            icon={AiFillSetting}
                            toPath={VIRTUAL_ACCOUNT}
                        />
                    }
                    {(userInfo.has2FA === 'False' && (IsSuperAdmin(userInfo) || IsProgramAdmin(userInfo) || IsInstitutional(userInfo) || IsAccountManager(userInfo))) &&
                        <MenuItem
                            labelId='app.internaltemplate.menu.configureTwoFactorAuthentication'
                            icon={AiFillSetting}
                            toPath={TWO_FACTOR_AUTHENTICATOR}
                        />
                    }
                    <MenuItem
                        labelId='app.internaltemplate.menu.logout'
                        icon={FaSignOutAlt}
                        toPath={LOGIN_PAGE}
                        onItemClicked={onLogoutClicked}
                    />
                </ul>
            </nav>
            <main role='main' className='internal-template-main-component'>
                {component}
            </main>
            <div className='internal-template-footer'>
                <hr />
                <BeviFormattedLabel messageId={'app.bevi_copyright_text_1'} />
                <BeviFormattedLabel messageId={'app.bevi_copyright_text_2'} />
            </div>
        </div>
    );
}
