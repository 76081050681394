import { ListReportMessages } from '../types';

export const listReportHI: ListReportMessages = {
    'app.listreport.report_area': 'रिपोर्ट प्रबंधन',
    'app.listreport.new': 'नई रिपोर्ट',
    'app.listreport.new_group': 'नई रिपोर्ट समूह',
    'app.listreport.add_group': 'एक और समूह जोड़ें',
    'app.listreport.please_add_group_name': 'कृपया समूह का नाम जोड़ें',
    'app.listreport.search': 'खोजें',
    'app.listreport.name': 'नाम',
    'app.listreport.report_type': 'प्रकार',
    'app.listreport.created_on': 'निर्मित तिथि',
    'app.listreport.confirm_deletion_header': 'हटाने की पुष्टि करें',
    'app.listreport.confirm_deletion_body': 'क्या आप वाकई रिपोर्ट हटाने की प्रक्रिया को जारी रखना चाहते हैं? यदि आप आगे बढ़ते हैं, तो यह रिपोर्ट हमारे डेटाबेस से स्थायी रूप से हटा दी जाएगी।',
    'app.listorganizations.view': 'देखें',
    'app.listorganizations.edit': 'संपादित करें',
    'app.listorganizations.delete': 'हटाएँ',
    'app.listreport.report_status': 'रिपोर्ट स्थिति',
    'app.listreport.report_status_pending_approval': 'प्रतिभागी अनुमोदन की प्रतीक्षा में',
    'app.listreport.report_status_completed': 'उपलब्ध',
}
