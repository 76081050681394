import { ListOrganizationsMessages } from '../types';

export const listOrganizationsHI: ListOrganizationsMessages = {
    'app.listorganizations.created.on': 'निर्मित तिथि',
    'app.listorganizations.active': 'सक्रिय',
    'app.listorganizations.inactive': 'निष्क्रिय',
    'app.listorganizations.new.program': 'नया कार्यक्रम या अनुभव',
    'app.listorganizations.new.group': 'नया BEVI प्रशासन बनाएँ और नाम दें।',
    'app.listorganizations.view': 'देखें',
    'app.listorganizations.report': 'रिपोर्ट',
    'app.listorganizations.details': 'BEVI प्रशासन की समीक्षा और प्रबंधन करें।',
    'app.listorganizations.tooltip.invite_by_qr_code': 'QR कोड द्वारा इस समूह में नए उपयोगकर्ताओं को आमंत्रित करें।',
    'app.listorganizations.tooltip.sub_program_edit': 'BEVI प्रशासन के पैरामीटर संपादित करें।',
    'app.listorganizations.table.beviname': 'BEVI प्रशासन नाम',
    'app.listorganizations.table.created_on': 'निर्मित तिथि',
    'app.listorganizations.table.due_date': 'नियत तिथि',
    'app.listorganizations.table.completed_percentage': 'पूर्णता  %',
    'app.listorganizations.table.status': 'स्थिति',
    'app.listorganizations.table.actions': 'क्रियाएँ',
    'app.listorganizations.select_organization': 'एक संगठन चुनें।',
    'app.listorganizations.search': 'खोजें',
    'app.listorganizations.new_organization': 'नया संगठन',
    'app.listorganizations.input_program_placeholder': 'कार्यक्रम या अनुभव का नाम लिखें।',
    'app.listorganizations.input_subprogram_placeholder': 'BEVI प्रशासन का नाम लिखें।',
    'app.listorganizations.organization_area': 'कार्यक्रमों और अनुभवों का प्रबंधन',
    'app.listorganizations.download.data_report': 'डेटा को Excel फ़ाइल के रूप में डाउनलोड करें।',
    'app.listorganizations.sort_name_date': 'नाम या तिथि द्वारा क्रमित करें',
    'app.listorganizations.sort_by': 'के अनुसार क्रमित करें',
    'app.sample.data.footer.message_line1': '<b><i>नोट।</i> BEVI रिपोर्टिंग सिस्टम आठ विभिन्न रिपोर्ट प्रकारों के विकास की अनुमति देता है: 1) व्यक्तिगत रिपोर्ट, 2) व्यक्तिगत परिवर्तन रिपोर्ट, 3) साझेदार रिपोर्ट, 4) साझेदार परिवर्तन रिपोर्ट, 5) समूह रिपोर्ट, 6) समूह परिवर्तन रिपोर्ट, 7) समूह के भीतर रिपोर्ट, और 8) समूहों के बीच रिपोर्ट। यह दिखाने के लिए कि ये रिपोर्ट कैसे काम करती हैं, और किस प्रकार की जानकारी तक पहुंच प्राप्त की जा सकती है, प्रत्येक रिपोर्ट प्रकार के दो उदाहरण -- A और B -- प्रदान किए गए हैं।</b>',
    'app.sample.data.footer.message_line2': '<b>हालांकि सैम्पल रिपोर्ट BEVI सिस्टम में वास्तविक रिपोर्ट के अनुरूप हैं, ये उदाहरण केवल व्याख्यात्मक उद्देश्यों के लिए प्रदान किए गए हैं। इस प्रकार, इन नमूना रिपोर्ट में कुछ जानकारी (जैसे, स्केल स्कोर) को संशोधित (या हटाया गया) गया हो सकता है (जैसे, गुणात्मक प्रतिक्रियाएँ) ताकि परिणामों को पहचान-रहित और/या विशिष्ट व्यक्तियों से लिंक-मुक्त सुनिश्चित किया जा सके।</b>',
    'app.sample.data.footer.message_line3': '<b>यदि इन सैम्पल रिपोर्ट या BEVI के किसी अन्य पहलू के बारे में आपके कोई प्रश्न हैं, तो कृपया उस व्यक्ति से संपर्क करें जिसने आपके लिए BEVI प्रशासनिक पहुंच अधिकृत की थी या BEVI टीम से <a href="https://thebevi.com/contact/">https://thebevi.com/contact/</a> पर संपर्क करें।</b>',
}
