import { UsersMessages } from '../types';

const users: UsersMessages = {
    'app.users.listuser.title': 'Gestionar usuarios',
    'app.users.name': 'Nombre de usuario',
    'app.users.login': 'Correo-e',
    'app.users.status.inactive': 'Inactivo',
    'app.users.status.active': 'Activo',
    'app.users.status': 'Estado',
    'app.create_batch_user.excel.email_column': 'Correo-e',
    'app.create_batch_user.excel.line_number_column': 'Número de línea',
    'app.create_batch_user.excel.user_error_column': 'Error',
    'app.create_batch_user.excel.download_error_details': 'Descargar detalles',
    'app.create_batch_user.error_already_exists': 'El usuario ya existe',
    'app.create_batch_user.error_invalid_line': 'Línea no válida',
    'app.create_batch_user.error_wrong_email': 'Usuario con correo-e incorrecto',
    'app.create_batch_user.error_missing_name': 'Falta el nombre de usuario',
    'app.create_batch_user.error_details_filename': 'Información del error',
    'app.user.organization': 'Organización',
    'app.user.select_organization': 'Selecciona una organización.',
    'app.user.select_program': 'Selecciona un programa o experiencia',
    'app.user.program': 'Programa',
    'app.users.listuser.action': 'Acción',
    'app.users.listuser.reset_password': 'Restablecer contraseña',
    'app.users.listuser.details': 'Gestión de usuarios',
    'app.users.listuser.programs_action': 'Programas y experiencias',
    'app.users.add_new_user': 'Nuevo usuario',
    'app.user_programs.programName': 'Nombre de programa o experiencia',
    'app.user_programs.subprogramName': 'Nombre de Periodo de administración del BEVI',
    'app.user_programs.status': 'Estado',
    'app.user_programs.completedOn': 'Finalizado el',
    'app.user_programs.invalid_reason': 'Razón no válida',
    'app.listorganizations.input_username_placeholder': 'Escribe un nombre de usuario',
    'app.listorganizations.input_useremail_placeholder': 'Escribe un correo-e de usuario',
    'app.create.batch_user_error': 'Se han encontrado uno o más errores al intentar crear múltiples usuarios para la organización. Para más detalles descarga el archivo de información de errores.',
    'app.create.user.title': 'Crear nuevo usuario',
    'app.create.user.single.user': 'Proporciona una sola dirección de correo-e para que una persona cuente con autorización para realizar y/o administrar el BEVI.',
    'app.create.user.send_welcome_email': 'Enviar correo electrónico de bienvenida',
    'app.create.user.multiple.users': 'Proporciona múltiples direcciones de correo-e para individuos para que tengan autorización a realizar el BEVI.',
    'app.create.user.choose.option.new.user': 'Selecciona la opción correcta de entre las que aparecen a continuación.',
    'app.create.user.language_codes': 'Códigos de idioma',
    'app.create.user.download.template.file': 'Descargar archivo de plantilla.',
    'app.create.user.download.data.example': 'Descargar archivo de prueba',
    'app.create.user.upload.file': 'Subir',
    'app.create.user.drag.n.drop.text': 'Arrastrar y soltar los archivos aquí o hacer click para seleccionar archivos',
    'app.create.user.dran.n.drop.available.files': '(Solo se aceptarán archivos *.xlsx and *.xls)',
    'app.create.user.remove': 'Quitar',
    'app.create.user.selected.file': 'Seleccionados: ',
    'app.create.user.create.multiple.user.title': 'Proporciona direcciones de correo-e para individuos que puedan tener autorización para realizar el BEVI.',
    'app.create.user.create.single.user.title': 'Crear un Usuario',
    'app.create.user.table.column_actions': 'Quitar organización',
    'app.create.user.edit.single.user.title': 'Gestión de usuarios',
    'app.create.user.is.anonymous': 'Es anónimo',
    'app.create.user.user.type': 'Tipo de usuario',
    'app.create.user.language': 'Idioma de correo-e',
    "app.create.user.capability_visualize_user": "VISUALIZAR - USUARIO",
    "app.create.user.capability_add_user": "AGREGAR - USUARIO",
    "app.create.user.capability_reset_password_user": "RESTABLECER CONTRASEÑA - USUARIO",
    "app.create.user.capability_manage_user": "ADMINISTRAR - USUARIO",
    "app.create.user.capability_visualize_user_programs": "VISUALIZAR - PROGRAMAS DEL USUARIO",
    "app.create.user.capability_visualize_program_and_experience": "VISUALIZAR - PROGRAMA Y EXPERIENCIA",
    "app.create.user.capability_add_program_and_experience": "AGREGAR - PROGRAMA Y EXPERIENCIA",
    "app.create.user.capability_rename_program_and_experience": "RENOMBRAR - PROGRAMA Y EXPERIENCIA",
    "app.create.user.capability_add_subprogram": "AGREGAR - SUBPROGRAMA",
    "app.create.user.capability_edit_subprogram": "EDITAR - SUBPROGRAMA",
    "app.create.user.capability_manage_subprogram": "ADMINISTRAR - SUBPROGRAMA",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "MOSTRAR CÓDIGO QR PARA INVITAR NUEVOS USUARIOS - SUBPROGRAMA",
    "app.create.user.capability_visualize_report": "VISUALIZAR - INFORME",
"app.create.user.capability_add_report": "AGREGAR - INFORME",
    'app.user.profile.participant': 'Participante',
    'app.user.profile.programAdmin': 'Admin de programa',
    'app.user.profile.institutional': 'Admin institucional',
    'app.user.profile.accountManager': 'Administrador de cuenta',
    'app.user.profile.superAdmin': 'Super Admin',
    'app.user.language.message': '(Idioma en que el usuario recibirá el correo-e)',
    'app.user.successfulMessage': 'Usuario creado con éxito',
    "app.user.updateSuccessfulMessage": "El usuario se actualizó correctamente",
    'app.edit.user.confirmation_title_fail': '¡Vaya! Por desgracia, no hemos podido actualizarlo',
    'app.edit.user.confirmation_body_fail': 'No se ha podido actualizar el usuario. Por favor, revisa los datos proporcionados, la conexión a Internet y vuélvelo a intentar. Si el problema persiste, habla con el administrador de cuenta.',
    'app.edit.user.confirmation_body_fail_already_exists': 'No se ha podido crear el usuario porque otro usuario ya tiene el correo-e proporcionado.',
    'app.edit.user.confirmation_body_fail_wrong_email': 'No se ha podido actualizar el usuario porque el correo-e proporcionado no es válido.',
    'app.edit.user.confirmation_title_success': 'Actualizado con éxito',
    'app.edit.user.confirmation_body_success': 'Se ha actualizado el usuario y ahora puedes visualizarlo en el listado de usuarios, al que te estamos redirigiendo.',
    'app.create.user.confirmation_title_fail': '¡Vaya! Por desgracia no hemos podido crearlo',
    'app.create.user.confirmation_body_fail': 'No se ha podido crear el usuario. Por favor, revisa los datos proporcionados, la conexión a Internet y vuélvelo a intentar. Si el problema persiste, habla con el administrador de cuenta.',
    'app.create.user.confirmation_title_success': 'Creado con éxito',
    'app.create.user.confirmation_body_success': 'El usuario se ha creado con éxito.',
    'app.create.user.confirmation_body_fail_already_exists': 'No se ha podido crear el usuario porque otro usuario ya tiene el correo-e proporcionado.',
    'app.create.user.confirmation_body_fail_wrong_email': 'No se ha podido crear el usuario porque el correo-e proporcionado no es válido.',
    'app.reset_password.title': 'RESTABLECER CONTRASEÑA',
    'app.reset_password.body': 'Haciendo clic en Restablecer contraseña, restablecerás temporalmente la contraseña de <b>{username}</b>.<br/><br/>Una vez haya ingresado en el sistema, podrá modificar su contraseña.',
    'app.reset_password.successful_message': '<b>{username}</b> ha sido actualizado con éxito.',
    'app.reset_password.error_message': 'Algo ha ido mal, por favor, vuelve a intentarlo en unos minutos.',
    'app.reset_password.new_temporary_password': 'Escribe la contraseña temporal a continuación',
    'app.reset_password.new_password': 'Nueva contraseña',
    'app.reset_password.button_reset': 'Restablecer contraseña',
    "app.users_type.select": "Selecciona un tipo de usuario",
    "app.users_type.all": "Todos",
    "app.users_type.participant": "Participante",
    "app.users_type.program_admin": "Administrador del Programa",
    "app.users_type.institutional": "Institucional",
    "app.users_type.account_manager": "Gerente de Cuenta",
    "app.users_type.super_admin": "Super Administrador",
    "app.users_type.external_partner": "Socio Externo",
    "app.users.listuser.clear_2fa": "Borrar la Autenticación de Dos Factores",
    "app.users.confirm_deletion_body": "¿Estás seguro de que deseas aplicar el proceso de eliminación de 2FA? Si lo haces, se le pedirá al usuario que vuelva a ingresar el código en Google Authenticator."
}

export const usersES: UsersMessages =
    { ...users }
