import { CreateProgramMessages } from '../types';

export const createProgramHI: CreateProgramMessages = {
    'app.create_program.btn_submit': 'सहेजें',
    'app.create_program.btn_submit_and_send_email': 'सहेजें/ईमेल भेजें',
    'app.create_program.btn_unselect_all_users': 'सभी हटा दें',
    'app.create_program.select_individual_manually': 'व्यक्तियों को मैन्युअल रूप से चुनें',
    'app.create_program.table.column_name': 'नाम',
    'app.create_program.table.column_email': 'ईमेल',
    'app.create_program.table.column_actions': 'व्यक्तियों को हटाएँ',
    'app.create_program.table.column_get_last_users': 'पिछले प्रशासन के व्यक्ति',
    'app.create_program.tooltip.action_remove': 'हटाएँ',
    'app.create_program.tooltip.btn_get_last_users': 'पिछले प्रशासन से व्यक्तियों को आयात करें',
    'app.create_program.tooltip.btn_download_users': 'डाउनलोड करें / प्रबंधित करें कि BEVI कौन लेगा',
    'app.create_program.tooltip.btn_download_users_empty': 'डाउनलोड करें / प्रबंधित करें कि BEVI (खाली टेम्पलेट) कौन लेगा',
    'app.create_program.excel.name_column': 'नाम',
    'app.create_program.excel.login_column': 'ईमेल',
    'app.create_program.excel.active_column': 'सक्रिय',
    'app.create_program.confirmation_title_success': 'सफलतापूर्वक बनाया गया',
    'app.create_program.confirmation_body_success_program': 'कार्यक्रम या अनुभव अब बनाया गया है। आप नीचे \'OK\' पर क्लिक करके इसकी समीक्षा या प्रबंधन कर सकते हैं।',
    'app.create_program.confirmation_body_success_subprogram': 'आपने BEVI प्रशासन बनाया और नामित किया है। आप नीचे \'OK\' पर क्लिक करके इसकी समीक्षा या प्रबंधन कर सकते हैं।',
    'app.create_program.confirmation_title_fail': 'ओह नहीं! दुर्भाग्यवश, हम इसे नहीं बना सके',
    'app.create_program.confirmation_body_fail_program': 'कार्यक्रम नहीं बनाया जा सका। कृपया प्रदान किए गए डेटा, इंटरनेट कनेक्शन की समीक्षा करें और फिर से प्रयास करें। यदि समस्या बनी रहती है, तो अकाउंट प्रबंधक से संपर्क करें।',
    'app.create_program.confirmation_body_fail_subprogram': 'उप-कार्यक्रम नहीं बनाया जा सका। कृपया प्रदान किए गए डेटा, इंटरनेट कनेक्शन की समीक्षा करें और फिर से प्रयास करें। यदि समस्या बनी रहती है, तो अकाउंट प्रबंधक से संपर्क करें।',
    'app.edit_sub_program.confirmation_title_success': 'सफलतापूर्वक अपडेट किया गया',
    'app.edit_sub_program.confirmation_body_success': 'समूह अपडेट किया गया है और आप अब इसे संगठन कार्यक्रम सूची में देख सकते हैं, जहां आप को पुनः निर्देशित किया जा रहा है।',
    'app.edit_sub_program.confirmation_title_fail': 'ओह नहीं! दुर्भाग्यवश, हम इसे अपडेट नहीं कर सके',
    'app.edit_sub_program.confirmation_body_fail': 'उप-कार्यक्रम को अपडेट नहीं किया जा सका। कृपया प्रदान किए गए डेटा, इंटरनेट कनेक्शन की समीक्षा करें और फिर से प्रयास करें। यदि समस्या बनी रहती है, तो अकाउंट प्रबंधक से संपर्क करें।',
    'app.create_program.lbl_associate_users': 'BEVI लेने के लिए व्यक्तियों का चयन करें',
    'app.create_program.lbl_organization_name': 'संगठन का नाम',
    'app.create_program.lbl_program_name': 'कार्यक्रम या अनुभव का नाम',
    'app.create_program.lbl_sub_program_name': 'BEVI प्रशासन का नाम',
    'app.create_program.lbl_year': 'वर्ष',
    'app.create_program.lbl_due_date': 'BEVI प्रशासन के लिए अंतिम तिथि',
    'app.create_program.lbl_alert_all_users': 'सभी व्यक्तियों को ईमेल द्वारा सूचित करें',
    'app.details_sub_program.progress': 'समूह प्रगति',
    'app.details_sub_program.participant_details': 'प्रतिभागी विवरण',
    'app.details_sub_program.remind_alert': 'स्मरण चेतावनी',
    'app.details_sub_program.remind_alert_text': 'एक अनुस्मारक अलर्ट निर्धारित किया गया था और इसे कुछ ही मिनटों में भेजा जाएगा।',
    'app.create_program.lbl_email_message': 'नीचे एक संदेश टाइप करें जो सभी व्यक्तिगत प्रतिभागियों के ईमेल पते पर भेजा जाएगा।',
    'app.create_program.tooltip.btn_upload_users': 'कृपया अपलोड करें कि BEVI कौन लेगा।',
    'app.create_program.upload_files_error_title': 'फ़ाइल अपलोड त्रुटि',
    'app.create_program.upload_rejected_files_error_body_prefix': 'निम्नलिखित फ़ाइलों को अस्वीकार कर दिया गया क्योंकि वे अपेक्षित प्रारूप में नहीं हैं:',
    'app.create_program.upload_parsed_files_error_body_prefix': 'फ़ाइल में {0}, {1} और {2} कॉलम होने की अपेक्षा है। निम्नलिखित फ़ाइलें सही ढंग से पढ़ी नहीं गईं:',
    'app.create_program.upload_parsed_files_error_body': '{0} में {1} त्रुटियाँ हैं।',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'निम्नलिखित फ़ाइलों का विश्लेषण करने में त्रुटि हुई:',
    'app.create_program.select_individuals_title': 'BEVI लेने के लिए व्यक्तियों को तीन में से किसी एक विधि के माध्यम से चुनें:',
    'app.create_program.import_individuals_from_previous_administration': '1. पिछले प्रशासन से व्यक्तियों को आयात करें।',
    'app.create_program.download_manage_who_will_take_the_bevi': '2. BEVI लेने वाले व्यक्तियों को डाउनलोड / प्रबंधित करें',
    'app.create_program.upload_who_will_take_the_bevi': ', और फिर BEVI लेने वाले व्यक्तियों को अपलोड करें',
    'app.create_program.add_individuals_manually': '3. ड्रॉपडाउन पर क्लिक करके व्यक्तियों को मैन्युअल रूप से / एक-एक करके जोड़ें।',
}
