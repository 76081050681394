import { PermissionMessages } from '../types';

export const permissionHI: PermissionMessages = {
  'app.permission.title': 'अनुमति - व्यक्तिगत रिपोर्ट - प्रोफ़ाइल',
  'app.permission.remove_permission_to_all': 'सभी की अनुमति हटा दें',
  'app.permission.give_permission_to_all': 'सभी को अनुमति दें',
  'app.permission.remove_permission': 'अनुमति हटाएँ',
  'app.permission.give_permission': 'अनुमति देना',
  'app.permission.table_title.permission': 'अनुमति',
  'app.permission.table_title.name': 'नाम',
  'app.permission.table_title.email': 'ईमेल',
  'app.permission.button.update': 'अपडेट',
  'app.permission.message': 'उपयोगकर्ता सफलतापूर्वक अपडेट हो गए',
  'app.permission.add_permissions_with_excel_file': 'एक्सेल फ़ाइल के साथ अनुमतियाँ जोड़ें',
  'app.permission.add_permissions': 'अनुमतियाँ जोड़ें',
  'app.permission.permissions': 'अनुमतियाँ',
  'app.permission.download_tooltip': 'फ़ाइल टेम्पलेट डाउनलोड करें'
}
