import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
	'app.demographic.age': 'Umri',
	'app.demographic.gender': 'Jinsia',
	'app.demographic.marital_status': 'Hali ya Uhusiano',
	'app.demographic.level_of_fluency': 'Tafadhali onyesha kiwango chako cha juu zaidi cha ufasaha katika lugha yoyote isipokuwa lugha asilia (inayozungumzwa nyumbani kwako na/au jumuiya, na/au lugha ya kufundishia katika shule za msingi/sarufi/msingi. ) ulizohudhuria)?.',
	'app.demographic.education_years': 'Tafadhali onyesha ni miaka mingapi ya elimu rasmi ambayo umemaliza.',
	'app.demographic.raised_in_country_where_currently_in': 'Kuanzia elimu ya msingi katika daraja la 1 (k.m., zaidi ya shule ya awali au chekechea), ni miaka mingapi ya elimu rasmi umemaliza:',
	'app.demographic.ethnic': 'Ukabila gani?',
	'app.demographic.ethnic_other': 'Tafadhali eleza katika nafasi iliyotolewa:',
	'app.demographic.ethnic_asian': 'Mhasia. Mtu mwenye asili ya nchi au maeneo ya Mashariki ya Mbali, Bara Hindi, au Asia ya Kusini-Mashariki ikijumuisha, kwa mfano, Kambodia, Uchina, India, Japan, Korea, Malaysia, Pakistan, Visiwa vya Ufilipino, Thailandi na Vietnam.',
	'app.demographic.ethnic_african': 'Mweusi au Mwafrika. Mtu mwenye asili ya jamii yoyote ya watu weusi barani Afrika.',
	'app.demographic.ethnic_latino': 'Mhispania au Mlatino. Mtu mwenye asili ya nchi au maeneo ikiwa ni pamoja na, kwa mfano, Kuba, Meksiko, Puerto Rico, Amerika Kusini au Kati, au tamaduni au asili nyingine ya Kihispania, bila kujali rangi.',
	'app.demographic.ethnic_indigenous': 'Wenyeji. Mtu mwenye asili katika mojawapo ya watu wa asili wa nchi au eneo fulani ambaye hudumisha uhusiano au kushikamana na kabila au jumuiya ya kiasili.',
	'app.demographic.ethnic_middle_eastern': 'Mashariki ya Kati. Mtu mwenye asili katika nchi au maeneo yoyote kutoka Afghanistan hadi Libya, ikiwa ni pamoja na, kwa mfano, Misri, Iran, Iraki, Israel, Jordan, Lebanon, Saudi Arabia, Sudan, Syria, Uturuki, au nchi za peninsula ya Arabia.',
	'app.demographic.ethnic_white': 'Mzungu au Caucasian. Mtu mwenye asili ya jamii yoyote ya Wazungu wa Ulaya.',
	'app.demographic.completing_bevi': 'Tafadhali onyesha unapokamilisha BEVI.',
	'app.demographic.completing_bevi_other': 'Tafadhali eleza mazingira ambayo unakamilisha BEVI hapa chini.',
	'app.demographic.previously_completed': 'Je, hapo awali umekamilisha BEVI?',
	'app.demographic.disadvantaged_or_minority_group': 'Kwa uzoefu wako, je, wewe ni mwanachama wa kikundi kilichotengwa au kilichopungukiwa?',
	'app.demographic.disadvantaged_or_minority_group_yes': 'Katika nafasi iliyotolewa, tafadhali toa maelezo yoyote ya ziada.',
	'app.demographic.primary_area_of_work': 'Kati ya chaguo zilizo hapa chini, ni ipi inawakilisha ulinganifu wa karibu zaidi na eneo lako la masomo au eneo lako la msingi la kazi / maslahi ya kitaaluma?',
	'app.demographic.current_area': 'Katika nafasi iliyotolewa, tafadhali orodhesha eneo/maeneo yako ya sasa ya masomo au kazi yako:',
	'app.demographic.political_orientation': 'Katika safu kutoka "Kihafidhina/Kijadi (k.m., kuunga mkono kudumisha au kuhifadhi sheria zilizopo, mifumo, na njia za kusihi)" hadi "Kisasa/Mamboleo (k.m., kuunga mkono kubadilisha au kurekebisha sheria zilizopo, mifumo na njia za kuishi)", tafadhali tumia kitelezi kilicho hapa chini ili kuonyesha mwelekeo wako wa kisiasa',
	'app.demographic.political_commitment': 'Tafadhali onyesha kiwango chako cha kujitolea kibinafsi kwa mwelekeo wako wa kisiasa.',
	'app.demographic.political_orientation_conservative_label': 'Kihafidhina/Kijadi',
	'app.demographic.political_orientation_liberal_label': 'Kisasa/Mamboleo',
	'app.demographic.religion': 'Ni ipi kati ya chaguo zifuatazo inalingana kwa karibu zaidi na imani au ahadi zako za sasa kuhusu dini au hali ya kiroho?',
	'app.demographic.religion_other': 'Tafadhali eleza katika nafasi iliyotolewa:',
	'app.demographic.religion_scale': 'Tafadhali onyesha kiwango chako cha kujitolea kibinafsi kwa dini au mambo ya kiroho.',
	'app.demographic.religion_scale_specified': 'Tafadhali onyesha kiwango chako cha kujitolea kwa kibinafsi kwa {0}',
	'app.demographic.income_level': 'Katika safu kutoka "Cheo cha Chini" hadi "Cheo cha Juu Zaidi," tafadhali tumia kitelezi kilicho hapa chini ili kuonyesha mapato yako au ufikiaji wa rasilimali ikilinganishwa na wengine katika jamii yako.',
	'app.demographic.income_less_label': 'Cheo cha chini kabisa',
	'app.demographic.income_more_label': 'Cheo cha Juu kabisa',
	'app.demographic.academic_rank_lowest_label': 'Cheo cha chini kabisa',
	'app.demographic.academic_rank_highest_label': 'Cheo cha Juu kabisa',
	'app.demographic.academic_rank': 'Kuhusiana na wanafunzi wengine ambao walichukua darasa nawe katika uzoefu wako wa shule wa hivi majuzi, tafadhali toa makadirio yako bora ya hadhi au cheo chako kitaaluma (k.m., kutoka "Cheo cha Chini zaidi hadi "Cheo cha Juu zaidi"). ',
	'app.demographic.primary_country': 'Nchi yako ya msingi ni ipi?',
	'app.demographic.time_in_other_countries': 'Kati ya chaguo zilizo hapa chini, tafadhali onyesha muda ambao umetumia katika nchi/maeneo mengine mbali na nchi yako ya asili.',
	'app.demographic.time_in_other_countries_other': 'Tafadhali bainisha',
	'app.demographic.visit_countries': 'Je, umetembelea au kuishi katika nchi/maeneo mangapi mbali na nchi yako ya asili?',
	'app.demographic.country_explanation_field': 'Ili kupata nchi yako, unaweza kuchagua nchi kutoka kwenye menyu kunjuzi au chapa tu na uchague lugha.',
	'app.demographic.country_mother_born': 'Tafadhali onyesha nchi/eneo ambalo mama yako alizaliwa:',
	'app.demographic.country_father_born': 'Tafadhali onyesha nchi/eneo ambalo baba yako alizaliwa:',
	'app.demographic.personal_interest': 'Tafadhali onyesha kiwango chako cha maslahi binafsi katika uzoefu wa kimataifa, tamaduni, au tamaduni mbalimbali (k.m., kazi, masomo, au huduma nje ya nchi; Kubadilishana mawazo mtandaoni au Ushirikiano wa masomo ya kitaifa mtandaoni; atiba ya masomo ya tamadinu mbalimbali; ushiriki katika timu mbalimbali).',
	'app.demographic.participated_experiences': 'Je, umekamilisha hapo awali, au kwa sasa unakamilisha, mojawapo ya uzoefu ufuatao? (Bofya yote yanayotumika)',
	'app.demographic.participated_learning_courses': 'Je, umekamilisha hapo awali, au kwa sasa unakamilisha, mojawapo ya kozi/programu zifuatazo za kimataifa na anuwai za kujifunza? (Bofya yote yanayotumika)',
	'app.demographic.plan_participate_experiences': 'Je, unapanga kukamilisha mojawapo ya uzoefu ufuatao? (Bofya yote yanayotumika)',
	'app.demographic.plan_participate_learning_courses': 'Je, unapanga kukamilisha kozi/programu zifuatazo za kimataifa na za utofauti? (Bofya yote yanayotumika)',
	'app.demographic.experience_satisfaction': 'Ikiwa umeshiriki, au kwa sasa unashiriki, katika uzoefu wa kimataifa, tamaduni, au tamaduni mbalimbali (k.m., kazi, kusoma, au huduma nje ya nchi; Masomo ya Ushirikiano wa kimataifa mtandaoni; ushiriki wa timu mbalimbali), tafadhali onyesha kiwango chako cha jumla cha kuridhika na uzoefu huu.',
	'app.demographic.languages_speak': 'Je, unazungumza lugha ngapi kama lugha za asili (zinazozungumzwa nyumbani kwako na/au jumuiya, na/au lugha ya kufundishia katika shule ya msingi/sarufi/msingi uliyosoma)? ',
	'app.demographic.years_studied_foreign_language': 'Je, umesoma lugha ya kigeni kwa miaka mingapi?',
	'app.demographic.primary_language': 'Lugha yako ya msingi ni ipi (yaani, lugha ya kwanza au kuu unayozungumza)? Unaweza kuchagua zaidi ya lugha moja ikiwa una zaidi ya lugha moja ya msingi. Ili kupata lugha yako, unaweza kuchagua lugha kutoka kwenye menyu kunjuzi au chapa tu na uchague lugha.',
	'app.demographic.primary_language_other': 'Je, unazungumza lugha yoyote isipokuwa lugha yako ya asili (yaani, lugha yako ya kwanza au ya msingi)?',
	'app.demographic.language_level_of_fluency': 'Kutoka kwa chaguo zilizo hapa chini, tafadhali chagua lugha zozote unazozungumza pamoja na kiwango chako cha ufasaha.',
	'app.demographic.language_level_of_fluency_add_anwser': 'Ongeza',
	'app.demographic.table_level_of_fluency': 'Kiwango cha ufasaha',
	'app.demographic.table_language': 'Lugha',
	'app.demographic.table_action': 'Kitendo',
	'app.demographic.remove_language_tooltip': 'Ondoa lugha',
	'app.demographic.father_formal_education': 'Tafadhali onyesha kiwango cha juu cha elimu rasmi alichopata baba yako:',
	'app.demographic.mother_formal_education': 'Tafadhali onyesha kiwango cha juu cha elimu rasmi alichopata mama yako:',
	'app.demographic.comparative': 'Ripoti Linganishi ya Mtu Binafsi',
	'app.demographic.compare': 'Linganisha',
	'app.demographic.select_multiple_reports': 'Chagua ripoti nyingi',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
	'app.demographic.language_options.Afrikaans': 'Kiafrikana',
	'app.demographic.language_options.Albanian': 'Kialbania',
	'app.demographic.language_options.Amharic': 'Kiamharik',
	'app.demographic.language_options.Arabic': 'Kiarabu',
	'app.demographic.language_options.Armenian': 'Kiarmenia',
	'app.demographic.language_options.Basque': 'Kibasque',
	'app.demographic.language_options.Bengali': 'Bengali',
	'app.demographic.language_options.Byelorussian': 'Kibyelorussian',
	'app.demographic.language_options.Burmese': 'Kiburma',
	'app.demographic.language_options.Bulgarian': 'Kibulgaria',
	'app.demographic.language_options.Catalan': 'Kikatalani',
	'app.demographic.language_options.Czech': 'Kiczech',
	'app.demographic.language_options.Chinese': 'Kichina',
	'app.demographic.language_options.Croatian': 'Kicroatiani',
	'app.demographic.language_options.Danish': 'Kideni',
	'app.demographic.language_options.Dari': 'Kidari',
	'app.demographic.language_options.Dzongkha': 'Kidzongkha',
	'app.demographic.language_options.Dutch': 'Kiholanzi',
	'app.demographic.language_options.English': 'Kiingereza',
	'app.demographic.language_options.Esperanto': 'Kiesperanto',
	'app.demographic.language_options.Estonian': 'Kiestonia',
	'app.demographic.language_options.Faroese': 'Kifaroese',
	'app.demographic.language_options.Farsi': 'Kifarsi',
	'app.demographic.language_options.Finnish': 'Kifini',
	'app.demographic.language_options.French': 'Kifaransa',
	'app.demographic.language_options.Gaelic': 'Kigaelic',
	'app.demographic.language_options.Galician': 'Kigalisia',
	'app.demographic.language_options.German': 'Kijerumani',
	'app.demographic.language_options.Greek': 'Kigiriki',
	'app.demographic.language_options.Hebrew': 'Kiebrania',
	'app.demographic.language_options.Hindi': 'Kihindi',
	'app.demographic.language_options.Hungarian': 'Kihungariani',
	'app.demographic.language_options.Icelandic': 'Kiaislandi',
	'app.demographic.language_options.Indonesian': 'Kindonesia',
	'app.demographic.language_options.Inuktitut_Eskimo': 'Inuktitut (Kieskimo)',
	'app.demographic.language_options.Italian': 'Kitaliano',
	'app.demographic.language_options.Japanese': 'Kijapani',
	'app.demographic.language_options.Khmer': 'Kikhmer',
	'app.demographic.language_options.Korean': 'Kikorea',
	'app.demographic.language_options.Kurdish': 'Kikurdi',
	'app.demographic.language_options.Laotian': 'Klaotiani',
	'app.demographic.language_options.Latvian': 'Kilatviani',
	'app.demographic.language_options.Lappish': 'Kilappish',
	'app.demographic.language_options.Lithuanian': 'Kilithuania',
	'app.demographic.language_options.Macedonian': 'Kimasedonia',
	'app.demographic.language_options.Malay': 'Kimalaya',
	'app.demographic.language_options.Maltese': 'Kimalta',
	'app.demographic.language_options.Nepali': 'Kinepali',
	'app.demographic.language_options.Norwegian': 'Kinowiji',
	'app.demographic.language_options.Pashto': 'Kipashto',
	'app.demographic.language_options.Polish': 'Kipoli',
	'app.demographic.language_options.Portuguese': 'Kireno',
	'app.demographic.language_options.Romanian': 'Kiromania',
	'app.demographic.language_options.Russian': 'Kirusi',
	'app.demographic.language_options.Scots': 'Kiskoti',
	'app.demographic.language_options.Serbian': 'Kiserbia',
	'app.demographic.language_options.Slovak': 'Kislovakia',
	'app.demographic.language_options.Slovenian': 'Kislovenia',
	'app.demographic.language_options.Somali': 'Kisomali',
	'app.demographic.language_options.Spanish': 'Kihispania',
	'app.demographic.language_options.Swedish': 'Kiswidi',
	'app.demographic.language_options.Swahili': 'Kiswahili',
	'app.demographic.language_options.Tagalog-Filipino': 'Tagalog-Kifilipino',
	'app.demographic.language_options.Tajik': 'Kitajiki',
	'app.demographic.language_options.Tamil': 'Kitamil',
	'app.demographic.language_options.Thai': 'Thai',
	'app.demographic.language_options.Tibetan': 'Kitibeti',
	'app.demographic.language_options.Tigrinya': 'Kitigrinya',
	'app.demographic.language_options.Tongan': 'Kitongan',
	'app.demographic.language_options.Turkish': 'Kituruki',
	'app.demographic.language_options.Turkmen': 'Kiturkmeni',
	'app.demographic.language_options.Ucrainian': 'Kiukrainia',
	'app.demographic.language_options.Urdu': 'Kiurdu',
	'app.demographic.language_options.Uzbek': 'Kiuzbeki',
	'app.demographic.language_options.Vietnamese': 'Kivietnami',
	'app.demographic.language_options.Welsh': 'Kiwelsh',
	'app.demographic.language_options.Other': 'Nyingine',
	'app.demographic.gender.male': 'Kiume',
	'app.demographic.gender.female': 'Kike',
	'app.demographic.gender.self_identify': 'Unavyo Pendelea kujieleza',
	'app.demographic.marital.response_single': 'Bila Uhisiano wa Ndoa',
	'app.demographic.marital.response_married': 'Oa/Olewa',
	'app.demographic.marital.response_divorced': 'Talakiwa',
	'app.demographic.marital.response_widowed': 'Mjane',
	'app.demographic.marital.response_other': 'Nyingine',
	'app.demographic.education_years.response_1': 'Hakuna elimu rasmi.',
	'app.demographic.education_years.response_2': 'mwaka 1',
	'app.demographic.education_years.response_3': 'miaka 2',
	'app.demographic.education_years.response_4': 'miaka 3',
	'app.demographic.education_years.response_5': 'miaka 4',
	'app.demographic.education_years.response_6': 'miaka 5',
	'app.demographic.education_years.response_7': 'miaka 6',
	'app.demographic.education_years.response_8': 'miaka 7',
	'app.demographic.education_years.response_9': 'miaka 8',
	'app.demographic.education_years.response_10': 'miaka 9',
	'app.demographic.education_years.response_11': 'miaka 10',
	'app.demographic.education_years.response_12': 'miaka 11',
	'app.demographic.education_years.response_13': 'miaka 12',
	'app.demographic.education_years.response_14': 'miaka 13',
	'app.demographic.education_years.response_15': 'miaka 14',
	'app.demographic.education_years.response_16': 'miaka 15',
	'app.demographic.education_years.response_17': 'miaka 16',
	'app.demographic.education_years.response_18': 'miaka 17',
	'app.demographic.education_years.response_19': 'miaka 18',
	'app.demographic.education_years.response_20': 'miaka 19',
	'app.demographic.education_years.response_21': 'miaka 20',
	'app.demographic.education_years.response_22': 'miaka 21',
	'app.demographic.education_years.response_23': 'miaka 22',
	'app.demographic.education_years.response_24': 'miaka 23',
	'app.demographic.education_years.response_25': 'miaka 24',
	'app.demographic.education_years.response_26': 'miaka 25',
	'app.demographic.education_years.response_27': 'miaka 26',
	'app.demographic.education_years.response_28': 'miaka 27',
	'app.demographic.education_years.response_29': 'miaka 28',
	'app.demographic.education_years.response_30': 'miaka 29',
	'app.demographic.education_years.response_31': 'miaka 30',
	'app.demographic.education_years.response_32': 'Zaidi ya miaka 30',
	'app.demographic.ethnic.respose_asian': 'Mhesia',
	'app.demographic.ethnic.respose_african': 'Mweusi au Mwafrika',
	'app.demographic.ethnic.respose_latino': 'Mhispania au Mlatino',
	'app.demographic.ethnic.respose_indigenous': 'Wenyeji',
	'app.demographic.ethnic.respose_middle_eastern': 'Mwenyeji Mashariki ya Kati',
	'app.demographic.ethnic.respose_white': 'Mzungu au Caucasian',
	'app.demographic.ethnic.respose_mixed': 'Mchanganyiko',
	'app.demographic.ethnic.respose_other': 'Nyingine',
	'app.demographic.completing_bevi.response_before': 'Kabla (au mwanzoni mwa) kujifunza uzoefu wa kimataifa/kitamaduni.',
	'app.demographic.completing_bevi.response_during': 'Wakati (au katikati ya) kujifunza uzoefu wa kimataifa/kitamaduni. ',
	'app.demographic.completing_bevi.response_after': 'Baada ya (au mwisho wa) kujifunza uzoefu wa kimataifa/kitamaduni.',
	'app.demographic.completing_bevi.response_during_academic': 'Wakati wa mwelekeo wa kitaaluma na siyo mojawapo ya chaguo zilizo hapo juu.',
	'app.demographic.completing_bevi.response_end_graduation': 'Mwishoni mwa masomo yako ya chuo kikuu.',
	'app.demographic.completing_bevi.response_other': 'Nyingine - Tafadhali Bainisha.',
	'app.demographic.primary_area_of_work.response_1': 'Sanaa, Mawasiliano,Masomo ya Kijamii',
	'app.demographic.primary_area_of_work.response_2': 'Tabia, Utamaduni, Jamii',
	'app.demographic.primary_area_of_work.response_3': 'Biashara',
	'app.demographic.primary_area_of_work.response_4': 'Elimu',
	'app.demographic.primary_area_of_work.response_5': 'Huduma za Afya na Kibinadamu',
	'app.demographic.primary_area_of_work.response_6': 'Sayansi na Teknolojia',
	'app.demographic.primary_area_of_work.response_7': 'Nyingine',
	'app.demographic.political_orientation.response_1': 'Kihafidhina/kijadi (k.m., kuunga mkono kudumisha au kuhifadhi sheria zilizopo, mifumo na namna ya kuishi)',
	'app.demographic.political_orientation.response_2': 'Kihafidhina zaidi kuliko mamboleo',
	'app.demographic.political_orientation.response_3': 'Wastani/bila kuegemea upande wowote/si wa kihafidhina wala mamboleo',
	'app.demographic.political_orientation.response_4': 'Mamboleo kuliko kihafidhina',
	'app.demographic.political_orientation.response_5': 'Kisasa /mamboleo(k.m., kuunga mkono kubadilisha au kurekebisha sheria zilizopo, mifumo na namna ya kuishi)',
	'app.demographic.political_orientation.response_6': 'Nyingine',
	'app.demographic.religion.response_1': 'Dini ya Jadi ya Kiafrika',
	'app.demographic.religion.response_2': 'Agnosticism',
	'app.demographic.religion.response_3': 'Ukafiri',
	'app.demographic.religion.response_4': 'Bahai',
	'app.demographic.religion.response_5': 'Ubudha',
	'app.demographic.religion.response_6': 'Caodaism',
	'app.demographic.religion.response_7': 'Dini ya Jadi ya Kichina',
	'app.demographic.religion.response_8': 'Ukristo',
	'app.demographic.religion.response_9': 'Uhindu',
	'app.demographic.religion.response_10': 'Wenyeji',
	'app.demographic.religion.response_11': 'Uislamu',
	'app.demographic.religion.response_12': 'Ujaini',
	'app.demographic.religion.response_13': 'Juche',
	'app.demographic.religion.response_14': 'Uyahudi',
	'app.demographic.religion.response_15': 'Upagani',
	'app.demographic.religion.response_16': 'Shinto',
	'app.demographic.religion.response_17': 'Ukalasinga',
	'app.demographic.religion.response_18': 'Kiroho',
	'app.demographic.religion.response_19': 'Kiroho Lakini Hakuna Ushirikiano Mahususi wa Dini',
	'app.demographic.religion.response_20': 'Tenrikyo',
	'app.demographic.religion.response_21': 'Unitarian-Universalism',
	'app.demographic.religion.response_22': 'Zoroastrianism',
	'app.demographic.religion.response_other': 'Nyingine',
	'app.demographic.religion.no_response': 'Sipendelei kujibu swali hili',
	'app.demographic.income_level.response_1': 'Thuluthi ya juu',
	'app.demographic.income_level.response_2': 'Thuluthiya kati',
	'app.demographic.income_level.response_3': 'Thuluthiya chini',
	'app.demographic.academic_rank.response_1': 'Thuluthiya juu',
	'app.demographic.academic_rank.response_2': 'Thuluthiya kati',
	'app.demographic.academic_rank.response_3': 'Thuluthi chini',
	'app.demographic.participated_experiences.response_1': 'Kozi ya semina mahususi kwa wanafunzi wa mwaka wa 1/wanafunzi mwaka wa kwanza',
	'app.demographic.participated_experiences.response_2': 'Mwanachama wa jumuiya ya kujifunza iliyopangwa na chuo kikuu',
	'app.demographic.participated_experiences.response_3': 'Kuandika kozi ya kina',
	'app.demographic.participated_experiences.response_4': 'Utafiti Pamoja Profesa au mshauri mtaalamu',
	'app.demographic.participated_experiences.response_5': 'Mafunzo ya huduma yaliyofadhiliwa/kuidhinishwa na chuo kikuu au mradi wa kijamii',
	'app.demographic.participated_experiences.response_6': 'Mafunzo kazini yaliyofadhiliwa/idhinishwa na chuo kikuuu',
	'app.demographic.participated_experiences.response_7': 'Mradi wa mwisho wa shahada',
	'app.demographic.participated_experiences.response_8': 'Seti ya kawaida au ya pamoja ya kozi zinazohitajika',
	'app.demographic.participated_experiences.response_9': 'Kazi za kushirikiana au miradi na wengine',
	'app.demographic.participated_experiences.response_10': 'Kozi za masomo mbalimbali / kimataifa au kusoma nje ya nchi',
	'app.demographic.participated_experiences.response_11': 'Wasifu wa kielektroniki (epotifolio) ya kazi yako ya elimu au uzoefu',
	'app.demographic.participated_experiences.response_12': 'Kujifunza mtandaoni (k.m., mtandaoni au kupitia tofuti) ambayo <b>inajumuisha</b> lengo la kimataifa, tamaduni, au tamaduni mbalimbali.',
	'app.demographic.participated_experiences.response_13': 'Masomo ya mtandaoni (k.m., mtandaoni au kupitia tofuti) ambayo <b>hayajumuishi</b> lengo la kimataifa, tamaduni nyingi au tamaduni mbalimbali.',
	'app.demographic.participated_learning_courses.response_1': 'Nimesoma kozi yenye msisitizo wa kitamaduni au kimataifa',
	'app.demographic.participated_learning_courses.response_2': 'Alishiriki katika matukio ya ziada ya kitamaduni, kimataifa, matukio ya kitamaduni chuoni',
	'app.demographic.participated_learning_courses.response_3': 'Kukamilishaa kwa mafunzo ya huduma nje ya nchi',
	'app.demographic.participated_learning_courses.response_4': 'Alimaliza mafunzo kazini nje ya nchi',
	'app.demographic.participated_learning_courses.response_5': 'Alisoma nje ya nchi',
	'app.demographic.participated_learning_courses.response_6': 'Aliishi katika jumuiya ya watu wanaojifunza kuishi kwa kuzingatia kujifunza mafunzo ya kimataifa, tamaduni nyingi, dunia au mafunzo ya lugha',
	'app.demographic.participated_learning_courses.response_7': 'Nilisoma lugha ambayo haikuwa lugha yangu asili',
	'app.demographic.participated_learning_courses.response_8': 'Niliishi na mwenzangu mwenye mila za kitamaduni au asili ambayo ilikuwa tofauti na yangu',
	'app.demographic.participated_learning_courses.response_9': 'Nimekamilisha au ninakamilisha Kubadilishana mawazo mtandaoni, Ushirikiano wa mafunzo ya kimataifa mtandaoni, au uzoefu mwingine wa kujifunza mtandaoni / mtandao ambao una mwelekeo wa kimataifa, tamaduni nyingi au tamaduni mbalimbali.' ,
	'app.demographic.plan_participate_experiences.response_1': 'Kozi ya semina mahususi kwa wanafunzi wa mwaka wa 1/wanafunzi mwaka wa kwanza',
	'app.demographic.plan_participate_experiences.response_2': 'Mwanachama wa jumuiya ya kujifunza iliyopangwa na chuo kikuu',
	'app.demographic.plan_participate_experiences.response_3': 'Kuandika kozi ya kina',
	'app.demographic.plan_participate_experiences.response_4': 'Utafiti Pamoja Profesa au mshauri mtaalamu',
	'app.demographic.plan_participate_experiences.response_5': 'Mafunzo ya huduma yaliyofadhiliwa/kuidhinishwa na chuo kikuu au mradi wa kijamii',
	'app.demographic.plan_participate_experiences.response_6': 'Mafunzo kazini yaliyofadhiliwa/idhinishwa na chuo kikuuu',
	'app.demographic.plan_participate_experiences.response_7': 'Mradi wa mwisho shahada',
	'app.demographic.plan_participate_experiences.response_8': 'Seti ya kawaida au ya pamoja ya kozi zinazohitajika',
	'app.demographic.plan_participate_experiences.response_9': 'Kazi za kushirikiana au miradi na wengine',
	'app.demographic.plan_participate_experiences.response_10': 'Kozi za masomo mbalimbali / kimataifa au kusoma nje ya nchi',
	'app.demographic.plan_participate_experiences.response_11': 'Nafasi ya kielektroniki (epotifolio) ya kazi yako ya elimu au uzoefu',
	'app.demographic.plan_participate_experiences.response_12': 'Kujifunza mtandaoni (k.m., mtandaoni au kupitia tofuti) ambayo <b>inajumuisha</b> lengo la kimataifa, tamaduni, au tamaduni mbalimbali.',
	'app.demographic.plan_participate_experiences.response_13': 'Mafunzo ya mtandaoni (k.m., mtandaoni au kupitia tofuti) ambayo <b>hayajumuishi</b> lengo la kimataifa, tamaduni, au tamaduni mbalimbali.',
	'app.demographic.plan_participate_learning_courses.response_1': 'Fanya kozi yenye msisitizo wa kitamaduni au kimataifa',
	'app.demographic.plan_participate_learning_courses.response_2': 'Shiriki katika matukio ya ziada ya kitamaduni, kimataifa, matukio ya kitamaduni chuoni.',
	'app.demographic.plan_participate_learning_courses.response_3': 'Kamilisha mafunzo ya huduma nje ya nchi.',
	'app.demographic.plan_participate_learning_courses.response_4': 'Kamilisha mafunzo kazini nje ya nchi',
	'app.demographic.plan_participate_learning_courses.response_5': 'Jifunze nje ya nchi',
	'app.demographic.plan_participate_learning_courses.response_6': 'Ishi katika jumuiya yenye kujifunza hai kwa kuzingatia kujifunza kimataifa, kitamaduni, dunia au mafunzo lugha',
	'app.demographic.plan_participate_learning_courses.response_7': 'Jifunze lugha ambayo haikuwa lugha yangu asili',
	'app.demographic.plan_participate_learning_courses.response_8': 'Ishi na mwenzako mwenye mila za kitamaduni au asili ambayo ilikuwa tofauti na yangu',
	'app.demographic.plan_participate_learning_courses.response_9': 'Kamilisha au ninakamilisha Kubadilishana mawazo mtandaoni, Ushirikiano wa mafunzo ya kimataifa mtandaoni, au uzoefu mwingine wa kujifunza mtandaoni / mtandao ambao una mwelekeo wa kimataifa, tamaduni nyingi au tamaduni mbalimbali.',
	'app.demographic.time_in_other_country.response_not_spent': 'Hakuna wakati muhimu nje ya nchi',
	'app.demographic.time_in_other_country.response_less_2_weeks': 'Angalau wiki 1 lakini chini ya wiki 2',
	'app.demographic.time_in_other_country.response_less_1_month': 'Angalau wiki 2 lakini chini ya mwezi 1',
	'app.demographic.time_in_other_country.response_less_3_months': 'Angalau mwezi 1 lakini chini ya miezi 3',
	'app.demographic.time_in_other_country.response_less_6_months': 'Angalau miezi 3 lakini chini ya miezi 6',
	'app.demographic.time_in_other_country.response_less_1_year': 'Angalau miezi 6 lakini chini ya mwaka 1',
	'app.demographic.time_in_other_country.response_less_2_years': 'Angalau mwaka 1 lakini chini ya miaka 2',
	'app.demographic.time_in_other_country.response_less_5_years': 'Angalau miaka 2 lakini chini ya miaka 5',
	'app.demographic.time_in_other_country.response_less_10_years': 'Angalau miaka 5 lakini chini ya miaka 10',
	'app.demographic.time_in_other_country.response_10_or_more': 'miaka 10 au zaidi',
	'app.demographic.country.response_eua': 'Marekani',
	'app.demographic.country.response_afganistan': 'Afghanistani',
	'app.demographic.country.response_albania': 'Albania',
	'app.demographic.country.response_algeria': 'Algeria',
	'app.demographic.country.response_american_samoa': 'Samoa ya Marekani',
	'app.demographic.country.response_andorra': 'Andorra',
	'app.demographic.country.response_angola': 'Angola',
	'app.demographic.country.response_anguilla': 'Anguilla',
	'app.demographic.country.response_antigua_barbuda': 'Antigua & Barbuda',
	'app.demographic.country.response_argentia': 'Argentina',
	'app.demographic.country.response_armenia': 'Armenia',
	'app.demographic.country.response_aruba': 'Aruba',
	'app.demographic.country.response_australia': 'Australia',
	'app.demographic.country.response_austria': 'Austria',
	'app.demographic.country.response_azerbaijan': 'Azerbaijan',
	'app.demographic.country.response_bahamas': 'Bahamas',
	'app.demographic.country.response_bahrain': 'Bahrain',
	'app.demographic.country.response_bangladesh': 'Bangladesh',
	'app.demographic.country.response_barbados': 'Barbados',
	'app.demographic.country.response_belarus': 'Belarus',
	'app.demographic.country.response_belgium': 'Ubelgiji',
	'app.demographic.country.response_belize': 'Belize',
	'app.demographic.country.response_benin': 'Benin',
	'app.demographic.country.response_bermuda': 'Bermuda',
	'app.demographic.country.response_bhutan': 'Bhutan',
	'app.demographic.country.response_bolivia': 'Bolivia',
	'app.demographic.country.response_bosnia_herzegovina': 'Bosnia na Herzegovina',
	'app.demographic.country.response_botswana': 'Botswana',
	'app.demographic.country.response_brazil': 'Brazil',
	'app.demographic.country.response_brunei': 'Brunei',
	'app.demographic.country.response_bulgaria': 'Bulgaria',
	'app.demographic.country.response_burkina_faso': 'Burkina Faso',
	'app.demographic.country.response_burundi': 'Burundi',
	'app.demographic.country.response_cambodia': 'Cambodia',
	'app.demographic.country.response_cameroon': 'Cameroon',
	'app.demographic.country.response_canada': 'Kanada',
	'app.demographic.country.response_cape_verde': 'Cape Verde',
	'app.demographic.country.response_cayman_islands': 'Visiwa vya Cayman',
	'app.demographic.country.response_central_african_republic': 'Jamhuri ya Afrika ya Kati',
	'app.demographic.country.response_chad': 'Chad',
	'app.demographic.country.response_chile': 'Chile',
	'app.demographic.country.response_china': 'China',
	'app.demographic.country.response_china_hong_kong_sar': 'Hong Kong',
	'app.demographic.country.response_colombia': 'Colombia',
	'app.demographic.country.response_comoros': 'Comoro',
	'app.demographic.country.response_congo': 'Kongo',
	'app.demographic.country.response_congo,_democratic_republic': 'Kongo, Jamhuri ya Kidemokrasia',
	'app.demographic.country.response_cook_islands': 'Visiwa vya Cook',
	'app.demographic.country.response_costa_rica': 'Costa Rica',
	'app.demographic.country.response_cote_divoire': 'Côte d\'Ivoire ',
	'app.demographic.country.response_croatia': 'Croatia',
	'app.demographic.country.response_cuba': 'Cuba',
	'app.demographic.country.response_cyprus': 'Cyprus',
	'app.demographic.country.response_czech_republic': 'Czech Republic',
	'app.demographic.country.response_denmark': 'Denmark',
	'app.demographic.country.response_djibouti': 'Djibouti',
	'app.demographic.country.response_dominica': 'Dominika',
	'app.demographic.country.response_dominican_republic': 'Jamhuri ya Dominika',
	'app.demographic.country.response_ecuador': 'Ecuador',
	'app.demographic.country.response_egypt': 'Misri',
	'app.demographic.country.response_el_salvador': 'El Salvador',
	'app.demographic.country.response_equatorial_guinea': 'Guinea ya Ikweta',
	'app.demographic.country.response_eritrea': 'Eritrea',
	'app.demographic.country.response_estonia': 'Estonia',
	'app.demographic.country.response_ethiopia': 'Ethiopia',
	'app.demographic.country.response_euro_area': 'Eneo la Euro',
	'app.demographic.country.response_falkland_islands': 'Visiwa vya Falkland',
	'app.demographic.country.response_fiji': 'Fiji',
	'app.demographic.country.response_finland': 'Finland',
	'app.demographic.country.response_france': 'Ufaransa',
	'app.demographic.country.response_french_guiana': 'Guyana ya Ufaransa',
	'app.demographic.country.response_french_polynesia': 'Polinesia ya Ufaransa',
	'app.demographic.country.response_gabon': 'Gabon',
	'app.demographic.country.response_gambia': 'Gambia',
	'app.demographic.country.response_georgia': 'Georgia',
	'app.demographic.country.response_germany': 'Ujerumani',
	'app.demographic.country.response_ghana': 'Ghana',
	'app.demographic.country.response_gibraltar': 'Gibraltar',
	'app.demographic.country.response_greece': 'Ugiriki',
	'app.demographic.country.response_grenada': 'Grenada',
	'app.demographic.country.response_guadeloupe': 'Guadeloupe',
	'app.demographic.country.response_guam': 'Guam',
	'app.demographic.country.response_guatemala': 'Guatemala',
	'app.demographic.country.response_guernsey': 'Guernsey',
	'app.demographic.country.response_guinea': 'Guinea',
	'app.demographic.country.response_guinea_bissau': 'Guinea Bissau',
	'app.demographic.country.response_guyana': 'Guyana',
	'app.demographic.country.response_haiti': 'Haiti',
	'app.demographic.country.response_honduras': 'Honduras',
	'app.demographic.country.response_hungary': 'Hungary',
	'app.demographic.country.response_iceland': 'Iceland',
	'app.demographic.country.response_india': 'India',
	'app.demographic.country.response_indonesia': 'Indonesia',
	'app.demographic.country.response_iran': 'Iran',
	'app.demographic.country.response_iraq': 'Iraq',
	'app.demographic.country.response_ireland': 'Ireland',
	'app.demographic.country.response_isle_of_man': 'Isle of Man',
	'app.demographic.country.response_israel': 'Israel',
	'app.demographic.country.response_italy': 'Italia',
	'app.demographic.country.response_jamaica': 'Jamaica',
	'app.demographic.country.response_japan': 'Japani',
	'app.demographic.country.response_jersey': 'Jersey',
	'app.demographic.country.response_jordan': 'Jordan',
	'app.demographic.country.response_kazakhstan': 'Kazakhstan',
	'app.demographic.country.response_kenya': 'Kenya',
	'app.demographic.country.response_kiribati': 'Kiribati',
	'app.demographic.country.response_kosovo': 'Kosovo',
	'app.demographic.country.response_kuwait': 'Kuwait',
	'app.demographic.country.response_kyrgyzstan': 'Kyrgyzstan',
	'app.demographic.country.response_laos': 'Laos',
	'app.demographic.country.response_latvia': 'Latvia',
	'app.demographic.country.response_lebanon': 'Lebanon',
	'app.demographic.country.response_lesotho': 'Lesotho',
	'app.demographic.country.response_liberia': 'Liberia',
	'app.demographic.country.response_libya': 'Libya',
	'app.demographic.country.response_liechtenstein': 'Liechtenstein',
	'app.demographic.country.response_lithuania': 'Lithuania',
	'app.demographic.country.response_luxembourg': 'Luxembourg',
	'app.demographic.country.response_macau': 'Macau',
	'app.demographic.country.response_macedonia': 'Masedonia',
	'app.demographic.country.response_madagascar': 'Madagascar',
	'app.demographic.country.response_malaysia': 'Malaysia',
	'app.demographic.country.response_malawi': 'Malawi',
	'app.demographic.country.response_maldives': 'Maldives',
	'app.demographic.country.response_mali': 'Mali',
	'app.demographic.country.response_malta': 'Malta',
	'app.demographic.country.response_mariana': 'Mariana',
	'app.demographic.country.response_marshall_islands': 'Visiwa vya Marshall',
	'app.demographic.country.response_martinique': 'Martinique',
	'app.demographic.country.response_mauritania': 'Mauritania',
	'app.demographic.country.response_mauritius': 'Mauritius',
	'app.demographic.country.response_mexico': 'Mexico',
	'app.demographic.country.response_micronesia': 'Micronesia',
	'app.demographic.country.response_moldova': 'Moldova',
	'app.demographic.country.response_monaco': 'Monaco',
	'app.demographic.country.response_mongolia': 'Mongolia',
	'app.demographic.country.response_montserrat': 'Montserrat',
	'app.demographic.country.response_montenegro': 'Montenegro',
	'app.demographic.country.response_morocco': 'Morocco',
	'app.demographic.country.response_mozambique': 'Msumbiji',
	'app.demographic.country.response_myanmar': 'Myanmar',
	'app.demographic.country.response_namibia': 'Namibia',
	'app.demographic.country.response_nepal': 'Nepal',
	'app.demographic.country.response_netherland_antilles': 'Netherland Antilles',
	'app.demographic.country.response_netherlands': 'Uholanzi',
	'app.demographic.country.response_new_caledonia': 'New Caledonia',
	'app.demographic.country.response_new_zealand': 'New Zealand',
	'app.demographic.country.response_nicaragua': 'Nicaragua',
	'app.demographic.country.response_niger': 'Niger',
	'app.demographic.country.response_nigeria': 'Nigeria',
	'app.demographic.country.response_north_korea': 'Korea Kaskazini',
	'app.demographic.country.response_norway': 'Norway',
	'app.demographic.country.response_oman': 'Oman',
	'app.demographic.country.response_pakistan': 'Pakistan',
	'app.demographic.country.response_palau': 'Palau',
	'app.demographic.country.response_panama': 'Panama',
	'app.demographic.country.response_papua_new_guinea': 'Papua New Guinea',
	'app.demographic.country.response_paraguay': 'Paraguay',
	'app.demographic.country.response_peru': 'Peru',
	'app.demographic.country.response_phillipines': 'Ufilipino',
	'app.demographic.country.response_poland': 'Poland',
	'app.demographic.country.response_portugal': 'Ureno',
	'app.demographic.country.response_puerto_rico': 'Puerto Rico',
	'app.demographic.country.response_qatar': 'Qatar',
	'app.demographic.country.response_reunion': 'Reunion',
	'app.demographic.country.response_romania': 'Romania',
	'app.demographic.country.response_russian_federation': 'Shirikisho la Urusi',
	'app.demographic.country.response_rwanda': 'Rwanda',
	'app.demographic.country.response_st_helena': 'St Helena',
	'app.demographic.country.response_st_kitts-nevis': 'St Kitts-Nevis',
	'app.demographic.country.response_st_lucia': 'St Lucia',
	'app.demographic.country.response_st_vincent_grenadines': 'St Vincent & Grenadines',
	'app.demographic.country.response_stateless': 'isiyo na serikali',
	'app.demographic.country.response_samoa': 'Samoa',
	'app.demographic.country.response_san_marino': 'San Marino',
	'app.demographic.country.response_sao_tome_principe': 'Sao Tome & Principe',
	'app.demographic.country.response_saudi_arabia': 'Saudi Arabia',
	'app.demographic.country.response_senegal': 'Senegal',
	'app.demographic.country.response_seychelles': 'Shelisheli',
	'app.demographic.country.response_serbia': 'Serbia',
	'app.demographic.country.response_sierra_leone': 'Sierra Leone',
	'app.demographic.country.response_singapore': 'Singapore',
	'app.demographic.country.response_slovak_republic': 'Jamhuri ya Kislovakia',
	'app.demographic.country.response_slovenia': 'Slovenia',
	'app.demographic.country.response_solomon_islands': 'Visiwa vya Solomon',
	'app.demographic.country.response_somalia': 'Somalia',
	'app.demographic.country.response_south_africa': 'Afrika Kusini',
	'app.demographic.country.response_south_korea': 'Korea Kusini',
	'app.demographic.country.response_spain': 'Hispania',
	'app.demographic.country.response_sri_lanka': 'Sri Lanka',
	'app.demographic.country.response_sudan': 'Sudan',
	'app.demographic.country.response_suriname': 'Suriname',
	'app.demographic.country.response_swaziland': 'Swaziland',
	'app.demographic.country.response_sweden': 'Sweden',
	'app.demographic.country.response_switzerland': 'Switzerland',
	'app.demographic.country.response_syria': 'Syria',
	'app.demographic.country.response_taiwan': 'Taiwan',
	'app.demographic.country.response_tajikistan': 'Tajikistan',
	'app.demographic.country.response_tanzania': 'Tanzania',
	'app.demographic.country.response_thailand': 'Thailand',
	'app.demographic.country.response_togo': 'Togo',
	'app.demographic.country.response_timor_leste': 'Timor-Leste',
	'app.demographic.country.response_tonga': 'Tonga',
	'app.demographic.country.response_trinidad_tobago': 'Trinidad na Tobago',
	'app.demographic.country.response_tunisia': 'Tunisia',
	'app.demographic.country.response_turkey': 'Uturuki',
	'app.demographic.country.response_turkmenistan': 'Turkmenistan',
	'app.demographic.country.response_turks_caicos_is': 'Turks & Caicos Is',
	'app.demographic.country.response_tuvalu': 'Tuvalu',
	'app.demographic.country.response_uganda': 'Uganda',
	'app.demographic.country.response_ukraine': 'Ukraine',
	'app.demographic.country.response_united_arab_erimates': 'United Arab Erimates',
	'app.demographic.country.response_united_kingdom': 'Uingereza',
	'app.demographic.country.response_uraguay': 'Uraguay',
	'app.demographic.country.response_uzbekistan': 'Uzbekistan',
	'app.demographic.country.response_vanuatu': 'Vanuatu',
	'app.demographic.country.response_venezuela': 'Venezuela',
	'app.demographic.country.response_vietnam': 'Vietnam',
	'app.demographic.country.response_virgin_islands': 'Visiwa vya Virgin',
	'app.demographic.country.response_west_bank_and_gaza': 'Ukingo wa Magharibi na Gaza',
	'app.demographic.country.response_yemen': 'Yemen',
	'app.demographic.country.response_zambia': 'Zambia',
	'app.demographic.country.response_zimbabwe': 'Zimbabwe',
	'app.demographic.level_of_fluency.i_do_not_speak': 'Sizungumzi lugha yoyote isipokuwa lugha yangu ya asili',
	'app.demographic.level_of_fluency.minimally_fluent': 'Fasaha kidogo',
	'app.demographic.level_of_fluency.somewhat_fluent': 'Fasaha kiasi',
	'app.demographic.level_of_fluency.moderately_fluent': 'Mwenye ufasaha kiasi',
	'app.demographic.level_of_fluency.mostly_fluent': 'Mwenye ufasaha zaidi',
	'app.demographic.level_of_fluency.fluent': 'Fasaha',
	'app.demographic.level_of_interest.not_applicable': 'Haitumiki',
	'app.demographic.level_of_interest.extremely_low': 'Chini mno',
	'app.demographic.level_of_interest.very_low': 'Chini sana',
	'app.demographic.level_of_interest.low': 'Chini',
	'app.demographic.level_of_interest.neutral': 'Katikati',
	'app.demographic.level_of_interest.high': 'Juu',
	'app.demographic.level_of_interest.very_high': 'Juu sana',
	'app.demographic.level_of_interest.extremely_high': 'Juu mno',
	'app.demographic_slider_default_option': 'Tafadhali sogeza kiteuzi ili kuchagua jibu lako.',
	'app.demographic.formal_education.some_high_school_or_less': 'Shule ya upili au chini',
	'app.demographic.formal_education.high_school_graduate': 'Mhitimu wa shule ya upili',
	'app.demographic.formal_education.some_college': 'Chuo',
	'app.demographic.formal_education.college_degree': 'Shahada ya chuo',
	'app.demographic.formal_education.some_graduate_school': 'Shule ya wahitimu',
	'app.demographic.formal_education.graduate_degree': 'Shahada ya uzamili (k.m. Shahada ya pili)',
	'app.demographic.formal_education.professional_degree': 'Shahada ya kitaaluma (k.m., Sheria)',
	'app.demographic.formal_education.doctoral_degree': 'Shahada ya udaktari',
}

export const demographicQuestionsSW: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
