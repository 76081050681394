import { useValues } from 'kea';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { HomeScreenTemplate } from '../components/HomeScreenTemplate';
import { IntlProviderWrapper, LocalStorage_BeviLanguage } from '../IntlProviderWrapper';
import { ExternalTemplate, InternalTemplate } from '../pages/_Shared';
import { NoInfoTemplate } from '../pages/_Shared/NoInfoTemplate/NoInfoTemplate';
import { ChangePassword } from '../pages/ChangePassword';
import { ComparativeReportByGroup } from '../pages/ComparativeReportByGroup';
import { ComparativeReportByIndividual } from '../pages/ComparativeReportByIndividual';
import { Consent } from '../pages/Consent';
import { CreateProgram } from '../pages/CreateProgram';
import { CreateReport } from '../pages/CreateReport';
import { CreateUser, EditUser } from '../pages/CreateUser';
import { HideDemographicsQuestions } from '../pages/HideDemographicsQuestions';
import { DemographicQuestions } from '../pages/DemographicQuestions';
import { DiscursiveQuestions } from '../pages/DiscursiveQuestions';
import { NotAvailableForm, PageMaintenance, PageNoAuthorized, PageNotFound } from '../pages/Error';
import { ExternalBevi, ThanksForFinishingForm } from '../pages/ExternalBevi';
import { FinancialReport } from '../pages/FinancialReport';
import { ForgotPassword } from '../pages/ForgotPassword';
import { GroupReport } from '../pages/GroupReport';
import { IndividualReport } from '../pages/IndividualReport';
import { ComparativeIndividualReport } from '../pages/ComparativeIndividualReport';
import { ListForms } from '../pages/ListForms';
import { ListOrganization } from '../pages/ListOrganization';
import { ListReport } from '../pages/ListReport';
import { ListUsers } from '../pages/ListUsers';
import { ListInstitutions } from '../pages/ListInstitutions';
import { Login } from '../pages/Login';
import { LongitudinalReport } from '../pages/LongitudinalReport';
import { ScaleQuestions } from '../pages/ScaleQuestions';
import { SubProgramDetails } from '../pages/SubProgramDetails';
import { UserProfilePermission } from '../pages/UserProfilePermission';
import { UserPrograms } from '../pages/UserPrograms';
import { appLogic, AppProps } from '../redux/appLogic';
import { formLogic, FormsProps } from '../redux/formsLogic';
import { HasPermissionCapability, HasPermissionCapabilityProgramAdmin, IsAccountManager, IsInstitutional, IsParticipant, IsProgramAdmin, IsSuperAdmin } from '../utils/userHelper';
import { ValidateExternalTokenInfo, ValidateTokenInfo } from '../utils/validateHelper';
import {
    CHANGE_PASSWORD_PAGE, COMPARATIVE_BY_GROUP_REPORT_PAGE, COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE,
    CONSENT_PAGE, CREATE_PROGRAM, CREATE_REPORT_PAGE, CREATE_SUBPROGRAM, CREATE_USER_PAGE,
    DASHBOARD_PAGE, DEMOGRAPHIC_QUESTIONS_PAGE, DISCURSIVE_QUESTIONS_PAGE, EDIT_REPORT_PAGE,
    EDIT_SUBPROGRAM, EDIT_USER_PAGE, EXTERNAL_BEVI_ACCESS, EXTERNAL_CONSENT_PAGE,
    EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE, EXTERNAL_DISCURSIVE_QUESTIONS_PAGE,
    EXTERNAL_INDIVIDUAL_REPORT_PAGE, EXTERNAL_SCALE_QUESTIONS_PAGE,
    EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE, FINANCIAL_REPORT_PAGE, FIRST_ACCESS_PAGE, FORGOT_PASSWORD_PAGE,
    GROUP_REPORT_PAGE, INDIVIDUAL_REPORT_PAGE, INVITATION_PAGE, LIST_USERS_PAGE, LOGIN_PAGE,
    LONGITUDINAL_REPORT_PAGE, MAINTENANCE, NOT_AUTHORIZED, NOT_AVAILABLE_FORM, NOT_FOUND,
    ORGANIZATION_PAGE, ORGANIZATION_DEMOGRAPHICS_EXTRA_QUESTIONS_PAGE, REPORT_PAGE, SCALE_QUESTIONS_PAGE, SUBPROGRAM_DETAILS,
    USER_PROFILE_PERMISSION, USER_PROGRAMS_PAGE, PROGRAMS_PAGE, HIDE_DEMOGRAPHICS_QUESTIONS, LOADING_MY_BEVI, VIRTUAL_ACCOUNT, COMPARATIVE_INDIVIDUAL_REPORT_PAGE, COMPARATIVE_INDIVIDUAL_PAGE, LONGITUDINAL_COMPARATIVE_INDIVIDUAL_REPORT_PAGE, COMBINED_INDIVIDUAL_REPORT_PAGE, REPORT_PERMISSION,
    SAMPLE_DATA_REPORT_PAGE, TWO_FACTOR_AUTHENTICATOR
} from './pages';
import { FirstAccessController } from '../pages/FirstAccess/FirstAccessController';
import { Invitation } from '../pages/Invitation';
import { isMaintenence } from '../config/constants';
import { DemographicExtraQuestions } from '../pages/DemographicExtraQuestions';
import { LoadingMyBevi } from '../pages/LoadingMyBevi';
import { VirtalAccount } from '../pages/VirtualAccount';
import { UserPermissions } from '../services/usersService';
import { ReportPermission } from '../pages/ReportPermission';
import { SampleDataReport } from '../pages/SampleDataReport';
import { TwoFactorAuthenticator } from '../pages/TwoFactorAuthenticator';

//@ts-ignore
const GetRedirectRouteToUnavailablePage = (...rest) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to={{ pathname: MAINTENANCE, state: { from: props.location } }} push />
            }
        />
    );
}

//@ts-ignore
const GetRedirectRouteToNotAuthorizedPage = (...rest) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to={{ pathname: NOT_AUTHORIZED, state: { from: props.location } }} push />
            }
        />
    );
}

//@ts-ignore
const GetRedirectRouteToFirstPage = (...rest) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to={{ pathname: FIRST_ACCESS_PAGE, state: { from: props.location } }} push />
            }
        />
    );
}

//@ts-ignore
const GetRedirectRouteToLoginPage = (...rest) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to={{ pathname: LOGIN_PAGE, state: { from: props.location } }} push />
            }
        />
    );
}

//@ts-ignore
const HomePublicRoute = ({ component: Component, isUnavailable, ...rest }) => {
    const isJapanese = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    if (isUnavailable || isMaintenence) {
        return GetRedirectRouteToUnavailablePage(rest);
    }

    return (
        <Route
            {...rest}
            render={props =>
                <HomeScreenTemplate
                    key={Math.ceil(Math.random() * 100)}
                    bodyComponent={<Component {...props} />}
                    isJapanese={isJapanese}
                />
            }
        />
    );
}

//@ts-ignore
const NoInfoPublicRoute = ({ component: Component, ...rest }) => {
    const isJapanese = localStorage.getItem(LocalStorage_BeviLanguage) === "ja";

    return (
        <Route
            {...rest}
            render={props =>
                <NoInfoTemplate
                    key={Math.ceil(Math.random() * 100)}
                    component={<Component {...props} />}
                    isJapanese={isJapanese}
                />
            }
        />
    );
}

//@ts-ignore
const PrivateRoute = ({ component: Component, isUnavailable, hasValidToken, notAuthorized, isFirstPage, ...rest }) => {
    if (!hasValidToken) {
        return GetRedirectRouteToLoginPage(rest);
    }

    if (isUnavailable || isMaintenence) {
        return GetRedirectRouteToUnavailablePage(rest);
    }

    if (isFirstPage) {
        return GetRedirectRouteToFirstPage(rest);
    }

    if (notAuthorized) {
        return GetRedirectRouteToNotAuthorizedPage(rest);
    }

    return (
        <Route
            {...rest}
            render={props =>
                <InternalTemplate component={<Component {...props} key={Math.ceil(Math.random() * 100)} />} />
            }
        />
    );
}

//@ts-ignore
const PrivateFormRoute = ({ component: Component, isUnavailable, hasValidToken, notAuthorized, path, ...rest }) => {
    const { formId, formStatus, showReport }: FormsProps = useValues(formLogic);

    if (!hasValidToken) {
        return GetRedirectRouteToLoginPage(rest);
    }

    if (isUnavailable || isMaintenence) {
        return GetRedirectRouteToUnavailablePage(rest);
    }

    if (notAuthorized) {
        return GetRedirectRouteToNotAuthorizedPage(rest);
    }

    if (path !== COMPARATIVE_INDIVIDUAL_PAGE) {
        let redirectPath: string = "";

        if (!formId) {
            redirectPath = DASHBOARD_PAGE;
        } else {
            if (formStatus === 1) {
                if (path !== CONSENT_PAGE) redirectPath = CONSENT_PAGE;
            } else if (formStatus === 2) {
                if (path !== DEMOGRAPHIC_QUESTIONS_PAGE) redirectPath = DEMOGRAPHIC_QUESTIONS_PAGE;
            } else if (formStatus === 3) {
                if (path !== SCALE_QUESTIONS_PAGE) redirectPath = SCALE_QUESTIONS_PAGE;
            } else if (formStatus === 4) {
                if (path !== DISCURSIVE_QUESTIONS_PAGE) redirectPath = DISCURSIVE_QUESTIONS_PAGE;
            } else if (formStatus === 5 && showReport === true) {
                if (path !== INDIVIDUAL_REPORT_PAGE) redirectPath = INDIVIDUAL_REPORT_PAGE;
            } else {
                redirectPath = DASHBOARD_PAGE;
            }
        }

        if (redirectPath !== "") {
            return (
                <Route
                    {...rest}
                    render={props =>
                        <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} push />
                    }
                />
            );
        }
    }

    return (
        <Route
            {...rest}
            render={props =>
                <InternalTemplate component={<Component {...props} key={Math.ceil(Math.random() * 100)} />} />
            }
        />
    );
}

//@ts-ignore
const ExternalLoginRoute = ({ component: Component, isUnavailable, ...rest }) => {
    if (isUnavailable || isMaintenence) {
        return GetRedirectRouteToUnavailablePage(rest);
    }

    return (
        <Route
            {...rest}
            render={props => <ExternalTemplate component={<Component {...props} />} />}
        />
    );
}

//@ts-ignore
const ExternalFormRoute = ({ component: Component, hasValidToken, isUnavailable, notAuthorized, ...rest }) => {
    if (!hasValidToken) {
        // TODO(rodrigo.santos): Redirect to external erro page.
        return GetRedirectRouteToLoginPage(rest);
    }

    if (isUnavailable || isMaintenence) {
        return GetRedirectRouteToUnavailablePage(rest);
    }

    if (notAuthorized) {
        return GetRedirectRouteToNotAuthorizedPage(rest);
    }

    return (
        <Route
            {...rest}
            render={props =>
                <ExternalTemplate component={<Component {...props} key={Math.ceil(Math.random() * 100)} />} />
            }
        />
    );

}

export const Routes = (): JSX.Element => {
    const { tokenInfo, serviceUnavailable, notAuthorized }: AppProps = useValues(appLogic);

    return (
        <BrowserRouter>
            <IntlProviderWrapper>
                <Switch>
                    <HomePublicRoute
                        exact={true} path={LOGIN_PAGE} isUnavailable={serviceUnavailable === true} component={Login}
                    />
                    <HomePublicRoute
                        exact={true} path={FORGOT_PASSWORD_PAGE} isUnavailable={serviceUnavailable === true} component={ForgotPassword}
                    />
                    <HomePublicRoute
                        exact={true}
                        path={CHANGE_PASSWORD_PAGE}
                        isUnavailable={serviceUnavailable === true}
                        component={ChangePassword}
                    />
                    <HomePublicRoute
                        exact={true}
                        path={INVITATION_PAGE}
                        isUnavailable={serviceUnavailable === true}
                        component={Invitation}
                    />
                    <HomePublicRoute
                        exact={true} path={LOADING_MY_BEVI} isUnavailable={serviceUnavailable === true} component={LoadingMyBevi}
                    />
                    <PrivateRoute
                        exact={true} path={DASHBOARD_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ListForms}
                    />
                    <PrivateRoute
                        exact={true} path={REPORT_PERMISSION} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ReportPermission}
                    />
                    <PrivateRoute
                        exact={true} path={PROGRAMS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeProgramExperience)
                        }
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ListOrganization}
                    />
                    <PrivateRoute
                        exact={true} path={CREATE_PROGRAM} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)  ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canAddProgramExperience)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateProgram}
                    />
                    <PrivateRoute
                        exact={true} path={CREATE_SUBPROGRAM} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canAddSubProgram)
                        }
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateProgram}
                    />
                    <PrivateRoute
                        exact={true} path={EDIT_SUBPROGRAM} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canEditSubProgram)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateProgram}
                    />
                    <PrivateRoute
                        exact={true} path={LIST_USERS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeUser)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ListUsers}
                    />
                    <PrivateRoute
                        exact={true} path={ORGANIZATION_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ListInstitutions}
                    />
                    <PrivateRoute
                        exact={true} path={ORGANIZATION_DEMOGRAPHICS_EXTRA_QUESTIONS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={DemographicExtraQuestions}
                    />
                    <PrivateRoute
                        exact={true} path={USER_PROGRAMS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeUserPrograms)
                        }
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={UserPrograms}
                    />
                    <PrivateRoute
                        exact={true} path={CREATE_USER_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateUser}
                    />
                    <PrivateRoute
                        exact={true} path={EDIT_USER_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canManageUser)
                        }
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={EditUser}
                    />
                    <PrivateRoute
                        exact={true} path={HIDE_DEMOGRAPHICS_QUESTIONS} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={HideDemographicsQuestions}
                    />
                    <PrivateRoute
                        exact={true} path={REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canVisualizeReport)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ListReport}
                    />
                    <PrivateRoute
                        exact={true} path={CREATE_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)  ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canAddReport)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateReport}
                    />
                    <PrivateRoute
                        exact={true} path={EDIT_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={CreateReport}
                    />
                    <PrivateRoute
                        exact={true} path={SUBPROGRAM_DETAILS} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo) ||
                            !HasPermissionCapabilityProgramAdmin(tokenInfo, UserPermissions.canManageSubProgram)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={SubProgramDetails}
                    />
                    <PrivateRoute
                        exact={true} path={GROUP_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={GroupReport}
                    />
                    <PrivateRoute
                        exact={true} path={LONGITUDINAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={LongitudinalReport}
                    />
                    <PrivateRoute
                        exact={true} path={COMPARATIVE_BY_GROUP_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeReportByGroup}
                    />
                    <PrivateRoute
                        exact={true} path={COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeReportByIndividual}
                    />
                    <PrivateRoute
                        exact={true} path={COMPARATIVE_INDIVIDUAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeIndividualReport}
                    />
                    <PrivateRoute
                        exact={true} path={LONGITUDINAL_COMPARATIVE_INDIVIDUAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeIndividualReport}
                    />
                    <PrivateRoute
                        exact={true} path={COMBINED_INDIVIDUAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeIndividualReport}
                    />
                    <PrivateFormRoute
                        exact={true} path={CONSENT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={Consent}
                    />
                    <PrivateFormRoute
                        exact={true} path={DEMOGRAPHIC_QUESTIONS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={DemographicQuestions}
                    />
                    <PrivateFormRoute
                        exact={true} path={SCALE_QUESTIONS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ScaleQuestions}
                    />
                    <PrivateFormRoute
                        exact={true} path={DISCURSIVE_QUESTIONS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={DiscursiveQuestions}
                    />
                    <PrivateFormRoute
                        exact={true} path={INDIVIDUAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={false}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={IndividualReport}
                    />
                    <PrivateFormRoute
                        exact={true} path={COMPARATIVE_INDIVIDUAL_PAGE}
                        isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={false}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={ComparativeIndividualReport}
                    />

                    <PrivateRoute
                        exact={true} path={SAMPLE_DATA_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || IsParticipant(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={SampleDataReport}
                    />

                    <PrivateRoute
                        exact={true} path={USER_PROFILE_PERMISSION} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !IsSuperAdmin(tokenInfo)}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={UserProfilePermission}
                    />
                    <PrivateRoute
                        exact={true} path={FINANCIAL_REPORT_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized || !(IsSuperAdmin(tokenInfo) || IsAccountManager(tokenInfo))}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={FinancialReport}
                    />
                    <PrivateRoute
                        exact={true} path={FIRST_ACCESS_PAGE} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized}
                        isFirstPage={false}
                        component={FirstAccessController}
                    />
                    <PrivateRoute
                        exact={true} path={VIRTUAL_ACCOUNT} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized ||
                            (!IsSuperAdmin(tokenInfo) && !HasPermissionCapability(tokenInfo, UserPermissions.canVisualizeVirtualAccount))}
                        isFirstPage={tokenInfo && tokenInfo.first_access === 'True'}
                        component={VirtalAccount}
                    />
                    <PrivateRoute
                        exact={true} path={TWO_FACTOR_AUTHENTICATOR} isUnavailable={serviceUnavailable === true}
                        hasValidToken={ValidateTokenInfo(tokenInfo)} notAuthorized={notAuthorized ||
                            ((!IsSuperAdmin(tokenInfo) && !IsAccountManager && !IsProgramAdmin && !IsInstitutional))}
                        isFirstPage={false}
                        component={TwoFactorAuthenticator}
                    />
                    <ExternalLoginRoute
                        exact={true} path={EXTERNAL_BEVI_ACCESS}
                        isUnavailable={serviceUnavailable === true}
                        component={ExternalBevi}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_CONSENT_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={Consent}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={DemographicQuestions}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_SCALE_QUESTIONS_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={ScaleQuestions}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_DISCURSIVE_QUESTIONS_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={DiscursiveQuestions}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_INDIVIDUAL_REPORT_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={IndividualReport}
                    />
                    <ExternalFormRoute
                        exact={true} path={EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE}
                        hasValidToken={ValidateExternalTokenInfo(tokenInfo)}
                        notAuthorized={notAuthorized || !IsParticipant(tokenInfo)}
                        isUnavailable={serviceUnavailable === true}
                        component={ThanksForFinishingForm}
                    />

                    <NoInfoPublicRoute exact={true} path={NOT_AUTHORIZED} component={PageNoAuthorized} />
                    <NoInfoPublicRoute exact={true} path={MAINTENANCE} component={PageMaintenance} />
                    <NoInfoPublicRoute exact={true} path={NOT_AVAILABLE_FORM} component={NotAvailableForm} />
                    <NoInfoPublicRoute path={NOT_FOUND} component={PageNotFound} />
                </Switch>
            </IntlProviderWrapper>
        </BrowserRouter>
    );
}
