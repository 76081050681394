import React, { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import { DecileProfileItem } from '../services/reportService';
import { forEach } from 'lodash';
import { Button } from 'antd';

type Props = {
    consistency: DecileProfileItem[];
    congruency: DecileProfileItem[];
    negativeLifeEvents: DecileProfileItem[];
    needsClosure: DecileProfileItem[];
    needsFulfillment: DecileProfileItem[];
    identityDiffusion: DecileProfileItem[];
    basicOpenness: DecileProfileItem[];
    selfCertitude: DecileProfileItem[];
    basicDeterminism: DecileProfileItem[];
    socioemotionalConvergence: DecileProfileItem[];
    physicalResonance: DecileProfileItem[];
    emotionalAttunement: DecileProfileItem[];
    selfAwereness: DecileProfileItem[];
    meaningQuest: DecileProfileItem[];
    religiousTraditionalism: DecileProfileItem[];
    genderTraditionalism: DecileProfileItem[];
    socioculturalOpenness: DecileProfileItem[];
    ecologicalResonance: DecileProfileItem[];
    globalResonance: DecileProfileItem[];
}

const DecileProfileLineChart: React.FC<Props> = (props: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);

    const dataChart = useMemo(() => {
        const returnSeries: echarts.EChartOption.Series[] = [];
        const legend: string[] = [];

        forEach(props, (value, key) => {
            value.forEach((data) => {
                legend.push(data.decileTitle);
                returnSeries.push({
                    name: data.decileTitle,
                    type: 'line',
                    stack: 'Total',
                    data: [data.value1, data.value2, data.value3, data.value4, data.value5, data.value6, data.value7, data.value8, data.value9, data.value10]
                });
            });
        })

        return { series: returnSeries, dataLegend: legend };
    }, [props]);

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = echarts.init(chartRef.current);

            const option: echarts.EChartOption = {
                grid: {
                    left: 0,
                    right: '25%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    left: 'right',
                    top: 'center',
                    data: dataChart.dataLegend
                },
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    }
                },
                series: dataChart.series,
            };

            chartInstance.setOption(option);

            return () => {
                chartInstance.dispose();
            };
        }

    }, [dataChart]);

    return <>
        <div ref={chartRef} style={{ width: '100%', height: `calc(100vh - 200px)` }} />
    </>
};

export default DecileProfileLineChart;
