import { InternalTemplateMessages } from '../types';

export const internalTemplatePageZHTW: InternalTemplateMessages = {
    'app.internaltemplate.welcome': '欢迎',
    'app.internaltemplate.menu.dashboard': '仪表板(Dashboard)',
    'app.internaltemplate.menu.users': '用户',
    'app.internaltemplate.menu.organization': '大学或机构',
    'app.internaltemplate.menu.report': '结果',
    'app.internaltemplate.menu.sample_report': '報告樣本',
    'app.internaltemplate.menu.permission_profile': 'Permission Profile',
    'app.internaltemplate.menu.financial_report': 'Usage Report',
    'app.internaltemplate.menu.logout': '退出',
    'app.internaltemplate.menu.virtualAccount': 'Virtual account',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': '配置雙因素認證',
    'app.internaltemplate.menu.institutions': 'Organizations',
    'app.internaltemplate.system.maintenance_time': 'The system will be under maintenance from {0} to {1}.',
    'app.internaltemplate.menu.report_permission': 'Report Permission',
}
