import { InternalTemplateMessages } from '../types';

export const internalTemplatePageDE: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'Willkommen',
    'app.internaltemplate.menu.dashboard': 'Dashboard',
    'app.internaltemplate.menu.users': 'Benutzer:innen',
    'app.internaltemplate.menu.organization': 'Programme und Erfahrungen',
    'app.internaltemplate.menu.institutions': 'Organisationen',
    'app.internaltemplate.menu.report': 'Berichte',
    'app.internaltemplate.menu.sample_report': 'Beispielbericht',
    'app.internaltemplate.menu.permission_profile': 'Erlaubnisprofil',
    'app.internaltemplate.menu.financial_report': 'Verwendungsbericht',
    'app.internaltemplate.menu.logout': 'Logout',
    'app.internaltemplate.menu.virtualAccount': 'Virtual account',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': 'Zwei-Faktor-Authentifizierung konfigurieren',
    'app.internaltemplate.system.maintenance_time': 'Das System wird von {0} bis {1} gewartet.',
    'app.internaltemplate.menu.report_permission': 'Berichtsberechtigung'
}
