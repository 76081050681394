import { useActions, useValues } from 'kea';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import search from '../../assets/images/no_info_search.png';
import { Loading } from '../../components/Loading';
import { NoInfo } from '../../components/NoInfo';
import { appLogic, AppProps } from '../../redux/appLogic';
import {
    ListOrganizationActions, listOrganizationLogic, ListOrganizationProps
} from '../../redux/listOrganizationLogic';
import { UsersActions, usersLogic, UsersProps } from '../../redux/usersLogic';
import { CREATE_USER_PAGE, EDIT_USER_PAGE, USER_PROGRAMS_PAGE } from '../../router/pages';
import { ResetUserPasswordType } from '../../services/usersService';
import { FilterUsers } from './components';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import { ListUsers } from './ListUsers';
import { Remove2FAModal } from './components/Remove2FAModal';

type Props = RouteComponentProps;

export const ListUsersContainer = withRouter((props: Props): JSX.Element => {

    const { tokenInfo }: AppProps = useValues(appLogic);

    const { organizations, loadingPage }: ListOrganizationProps = useValues(listOrganizationLogic);
    const { getOrganizations, setSelectedUserId }: ListOrganizationActions = useActions(listOrganizationLogic);
    const { clearSuccessfulCreateNewUser, searchUsers, loadUserProgramDetails, resetUserResetPassword, clearUserResetPassword, clearRemove2FA, remove2FA }: UsersActions = useActions(usersLogic);
    const { users, loadingSearchUsers, searchUserData, resetPasswordSuccessful, errorResetPassword, remove2FASuccessful, errorRemove2FA }: UsersProps = useValues(usersLogic);

    const [selectedUser, setSelectedUser] = useState<any>(undefined);
    const [loadingResetUserPassword, setLoadingResetUserPassword] = useState<boolean>(false);
    const [selectedUserRemove2FA, setSelectedUserRemove2FA] = useState<any>(undefined);
    const [loadingRemove2FAPassword, setLoadingRemove2FAPassword] = useState<boolean>(false);
    const [lastSearch, setLastSearch] = useState<{ organizationId: any, userNameQuery: any, userEmailQuery: any, userType?: any }>();

    useEffect(() => {
        if (searchUserData.organizationId !== undefined && !users?.length) {
            setLastSearch({
                organizationId: searchUserData.organizationId,
                userNameQuery: searchUserData.userNameQuery,
                userEmailQuery: searchUserData.userEmailQuery,
            })
            searchUsers(searchUserData.organizationId, searchUserData.userNameQuery, searchUserData.userEmailQuery);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (resetPasswordSuccessful === true || errorResetPassword === true) {
            setLoadingResetUserPassword(false);
        }
    }, [resetPasswordSuccessful, errorResetPassword]);

    useEffect(() => {
        if (remove2FASuccessful === true || errorRemove2FA === true) {
            setLoadingRemove2FAPassword(false);
        }
        if (remove2FASuccessful) {
            searchUsers(lastSearch?.organizationId, lastSearch?.userNameQuery, lastSearch?.userEmailQuery, lastSearch?.userType);
            setTimeout(() => {
                setSelectedUserRemove2FA(undefined);
                clearRemove2FA();
            }, 3000)
        }
    }, [remove2FASuccessful, errorRemove2FA, searchUsers, lastSearch, clearRemove2FA]);

    useEffect(() => {
        if (!organizations?.length) getOrganizations();
        // eslint-disable-next-line
    }, [getOrganizations]);

    const onCreateNewUserHandleClick = () => {
        clearSuccessfulCreateNewUser();
        props.history.push(CREATE_USER_PAGE);
    }

    const onEditUserHandleClick = (userId: number) => {
        clearSuccessfulCreateNewUser();
        setSelectedUserId(userId);
        props.history.push(EDIT_USER_PAGE);
    }

    const onUserProgramsHandleClick = (userId: number) => {
        loadUserProgramDetails(userId);
        props.history.push(USER_PROGRAMS_PAGE);
    }

    if (loadingPage) {
        return <Loading />
    }

    const onResetPasswordModalHide = () => {
        setSelectedUser(undefined);
        clearUserResetPassword();
    }

    const onRemove2FAModalHide = () => {
        setSelectedUserRemove2FA(undefined);
        clearRemove2FA();
    }

    const onResetPasswordClick = (password: string) => {
        setLoadingResetUserPassword(true);
        const passwords: ResetUserPasswordType[] = [];
        passwords.push({
            NewPassword: password,
            UserId: selectedUser.id
        })
        resetUserResetPassword(passwords);
    }

    const onRemove2FAClick = (id: number) => {
        setLoadingRemove2FAPassword(true);
        remove2FA(id);
    }

    return (
        <Fragment>
            <FilterUsers
                userInfo={tokenInfo}
                organizations={organizations}
                onCreateNewUserHandleClick={onCreateNewUserHandleClick}
                setLastSearch={setLastSearch}
            />
            {searchUserData?.organizationId === undefined
                ? null
                : (users === undefined || users == null || users.length === 0) && !loadingSearchUsers
                    ? (
                        <NoInfo
                            title={'app.no.info.title.no_data'}
                            subTitle={'app.no.info.subtitle.no_data'}
                            image={search}
                        />
                    ) : (
                        <>
                            <ListUsers
                                userInfo={tokenInfo}
                                data={users}
                                loading={loadingSearchUsers}
                                onUserProgramClicked={onUserProgramsHandleClick}
                                onEditUserClicked={onEditUserHandleClick}
                                onResetClick={setSelectedUser}
                                onRemove2FAClick={setSelectedUserRemove2FA}
                            />
                            <ResetPasswordModal
                                user={selectedUser}
                                loadingButton={loadingResetUserPassword}
                                showModal={selectedUser !== undefined}
                                onModalHide={onResetPasswordModalHide}
                                onChangePassword={onResetPasswordClick}
                                success={resetPasswordSuccessful === true}
                                error={errorResetPassword === true}
                            />
                            <Remove2FAModal
                                user={selectedUserRemove2FA}
                                loadingButton={loadingRemove2FAPassword}
                                showModal={selectedUserRemove2FA !== undefined}
                                onModalHide={onRemove2FAModalHide}
                                onRemove2FA={onRemove2FAClick}
                                success={remove2FASuccessful === true}
                                error={errorRemove2FA === true}
                            />
                        </>
                    )
            }
        </Fragment>
    )
});
