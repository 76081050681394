import { ListFormPageMessages } from '../types';

export const listFormsPageHI: ListFormPageMessages = {
    'app.listforms.title': 'मेरा BEVI डैशबोर्ड',
    'app.listforms.subtitle': 'BEVI डैशबोर्ड सिस्टम आपको BEVI लेने, आपके BEVI रिपोर्ट की समीक्षा और प्रबंधन करने की अनुमति देता है।',
    'app.listforms.organization': 'संस्थान',
    'app.listforms.participant': 'भागीदार',
    'app.listforms.program': 'कार्यक्रम',
    'app.listforms.take_by': 'नियत तिथि',
    'app.listforms.completed_on': 'पूरण तिथि',
    'app.listforms.progress': 'प्रगति',
    'app.listforms.status': 'स्थिति',
    'app.listforms.custom_agreement_status': 'कस्टम समझौता स्थिति',
    'app.listforms.action': 'क्रिया',
    'app.listforms.organization_tooltip': 'BEVI डैशबोर्ड सिस्टम आपको BEVI लेने, आपके BEVI रिपोर्ट की समीक्षा और प्रबंधन करने की अनुमति देता है।',
    'app.listforms.program_tooltip': 'कार्यक्रम या अनुभव का नाम जिसके लिए आप BEVI ले रहे हैं।',
    'app.listforms.take_by_tooltip': 'यह वह तिथि है जब तक BEVI पूरा किया जाना चाहिए।',
    'app.listforms.completed_on_tooltip': 'यह वह तिथि है जिस पर आपने BEVI पूरा किया।',
    'app.listforms.progress_tooltip': 'जिस हद तक आपने BEVI प्रशासन पूरा किया है (उदाहरण के लिए, 100% का मतलब है कि आपने BEVI पूरा कर लिया है)',
    'app.listforms.status_tooltip': 'स्थिति कॉलम टूलटिप',
    'app.listforms.action_tooltip': 'यहां, आप BEVI ले सकते हैं, पूरा कर सकते हैं, या BEVI को पुनः ले सकते हैं या अपने BEVI रिपोर्ट की समीक्षा कर सकते हैं। जो बॉक्स आप देख रहे हैं, वही आप वर्तमान में कर सकते हैं।',
    'app.listforms.all': 'सभी',
    'app.listforms.available': 'अभी शुरू नहीं हुआ',
    'app.listforms.onDemographics': 'जनसांख्यिकी पर',
    'app.listforms.onScales': 'BEVI प्रश्नों पर',
    'app.listforms.onSubjective': 'लिखित प्रतिक्रियाओं पर',
    'app.listforms.finished': 'पूर्ण',
    'app.listforms.cancelled': 'रद्द',
    'app.listforms.expired': 'समाप्त',
    'app.listforms.archived': 'आर्काइव किया गया',
    'app.listforms.invalid': 'अमान्य',
    'app.listforms.start': 'BEVI लें',
    'app.listforms.continue': 'BEVI पूरा करें',
    'app.listforms.report': 'BEVI रिपोर्ट की समीक्षा करें',
    'app.listforms.partner_report': 'साझेदार रिपोर्ट की समीक्षा करें',
    'app.listforms.partner_change_report': 'साझेदार परिवर्तन रिपोर्ट की समीक्षा करें',
    'app.listforms.restart': 'BEVI पुनः लें',
    'app.listforms.confirmation_title': 'निर्देश',
    'app.listforms.retart_confirmation_title': 'BEVI पुनः प्रारंभ करें',
    'app.listforms.restart.instruction.part1': 'BEVI पुनः प्रारंभ करने के लिए नीचे दिए गए बटन पर क्लिक करें।',
    'app.listforms.instruction.part1': 'निम्नलिखित उपकरण में पृष्ठभूमि जानकारी की वस्तुएँ और प्रति पृष्ठ 20 प्रश्नों के साथ 185 प्रश्न हैं। कृपया प्रत्येक प्रश्न को पढ़ें और अपनी प्रतिक्रिया का संकेत देने के लिए चार विकल्पों में से एक का चयन करें जो आपकी भावना को सबसे अच्छे तरीके से व्यक्त करता है: पूरी तरह सहमत, सहमत, असहमत, पूरी तरह असहमत।',
    'app.listforms.instruction.part2': 'एक बार जब आपने प्रत्येक प्रश्न सेट का उत्तर दे दिया है, तो आगे बढ़ने के लिए जारी रखें बटन दबाएँ; एक बार जारी रखें बटन दबाने के बाद आप पूर्ववर्ती उत्तरों को बदल नहीं सकते।',
    'app.listforms.instruction.part3': 'आपको BEVI के बारे में पढ़ना होगा और BEVI पूरा करने से पहले सूचित सहमति प्रदान करनी होगी।',
    'app.listforms.instruction.press.start': 'भाग लेने के लिए धन्यवाद। कृपया जब आप तैयार हों तो शुरू करें दबाएँ।',
    'app.listforms.comparative_individual_report': 'व्यक्तिगत रिपोर्ट की तुलना करें',
    'app.form.status_title': 'अनुपलब्ध BEVI फॉर्म',
    'app.form.status_finished_subtitle': 'लगता है कि फॉर्म पहले ही उत्तरित किया जा चुका है।',
    'app.form.status_finished_body': 'फॉर्म की स्थिति के बारे में कोई प्रश्न होने पर जिम्मेदार लोगों से संपर्क करें।',
    'app.form.status_cancelled_subtitle': 'लगता है कि फॉर्म रद्द कर दिया गया था',
    'app.form.status_cancelled_body': 'फॉर्म की स्थिति के बारे में कोई प्रश्न होने पर जिम्मेदार लोगों से संपर्क करें।',
    'app.form.status_expired_subtitle': 'लगता है कि फॉर्म समाप्त हो गया है',
    'app.form.status_expired_body': 'फॉर्म की स्थिति के बारे में कोई प्रश्न होने पर जिम्मेदार लोगों से संपर्क करें।',
    'app.form.invalid_status.by_time': 'समय द्वारा अमान्य',
    'app.form.invalid_status.by_number_of_questions': 'प्रश्नों की संख्या द्वारा अमान्य',
    'app.form.invalid_status.by_consecutive_answers': 'लगातार उत्तरों द्वारा अमान्य',
    'app.form.invalid_status.by_common_answers': 'सामान्य उत्तरों द्वारा अमान्य',
    'app.form.invalid_status.by_congruency': 'संगति द्वारा अमान्य',
    'app.form.invalid_status.by_consistency': 'संगतता द्वारा अमान्य',
    'app.form.invalid_status.by_wrong_scale_value': 'गलत स्केल मान द्वारा अमान्य',
    'app.form.custom_agreement.not_requested': 'अनुरोध नहीं किया गया',
    'app.form.custom_agreement.pending': 'लंबित',
    'app.form.custom_agreement.accepted': 'स्वीकृत',
    'app.form.custom_agreement.rejected': 'अस्वीकृत',
    'app.form.actions.tooltip.send_custom_agreement': 'कस्टम समझौता भेजें',
    'app.form.actions.tooltip.resend_custom_agreement': 'कस्टम समझौता पुनः भेजें',
    'app.form.actions.tooltip.show_report': 'रिपोर्ट दिखाएँ',
    'app.listforms.ai_interactions': 'एआई इंटरएक्शन'
}
