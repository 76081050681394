import { TimeMessages } from '../types';

export const timeHI: TimeMessages = {
  'app.time.day': 'दिन',
  'app.time.days': 'दिन',
  'app.time.hour': 'घंटा',
  'app.time.hours': 'घंटे',
  'app.time.minute': 'मिनट',
  'app.time.minutes': 'मिनट',
  'app.time.second': 'सेकंड',
  'app.time.seconds': 'सेकंड',
  'app.time.and': 'और',
}
