import { ListFormPageMessages } from '../types';

export const listFormsPageSW: ListFormPageMessages = {
	'app.listforms.title': 'Dashibodi yangu ya BEVI',
	'app.listforms.subtitle': 'Mfumo wa dashibodi wa BEVI hukuruhusu kuchukua BEVI na kukagua na kudhibiti ripoti zako za BEVI.',
	'app.listforms.organization': 'Shirika',
	'app.listforms.participant': 'Mshiriki',
	'app.listforms.program': 'Programu',
	'app.listforms.take_by': 'Tarehe ya Kukamilisha',
	'app.listforms.completed_on': 'Tarehe ya Kukamilishwa',
	'app.listforms.progress': 'Maendeleo',
	'app.listforms.status': 'Hali',
	'app.listforms.custom_agreement_status': 'Hali Maalum ya Makubaliano',
	'app.listforms.action': 'Kitendo',
	'app.listforms.organization_tooltip': 'Mfumo wa dashibodi wa BEVI hukuruhusu kuchukua BEVI na kukagua na kudhibiti ripoti zako za BEVI.',
	'app.listforms.program_tooltip': 'Jina la programu au matumizi ambayo unaitumia BEVI.',
	'app.listforms.take_by_tooltip': 'Hii ndiyo tarehe ambayo BEVI inapaswa kukamilishwa',
	'app.listforms.completed_on_tooltip': 'Hii ndiyo tarehe ambayo BEVI ilikamilishwa na wewe',
	'app.listforms.progress_tooltip': 'Kiwango ambacho umekamilisha usimamizi wa BEVI (yaani, 100% inamaanisha kuwa umemaliza BEVI)',
	'app.listforms.status_tooltip': 'Kidokezo cha safu wima ya hali',
	'app.listforms.action_tooltip': 'Hapa, unaweza kuchukua, kukamilisha, au kuchukua tena BEVI au kukagua ripoti yako ya BEVI. Kisanduku unachokiona ndicho unachoweza kufanya kwa sasa.',
	'app.listforms.all': 'Yote',
	'app.listforms.available': 'Bado haijaanza',
	'app.listforms.onDemographics': 'Kwenye Demografia',
	'app.listforms.onScales': 'Kwenye Maswali ya BEVI',
	'app.listforms.onSubjective': 'Kwa Majibu Yanayoandikwa',
	'app.listforms.finished': 'Kamilisha',
	'app.listforms.cancelled': 'Imeghairiwa',
	'app.listforms.expired': 'Imeisha muda wake',
	'app.listforms.archived': 'Imehifadhiwa',
	'app.listforms.invalid': 'Batili',
	'app.listforms.start': 'Chukua BEVI',
	'app.listforms.continue': 'Kamilisha BEVI',
	'app.listforms.report': 'Kagua Ripoti ya BEVI',
	'app.listforms.partner_report': 'Kagua Ripoti ya Mshirika',
	'app.listforms.partner_change_report': 'Kagua Ripoti ya Mabadiliko ya Washirika',
	'app.listforms.restart': 'Chukua tena BEVI',
	'app.listforms.confirmation_title': 'Maelekezo',
	'app.listforms.retart_confirmation_title': 'Anzisha upya BEVI',
	'app.listforms.restart.instruction.part1': 'Bofya kitufe kilicho hapa chini ili kuanzisha upya BEVI.',
	'app.listforms.instruction.part1': 'Kifaa kifuatacho kina vipengee vya taarifa za asili na maswali 185 yenye maswali 20 kwa kila ukurasa. Tafadhali soma kila swali na uonyeshe jibu lako kwa kuchagua mojawapo ya chaguo nne zinazoelezea vyema jinsi unavyohisi: kubali kabisa, kubali, kutokubali, kutokubali kabisa.',
	'app.listforms.instruction.part2': 'Baada ya kujibu kila kundi la maswali, bonyeza endelea ili kuendelea; mara tu kitufe cha kuendelea kitakapobonyezwa huwezi kurudi nyuma na kubadilisha majibu ya awali.',
	'app.listforms.instruction.part3': 'Lazima uonyeshe kuwa umesoma kuhusu BEVI na utoe idhini ya ufahamu kabla ya kukamilisha BEVI.',
	'app.listforms.instruction.press.start': 'Asante kwa kushiriki. Tafadhali bonyeza anza ukiwa tayari.',
	'app.listforms.comparative_individual_report': 'Linganisha ripoti ya mtu binafsi',
	'app.form.status_title': 'Fomu ya BEVI haipatikani',
	'app.form.status_finished_subtitle': 'Inaonekana kama fomu tayari imejibiwa.',
	'app.form.status_finished_body': 'Wasiliana na wale wanaohusika ikiwa una maswali yoyote kuhusu hali ya fomu.',
	'app.form.status_cancelled_subtitle': 'Inaonekana kama fomu imeghairiwa',
	'app.form.status_cancelled_body': 'Wasiliana na wale wanaohusika ikiwa una maswali yoyote kuhusu hali ya fomu.',
	'app.form.status_expired_subtitle': 'Inaonekana fomu imeisha muda wake',
	'app.form.status_expired_body': 'Wasiliana na wale wanaohusika ikiwa una maswali yoyote kuhusu hali ya fomu.',
	'app.form.invalid_status.by_time': 'Batili kwa Wakati',
	'app.form.invalid_status.by_number_of_questions': 'Batili kwa Idadi ya Maswali',
	'app.form.invalid_status.by_consecutive_answers': 'Batili kwa Majibu Mfululizo',
	'app.form.invalid_status.by_common_answers': 'Batili kwa Majibu ya Kawaida',
	'app.form.invalid_status.by_congruency': 'Batili kwa Ulinganifu',
	'app.form.invalid_status.by_consistency': 'Batili kwa Uthabiti',
	'app.form.invalid_status.by_wrong_scale_value': 'Batili kwa Thamani ya Mizani Isiyo Sahihi',
	'app.form.custom_agreement.not_requested': 'Haijaombwa',
	'app.form.custom_agreement.pending': 'Inasubiri',
	'app.form.custom_agreement.accepted': 'Imekubaliwa',
	'app.form.custom_agreement.rejected': 'Imekataliwa',
	'app.form.actions.tooltip.send_custom_agreement': 'Tuma makubaliano maalum',
	'app.form.actions.tooltip.resend_custom_agreement': 'Tuma tena makubaliano maalum',
	'app.form.actions.tooltip.show_report': 'Onyesha ripoti',
	'app.listforms.ai_interactions': 'Ushirikiano wa AI'
}
