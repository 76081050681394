import { AppMessages } from '../types';

export const appSW: AppMessages = {
	'app.unknown_error': 'Hitilafu isiyojulikana imetokea, tafadhali wasiliana na waliohusika.',
	'app.btn_start': 'Anza',
	'app.btn_restart': 'Anzisha upya',
	'app.btn_cancel': 'Ghairi',
	'app.btn_next': 'Inayofuata',
	'app.btn_ok': 'Sawa',
	'app.btn_confirm' : 'Thibitisha',
	'app.btn_finish': 'Maliza',
	'app.btn_back': 'Nyuma',
	'app.dropdown_select': 'Chagua...',
	'app.loading': 'Inapakia',
	'app.response_yes': 'Ndiyo',
	'app.response_no': 'Hapana',
	'app.bevi_copyright_text_1': 'Orodha ya Imani, Matukio, na Maadili au BEVI ni orodha iliyo na hakimiliki. Ripoti za BEVI na BEVI, vipengee, maudhui ya bidhaa, au mizani haziwezi kurekebishwa, kunakiliwa, kusambazwa, au kuchapishwa, nzima au sehemu, bila idhini ya maandishi na ya wazi ya Craig N. Shealy, Ph.D.',
	'app.bevi_copyright_text_2': '',
	'app.empty.data': 'Hakuna data',
	'app.systemerro': 'Oh hapana! Mfumo unaonekana kutokuwa dhabiti,jaribu tena baada ya dakika chache.',
	'app.loading_document': 'Inapakia hati...',
	'app.download_document': 'Pakua PDF',
	'app.download_excel': 'Pakua XLSX',
	'app.btn_search': 'Tafuta',
	'app.english': 'Kingereza',
	'app.catalan': 'Kikatalani',
	'app.spanish': 'Kihispania',
	'app.japanese': 'Kijapani',
	'app.chinese-t': 'Kichina cha jadi',
	'app.chinese-s': 'Kichina kilichorahisishwa',
	'app.true': 'Kweli',
	'app.false': 'Uongo',
	'app.save': 'Hifadhi',
	'app.btn_reset': 'Anzisha upya',
	'app.btn_submit': 'Wasilisha',
	'app.search_placeholder': 'Andika andiko unalotafuta',
	'app.notification.error_title': 'Hitilafu',
	'app.error.user_not_found': 'Mtumiaji hajapatikana',
	'app.save_with_success': 'Hifadhi kwa Mafanikio',
	'app.swahili': 'Kiswahili',
    'app.german': 'Kijerumani',
	'app.hindi': 'Hindi'
}
