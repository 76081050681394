import { ProgramsMessages } from '../types';

export const programsSW: ProgramsMessages = {
	'app.programs.add.title': 'Programu Mpya',
	'app.programs.rename': 'Badilisha jina la programu',
	'app.programs.edit.title': 'Hariri Programu ',
	'app.programs.add.successful_message': 'Programu imesasishwa kwa ufanisi.',
	'app.programs.add.error_message': 'Hitilafu imetokea, tafadhali, jaribu tena baada ya dakika chache.',
	'app.programs.add.new_name': 'Jina jipya',
	'app.programs.add.button_save': 'Hifadhi',
	'app.programs.chat.successful_message': 'Baada ya dakika chache, watumiaji wote wataweza kutumia gumzo la AI.',
	'app.programs.chat.add_chat_all': 'Ongeza Gumzo kwa Watumiaji wote',
	'app.programs.chat.question_add_chat_all': 'Je, una hakika unataka kujumuisha gumzo kwa watumiaji wote katika programu hii?'
}