import { UsersMessages } from '../types';

export const usersCA: UsersMessages = {
    'app.users.listuser.title': 'Gestionar Usuaris',
    'app.users.name': 'Nom d’usuari',
    'app.users.login': 'Correu electrònic',
    'app.users.status.inactive': 'Inactiu',
    'app.users.status.active': 'Actiu',
    'app.users.status': 'Estat',
    'app.create_batch_user.excel.email_column': 'Correu electrònic',
    'app.create_batch_user.excel.line_number_column': 'Número de línia',
    'app.create_batch_user.excel.user_error_column': 'Error',
    'app.create_batch_user.excel.download_error_details': 'Descarregar detalls',
    'app.create_batch_user.error_already_exists': 'Usuari ja existeix',
    'app.create_batch_user.error_invalid_line': 'Línia invàlida',
    'app.create_batch_user.error_wrong_email': 'Usuari amb correu electrònic incorrecte',
    'app.create_batch_user.error_missing_name': 'Falta el nom d’usuari',
    'app.create_batch_user.error_details_filename': 'Informació de l’error',
    'app.user.organization': 'Organització',
    'app.user.select_organization': 'Selecciona una organització.',
    'app.user.select_program': 'Selecciona un programa o experiència',
    'app.user.program': 'Programa',
    'app.users.listuser.action': 'Acció',
    'app.users.listuser.reset_password': 'Restablir contrasenya',
    'app.users.listuser.details': 'Gestió d’usuaris',
    'app.users.listuser.programs_action': 'Programes i experiències',
    'app.users.add_new_user': 'Nou Usuari',
    'app.user_programs.programName': 'Nom de programa o experiència',
    'app.user_programs.subprogramName': 'Nom d’administració BEVI',
    'app.user_programs.status': 'Estat',
    'app.user_programs.completedOn': 'Completat el',
    'app.user_programs.invalid_reason': 'Raó no vàlida',
    'app.listorganizations.input_username_placeholder': 'Escriu un nom d’usuari',
    'app.listorganizations.input_useremail_placeholder': 'Escriu un correu electrònic d’usuari',
    'app.create.batch_user_error': 'S\'ha trobat un o més errors a l’hora de crear usuaris múltiples per l’organització. Per a més detalls, descarrega l’arxiu d’informació d’errors.',
    'app.create.user.title': 'Crea un nou usuari',
    'app.create.user.single.user': 'Proporciona un únic correu electrònic  d’alguna persona autoritzada per completar i/o administrar el BEVI.',
    'app.create.user.send_welcome_email': 'Enviar un correu electrònic de benvinguda',
    'app.create.user.multiple.users': 'Proporciona correus electrònics d’individus perquè tinguin autorització per a realitzar el BEVI.',
    'app.create.user.choose.option.new.user': 'Selecciona l’opció correcta entre les dues que apareixen a continuació.',
    'app.create.user.language_codes': 'Codis d’idiomes',
    'app.create.user.download.template.file': 'Descarrega l’arxiu de plantilles.',
    'app.create.user.download.data.example': 'Descarrega l’arxiu de mostres',
    'app.create.user.upload.file': 'Penjar',
    'app.create.user.drag.n.drop.text': 'Arrossega i deixa anar els arxius aquí, o fes clic per seleccionar arxius',
    'app.create.user.dran.n.drop.available.files': '(Only *.xlsx and *.xls files will be accepted)',
    'app.create.user.remove': 'Eliminar',
    'app.create.user.selected.file': 'Seleccionats: ',
    'app.create.user.create.multiple.user.title': 'Proporciona correus electrònics d’individus perquè tinguin autorització per a completar el BEVI.',
    'app.create.user.create.single.user.title': 'Crear un Usuari',
    'app.create.user.table.column_actions': 'Eliminar Organització',
    'app.create.user.edit.single.user.title': 'Gestió d’usuari',
    'app.create.user.is.anonymous': 'És Anònim',
    'app.create.user.user.type': 'Tipus d’Usuari',
    'app.create.user.language': 'Idioma de correu electrònic',
    "app.create.user.capability_visualize_user": "VISUALITZAR - USUARI",
    "app.create.user.capability_add_user": "AFEGIR - USUARI",
    "app.create.user.capability_reset_password_user": "REINICIAR CONTRASENYA - USUARI",
    "app.create.user.capability_manage_user": "GESTIONAR - USUARI",
    "app.create.user.capability_visualize_user_programs": "VISUALITZAR - PROGRAMES DE L'USUARI",
    "app.create.user.capability_visualize_program_and_experience": "VISUALITZAR - PROGRAMA I EXPERIÈNCIA",
    "app.create.user.capability_add_program_and_experience": "AFEGIR - PROGRAMA I EXPERIÈNCIA",
    "app.create.user.capability_rename_program_and_experience": "REANOMENAR - PROGRAMA I EXPERIÈNCIA",
    "app.create.user.capability_add_subprogram": "AFEGIR - SUBPROGRAMA",
    "app.create.user.capability_edit_subprogram": "EDITAR - SUBPROGRAMA",
    "app.create.user.capability_manage_subprogram": "GESTIONAR - SUBPROGRAMA",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "MOSTRAR CODI QR PER INVITAR NOUS USUARIS - SUBPROGRAMA",
    "app.create.user.capability_visualize_report": "VISUALITZAR - INFORME",
    "app.create.user.capability_add_report": "AFEGIR - INFORME",
    'app.user.profile.participant': 'Participant',
    'app.user.profile.programAdmin': 'Administrador de programa',
    'app.user.profile.institutional': 'Administrador institucional',
    'app.user.profile.accountManager': 'Administrador del compte',
    'app.user.profile.superAdmin': 'Súper admin',
    'app.user.language.message': '(L’idioma en el qual l’usuari rebrà correus electrònics)',
    'app.user.successfulMessage': 'Usuari creat amb èxit',
    "app.user.updateSuccessfulMessage": "L'usuari s'ha actualitzat amb èxit",
    'app.edit.user.confirmation_title_fail': 'Llàstima! Desafortunadament, no l’hem pogut actualitzar.',
    'app.edit.user.confirmation_body_fail': 'No s’ha pogut actualitzar l’usuari. Si us plau, revisa les dades proporcionades, i la connexió d’internet i torna a provar. Si el problema persisteix, parla amb l’administrador del compte.',
    'app.edit.user.confirmation_body_fail_already_exists': 'No s’ha pogut actualitzar l’usuari perquè un altre usuari ja té el correu electrònic proporcionat.',
    'app.edit.user.confirmation_body_fail_wrong_email': 'No s’ha pogut actualitzar l’usuari perquè el correu electrònic proporcionat no és vàlid.',
    'app.edit.user.confirmation_title_success': 'Actualitzat amb èxit',
    'app.edit.user.confirmation_body_success': 'S’ha pogut actualitzar l’usuari i ara pots veure’l en la llista d’usuaris, a la que ara t’estem redirigint.',
    'app.create.user.confirmation_title_fail': 'Llàstima! Desafortunadament, no l’hem pogut crear.',
    'app.create.user.confirmation_body_fail': 'No s’ha pogut crear l’usuari. Si us plau, revisa les dades proporcionades, i la connexió d’internet i torna a provar. Si el problema persisteix, parla amb l’administrador del compte.',
    'app.create.user.confirmation_title_success': 'Actualitzat amb èxit',
    'app.create.user.confirmation_body_success': 'Usuari creat amb èxit.',
    'app.create.user.confirmation_body_fail_already_exists': 'No s’ha pogut crear l’usuari perquè un altre usuari ja té el correu electrònic proporcionat.',
    'app.create.user.confirmation_body_fail_wrong_email': 'No s’ha pogut crear l’usuari perquè el correu electrònic proporcionat no és vàlid.',
    'app.reset_password.title': 'RESTABLIR CONTRASENYA',
    'app.reset_password.body': 'Si fes clic a Restablir Contrasenya, restabliràs temporalment la contrasenya per a <b>{username}</b>.<br/><br/> Un cop hagi ingressat en el sistema, podrà canviar la contrasenya.',
    'app.reset_password.successful_message': '<b>{username}</b> creat amb èxit.',
    'app.reset_password.error_message': 'Alguna cosa ha fallat. Si us plau, torna a provar després d’uns minuts.',
    'app.reset_password.new_temporary_password': 'Escriu la contrasenya temporal a continuació',
    'app.reset_password.new_password': 'Nova contrasenya',
    'app.reset_password.button_reset': 'Restablir contrasenya',
    "app.users_type.select": "Selecciona un tipus d'usuari",
    "app.users_type.all": "Tots",
    "app.users_type.participant": "Participant",
    "app.users_type.program_admin": "Administrador del Programa",
    "app.users_type.institutional": "Institucional",
    "app.users_type.account_manager": "Gestor de Comptes",
    "app.users_type.super_admin": "Super Administrador",
    "app.users_type.external_partner": "Soci Extern",
    "app.users.listuser.clear_2fa": "Esborrar l'Autenticació de Doble Factor",
    "app.users.confirm_deletion_body": "Esteu segur que voleu aplicar el procés d'eliminació de l'2FA? Si ho feu, es demanarà a l'usuari que torni a introduir el codi a Google Authenticator."
}
