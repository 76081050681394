import { ReportsMessages } from '../types';

export const reportsSW: ReportsMessages = {
    'app.reports.insuficient_data': 'Hakuna data ya kutosha kutengeneza ripoti.',
 'app.reports.decile': 'Gawa kwa kumi vilivyo sawa (Decile)',
 'app.reports.group_report.title': 'Ripoti ya Kikundi',
 'app.reports.longitudinal.title': 'Ripoti ya Longitudinal',
 'app.reports.comparative_by_group.title': 'Ripoti Kati Kikundi',
 'app.reports.comparative_by_individual.title': 'Ripoti ya Ndani ya Kikundi',
 'app.report.menu.introduction': 'Taarifaya Ripoti',
 'app.report.menu.basic_descriptive_statistics': 'Kikoa cha Usuli',
 'app.report.menu.aggregate_profile': 'Jumla ya Wasifu',
 'app.report.menu.decile_profile': 'Wasifu wa Decile',
 'app.report.menu.aggregate_by_demographics': 'Panga kulingana na demografia',
 'app.report.menu.background_domain_contrast': 'Utofautishaji wa Kikoa cha Usuli',
 'app.report.menu.profile_background_contrast': 'Utofautishaji wa Kikoa cha Usuli',
 'app.report.menu.profile_contrast': 'Utofautishaji wa Wasifu',
 'app.report.menu.experiential_reflection_items': 'Vipengee vya Kuakisi kwa Uzoefu',
 'app.report.menu.country_of_origin': 'Nchi ya Asili',
 'app.report.menu.gender': 'Jinsia',
 'app.report.menu.education': 'Elimu',
 'app.report.menu.ethnicity': 'Kabila',
 'app.report.menu.income': 'Mapato',
 'app.report.menu.interest': 'Mapendeleo',
 'app.report.menu.politics': 'Siasa',
 'app.report.menu.religion': 'Dini',
 'app.report.menu.satisfaction': 'Kuridhika',
 'app.report.menu.question_1': 'Swali la 1',
 'app.report.menu.question_2': 'Swali la 2',
 'app.report.menu.question_3': 'Swali la 3',
 'app.report.menu.narrative_report': 'Ripoti ya Simulizi',
 'app.report.introduction.create_date': 'Iliundwa tarehe:',
 'app.report.introduction.created_by': 'Mtumiaji:',
 'app.report.introduction.program_name': 'Programu au uzoefu:',
 'app.report.introduction.group_name': 'Usimamizi/Usimamizi wa BEVI:',
 'app.report.introduction.number_of_participants': 'Idadi ya washiriki:',
 'app.report.introduction.visualized_by': 'Imetazamwa na:',
 'app.report.scales.consistency': 'Uthabiti',
 'app.report.scales.congruency': 'Ulinganifu',
 'app.report.scales.negative_life_events': 'Matukio Mabaya ya Maisha',
 'app.report.scales.needs_closure': 'Inahitaji Kufungwa',
 'app.report.scales.needs_fulfillment': 'Inahitaji Utimilifu',
 'app.report.scales.identity_diffusion': 'Usambazaji wa Utambulisho',
 'app.report.scales.basic_openness': 'Uwazi wa Msingi',
 'app.report.scales.self_certitude': 'Hadhi ya Kujitegemea',
 'app.report.scales.basic_determinism': 'Nia ya Kimsingi',
 'app.report.scales.socioemotional_convergence': 'Muunganisho wa Kijamii',
 'app.report.scales.physical_resonance': 'Msisimko wa Kimwili',
 'app.report.scales.emotional_attunement': 'Mwiano wa Kihisia',
 'app.report.scales.self_awareness': 'Kujitambua',
 'app.report.scales.meaning_quest': 'Maana ya Jaribio',
    'app.report.scales.religious_traditionalism': 'Utamaduni wa Kidini',
 'app.report.scales.gender_traditionalism': 'Utamaduni wa Kijinsia',
 'app.report.scales.sociocultural_openness': 'Uwazi wa kitamaduni',
 'app.report.scales.ecological_resonance': 'Msisimko wa ikolojia',
 'app.report.scales.global_resonance': 'Misisimko wa Kimataifa',
 'app.report.aggregate_profile.high': 'Juu',
 'app.report.aggregate_profile.low': 'Chini',
 'app.report.aggregate_profile.average': 'Wastani',
 'app.report.background_statistic_item.income_participants': '{0} kati ya {1} washiriki - {2} %',
 'app.report.background_statistic_item.background_information': 'Maelezo ya Usuli',
 'app.report.background_statistic_item.lowest': 'Chini zaidi',
 'app.report.background_statistic_item.middle': 'Katikati',
 'app.report.background_statistic_item.highest': 'Juu zaidi',
 'app.report.background_statistic_item.age': 'Umri',
 'app.report.background_statistic_item.average_age': 'Wastani wa Umri:',
 'app.report.background_statistic_item.answer': 'Jibu:',
 'app.report.background_statistic_item.not_answer': 'Si jibu:',
 'app.report.background_statistic_item.range': 'Kipimo:',
 'app.report.background_statistic_item.gender': 'Jinsia',
 'app.report.background_statistic_item.ethnic_background': 'Asili ya Kikabila',
 'app.report.background_statistic_item.country_of_origin': 'Nchi ya Asili',
 'app.report.background_statistic_item.raised_in': 'Kulelewa',
 'app.report.background_statistic_item.not_raised_in': 'Kutolelewa',
 'app.report.background_statistic_item.details': 'Maelezo',
 'app.report.background_statistic_item.years_of_education': 'Miaka ya elimu',
 'app.report.background_statistic_item.income': 'Mapato',
 'app.report.background_statistic_item.average': 'Wastani:',
 'app.report.background_statistic_item.marital_status': 'Hali ya Uhusiano',
 'app.report.background_statistic_item.religion': 'Dini',
 'app.report.background_statistic_item.interest': 'Mapendeleo',
 'app.report.background_statistic_item.satisfaction': 'Kuridhika',
 'app.report.background_statistic_item.domain_scores': 'Alama za Kikoa',
 'app.report.background_statistic_item.validity': 'Uhalali',
 'app.report.background_statistic_item.average_missing_items': 'Wastani wa Vipengee Vilivyokosekana',
 'app.report.background_statistic_item.average_quantitative_completion_time': 'Wastani wa Muda wa Kukamilisha',
 'app.report.background_statistic_item.full_scale': 'Kipimo Kamili',
 'app.report.background_statistic_item.formative_variables': 'I. Vigezo vya Uundaji',
 'app.report.background_statistic_item.fulfillment_of_core_needs': 'II. Utimilifu wa Mahitaji ya Msingi',
 'app.report.background_statistic_item.tolerance_of_disequilibrium': 'III. Uvumilivu wa kutokuwepo usawa',
 'app.report.background_statistic_item.critical_thinking': 'IV. Kufikiri kwa Kina',
 'app.report.background_statistic_item.self_access': 'V. Kujifikia',
 'app.report.background_statistic_item.other_access': 'VI. Ufikiaji Mwingine',
 'app.report.background_statistic_item.global_access': 'VII. Ufikiaji wa Kimataifa',
 'app.report.decile_profile.high': 'Juu',
 'app.report.decile_profile.low': 'Chini',
 'app.report.decile_profile.average': 'Wastani',
 'app.report.background_domain_contrast.title': 'Utofautishaji wa Kikoa cha Usuli',
 'app.report.background_domain_contrast.lowest_full_scale': 'Kiwango Kamili cha Chini Zaidi',
 'app.report.background_domain_contrast.middle_full_scale': 'Kiwango Kamili cha Kati',
 'app.report.background_domain_contrast.highest_full_scale': 'Kiwango Kamili cha Juu Zaidi',
 'app.report.background_domain_contrast.mean': 'Wastani',
    'app.report.background_domain_contrast.range': 'Kipimo',
 'app.report.background_domain_contrast.background_information': 'Maelezo ya Usuli',
 'app.report.profile_contrast.lowest': 'Chini zaidi',
 'app.report.profile_contrast.middle': 'Katikati',
 'app.report.profile_contrast.highest': 'Juu zaidi',
 'app.report.profile_contrast.lowest_optimal_profiles': 'Wasifu Bora wa Chini Zaidi',
 'app.report.profile_contrast.middle_optimal_profiles': 'Wasifu Bora wa Kati',
 'app.report.profile_contrast.highest_optimal_profiles': 'Wasifu Bora wa Juu Zaidi',
 'app.report.aggregate_profile.country_of_origin.selected_country': 'Nchi Iliyochaguliwa',
 'app.report.aggregate_profile.country_of_origin.selected_countries': 'Nchi Zilizochaguliwa',
 'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Kabila Lililochaguliwa',
 'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Makabila Yaliyochaguliwa',
 'app.report.aggregate_profile.country_of_origin.other': 'Nyingine',
 'app.report.aggregate_profile.religious_orientation.other': 'Nyingine',
 'app.report.aggregate_profile.parents_country.same': 'sawa',
 'app.report.aggregate_profile.parents_country.different': 'Tofauti',
 'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Hali Iliyochaguliwa ya Uhusiano',
 'app.report.aggregate_profile.religion.selected_religion': 'Dini Iliyochaguliwa',
 'app.report.aggregate_profile.relationship_status.other': 'Nyingine',
 'app.report.aggregate_profile.gender.male': 'Kiume',
 'app.report.aggregate_profile.gender.female': 'Kike',
 'app.report.aggregate_profile.gender.self_identify': 'Unavyo Jitambulisha',
 'app.report.aggregate_profile.education.lowest_30': 'Chini Zaidi 30%',
 'app.report.aggregate_profile.education.highest_30': 'Juu Zaidi 30%',
 'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'kabila lililochaguliwa',
 'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Makabila yaliyochaguliwa',
 'app.report.aggregate_profile.ethnicity.other': 'Nyingine',
 'app.report.aggregate_profile.income.bottom_third': 'Thuluthi ya chini',
 'app.report.aggregate_profile.income.middle_third': 'Thuluthi ya kati',
 'app.report.aggregate_profile.income.top_third': 'Thuluthi ya juu',
 'app.report.aggregate_profile.interest.low': 'Chini',
 'app.report.aggregate_profile.interest.middle': 'Katikati',
 'app.report.aggregate_profile.interest.high': 'Juu',
 'app.report.aggregate_profile.politics.low': 'Wahifadhi Zaidi/Wajadi',
 'app.report.aggregate_profile.politics.middle': 'Katikati /Wasio Upande Wowote',
 'app.report.aggregate_profile.politics.high': 'Wasiohifadhi/Wakisasa Zaidi',
 'app.report.aggregate_profile.age.low': 'Chini',
 'app.report.aggregate_profile.age.middle': 'Katikati',
 'app.report.aggregate_profile.age.high': 'Juu',
 'app.report.aggregate_profile.religion.low': 'Chini',
 'app.report.aggregate_profile.religion.middle': 'Katikati',
 'app.report.aggregate_profile.religion.high': 'Juu',
 'app.report.aggregate_profile.satisfaction.low': 'Chini',
 'app.report.aggregate_profile.satisfaction.middle': 'Katikati',
 'app.report.aggregate_profile.satisfaction.high': 'Juu',
 'app.report.aggregate_profile.mother_father_education.low': 'Chini',
 'app.report.aggregate_profile.mother_father_education.middle': 'Katikati',
 'app.report.aggregate_profile.mother_father_education.high': 'Juu',
 'app.report.aggregate_profile.time_spent_other_countries.low': 'Chini',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Katikati',
 'app.report.aggregate_profile.time_spent_other_countries.high': 'Juu',
 'app.report.aggregate_profile.minority_group.minority': 'Wachache',
 'app.report.aggregate_profile.minority_group.majority': 'Wengi',
 'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'Eneo la Msingi Lililochaguliwa',
 'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'Sehemu za Msingi Zilizochaguliwa',
 'app.report.aggregate_profile.primary_area_of_work.other': 'Nyingine',
 'app.report.aggregate_profile.years_of_language_study.low': 'Chini',
 'app.report.aggregate_profile.years_of_language_study.middle': 'Katikati',
 'app.report.aggregate_profile.years_of_language_study.high': 'Juu',
 'app.report.aggregate_profile.academic_rank.low': 'Chini',
 'app.report.aggregate_profile.academic_rank.middle': 'Katikati',
 'app.report.aggregate_profile.academic_rank.high': 'Juu',
 'app.report.aggregate_profile.visited_countries.low': 'Chini',
 'app.report.aggregate_profile.visited_countries.middle': 'Katikati',
 'app.report.aggregate_profile.visited_countries.high': 'Juu',
 'app.report.aggregate_profile.fluency.low': 'La au Kima cha Chini',
 'app.report.aggregate_profile.fluency.middle': 'Kiasi au Hivi hivi',
 'app.report.aggregate_profile.fluency.high': 'Wenye Fasaha Kiasi au Fasaha',
 'app.report.aggregate_demographic.core_demographics': 'Demografia ya Undani',
 'app.report.aggregate_demographic.parental_background': 'Asili ya Wazazi',
 'app.report.aggregate_demographic.political_religious_inclinations': 'Mielekeo ya Kisiasa na Kidini',
 'app.report.aggregate_demographic.education_background': 'Asili ya Elimu',
 'app.report.aggregate_demographic.international_cultural_experiences': 'Uzoefu wa Kimataifa na Kitamaduni',
 'app.report.aggregate_demographic.age': 'Umri',
 'app.report.aggregate_demographic.gender': 'Jinsia',
 'app.report.aggregate_demographic.relationship_status': 'Hali ya Uhusiano',
 'app.report.aggregate_demographic.country_origin': 'Nchi ya Asili',
 'app.report.aggregate_demographic.parent_country': 'Nchi ya Mzazi',
 'app.report.aggregate_demographic.mother_education': 'Elimu ya Mama',
 'app.report.aggregate_demographic.father_education': 'Elimu ya Baba',
 'app.report.aggregate_demographic.ethnic_background': 'Asili ya Kikabila',
 'app.report.aggregate_demographic.minority_group': 'Kikundi cha Wachache',
 'app.report.aggregate_demographic.political_orientation': 'Mwelekeo wa Kisiasa',
 'app.report.aggregate_demographic.religious_orientation': 'Mwelekeo wa Kidini',
 'app.report.aggregate_demographic.religious_commitment': 'Ahadi ya Kidini',
 'app.report.aggregate_demographic.income_level': 'Kiwango cha Mapato',
 'app.report.aggregate_demographic.years_education': 'Miaka ya Elimu',
 'app.report.aggregate_demographic.primary_area_work': 'Eneo la msingi au kazi',
    'app.report.aggregate_demographic.academic_rank': 'Cheo cha Kitaaluma',
 'app.report.aggregate_demographic.time_spent_countries': 'Muda unaliotumika katika nchi zingine',
 'app.report.aggregate_demographic.visited_countries': 'Nchi Zilizotembelewa',
 'app.report.aggregate_demographic.personal_interest': 'Maslahi ya kibinafsi',
 'app.report.aggregate_demographic.study_abroad_satisfaction': 'Kuridhika kwa kusoma nje ya nchi',
 'app.report.aggregate_demographic.fluency': 'Ufasaha',
 'app.report.aggregate_demographic.years_language_study': 'Miaka ya Masomo ya Lugha',
 'app.report.comparative_individual_report_result_title': 'Matokeo ya Ripoti BEVI ya Mabadiliko ya Mtu Binafsi',
 'app.report.comparative_individual_report_result_description': 'Kutoka usimamizi mmoja hadi mwingine, unasonga kati ya "bendi" tofauti za mabadiliko kwenye BEVI (yaani, unaweza kuwa unaonyesha mabadiliko makubwa kutoka usimamizi mmoja hadi mwingine). "Kuwa Bevi" kunaweza kukuambia zaidi kuhusu mabadiliko ambayo yanatokea kwako kati ya tawala tofauti za BEVI (yaani, Kuwa Bevi ni taasisi ya AI inayoishi ndani ya Beviverse -- bofya kwenye mduara katika kona ya chini kulia). Ikiwa kuna maandishi ya rangi hapa chini, hiyo inamaanisha kuwa alama zako zimebadilika sana kutoka usimamizi mmoja wa BEVI hadi mwingine. Maandishi ya rangi hurejelea mwelekeo ambapo mabadiliko haya yametokea katika usimamizi tofauti wa BEVI (k.m., kwa kawaida hiyo itakuwa ni kuanzia mara ya kwanza ulipochukua BEVI hadi mara ya pili, lakini hiyo inategemea ni mara ngapi utatumia. wamechukua BEVI na ulinganisho gani unafanya). Iwapo unataka kwenda kwa kina zaidi, ili kuona mabadiliko mahususi kwako kwenye mizani 17 ya BEVI, tafadhali wasiliana na Msimamizi wako wa BEVI au wasiliana na Timu ya BEVI kwa',
 'app.report.comparative_individual_report_result_description_no_change': 'Kutoka utawala mmoja hadi mwingine, unakaa ndani ya "bendi" sawa ya mabadiliko. "Kuwa Bevi" kunaweza kukuambia zaidi kuhusu mabadiliko ambayo yanatokea kwako kati ya usimamizi mmoja wa BEVI hadi mwingine (yaani, Kuwa Bevi ni taasisi ya AI inayoishi ndani ya Beviverse -- bofya kwenye mduara katika kona ya chini ya mkono wa kulia. ) Ukitaka kwenda kwa kina zaidi, ili kuona mabadiliko mahususi kwako kwenye mizani 17 ya BEVI, tafadhali wasiliana na Msimamizi wako wa BEVI au wasiliana na Timu ya BEVI kwa',
 'app.report.partner_report_result_title': 'Matokeo ya Ripoti ya Washirika wa BEVI',
 'app.report.partner_change_report_result_title': 'Matokeo ya Ripoti ya Mabadiliko ya Washirika wa BEVI',
 'app.report.permission.modal_title': 'Mpendwa Mshiriki wa BEVI',
    'app.report.permission.modal_description': `
 <h5>Tayari umetoa idhini ya kuchukua BEVI, lakini kabla hatujafanya kazi nawe kuhusu ripoti yako ya BEVI, tunakuomba ukubali:</h5>
 <br />
 <p>
 1) kwamba unataka kushiriki katika kipindi kimoja au zaidi ambapo ripoti yako binafsi itajadiliwa nawe na
 </ p>
 <p>
 2) kwamba unaidhinisha <b>[#USERS#]</b> kukagua ripoti yako binafsi (k.m., simulizi lako, alama) na kujadili maana au athari za ripoti yako ya BEVI nawe.
 Bila shaka, kama ilivyoonyeshwa katika fomu ya idhini ya BEVI, matokeo yako binafsi yatasalia kuwa siri kabisa. Ikiwa una maswali yoyote, au unahitaji maelezo zaidi, tafadhali wasiliana na msimamizi wa BEVI ambaye anafanya kazi nawe.
 </ p>
 <p>
 Ikiwa unaelewa na kukubaliana na masharti mawili hapo juu, tafadhali bofya kitufe cha "Nakubali" hapa chini.
 </ p>
 <center>Asante kwa shauku yako katika BEVI!</center>
 `,
 'app.report.permission.pending': 'Inasubiri',
 'app.report.permission.approved': 'Imeidhinishwa',
 'app.report.permission.rejected': 'Imekataliwa',
 'app.report.permission.approve': 'NAKUBALI',
 'app.report.permission.reject': 'SIKUBALI',
}
