// Pages before logging in.
export const CHANGE_PASSWORD_PAGE: string = '/change-password/:token';
export const FORGOT_PASSWORD_PAGE: string = '/forgot-password';
export const LOGIN_PAGE: string = '/';

// Invitation.
export const INVITATION_PAGE: string = '/invitation/:token';

// Pages after loggin in.
export const DASHBOARD_PAGE: string = '/my_bevi';
export const FIRST_ACCESS_PAGE: string = '/first_access';
export const ORGANIZATION_PAGE: string = '/list/organization';
export const ORGANIZATION_DEMOGRAPHICS_EXTRA_QUESTIONS_PAGE: string = '/organization/demographics-extra-questions';
export const PROGRAMS_PAGE: string = '/list/programs';
export const LIST_USERS_PAGE: string = '/list/users';
export const HIDE_DEMOGRAPHICS_QUESTIONS: string = '/organization/hide-demographics-questions';
export const LOADING_MY_BEVI: string = '/loading_my_bevi/:token';
export const REPORT_PERMISSION: string = '/report_permission';

// BEVI questionnaire pages.
export const CONSENT_PAGE: string = '/bevi/consent';
export const DEMOGRAPHIC_QUESTIONS_PAGE: string = '/bevi/questions/demographics';
export const SCALE_QUESTIONS_PAGE: string = '/bevi/questions/scale';
export const DISCURSIVE_QUESTIONS_PAGE: string = '/bevi/questions/discursive';

// BEVI extern questionnaire pages.
export const EXTERNAL_BEVI_ACCESS: string = '/external-bevi/:externalToken';
export const EXTERNAL_CONSENT_PAGE: string = '/external-bevi-form/consent';
export const EXTERNAL_DEMOGRAPHIC_QUESTIONS_PAGE: string = '/external-bevi-form/demographics';
export const EXTERNAL_SCALE_QUESTIONS_PAGE: string = '/external-bevi-form/scale';
export const EXTERNAL_DISCURSIVE_QUESTIONS_PAGE: string = '/external-bevi-form/discursive';
export const EXTERNAL_INDIVIDUAL_REPORT_PAGE: string = '/external-bevi-form/report/individual';
export const EXTERNAL_THANKS_FOR_FINISHING_FORM_PAGE: string = '/external-bevi-form/thanks-for';

// Report pages.
export const REPORT_PAGE: string = '/report/list';
export const INDIVIDUAL_REPORT_PAGE: string = '/report/individual';
export const CREATE_REPORT_PAGE: string = '/report/create';
export const EDIT_REPORT_PAGE: string = '/report/edit';
export const GROUP_REPORT_PAGE: string = '/report/group-report/:id';
export const LONGITUDINAL_REPORT_PAGE: string = '/report/longitudinal-report/:id';
export const COMPARATIVE_BY_GROUP_REPORT_PAGE: string = '/report/comparative-by-group-report/:id';
export const COMPARATIVE_BY_INDIVIDUAL_REPORT_PAGE: string = '/report/comparative-by-individual-report/:id';
export const FINANCIAL_REPORT_PAGE: string = '/report/financial';
export const COMPARATIVE_INDIVIDUAL_PAGE: string = '/report/comparative-individual';
export const COMPARATIVE_INDIVIDUAL_REPORT_PAGE: string = '/report/individual-report/:id';
export const LONGITUDINAL_COMPARATIVE_INDIVIDUAL_REPORT_PAGE: string = '/report/longitudinal-comparative-individual-report/:id';
export const COMBINED_INDIVIDUAL_REPORT_PAGE: string = '/report/combined-individual-report/:id';
export const SAMPLE_DATA_REPORT_PAGE: string = '/report/sample-data'

// CRUD program/subprogram pages.
export const CREATE_PROGRAM: string = '/program/create';
export const CREATE_SUBPROGRAM: string = '/subprogram/create';
export const EDIT_SUBPROGRAM: string = '/subprogram/edit';
export const SUBPROGRAM_DETAILS: string = '/subprogram/details';

// CRUD user pages.
export const CREATE_USER_PAGE: string = '/user/create';
export const EDIT_USER_PAGE: string = '/user/edit';
export const USER_PROGRAMS_PAGE: string = '/user/programs';

// No info pages.
export const NOT_AUTHORIZED: string = '/noauthorized';
export const MAINTENANCE: string = '/maintenance';
export const NOT_AVAILABLE_FORM: string = '/not-available-form';
export const NOT_FOUND: string = '/*';

// Pages to be REMOVED.
export const COUNTER_PAGE: string = '/counter';

// User Profile Permission
export const USER_PROFILE_PERMISSION: string = '/user-profile-permission';

// Virtual Account
export const VIRTUAL_ACCOUNT: string = '/virtual-account';

// Two Factor Authenticator
export const TWO_FACTOR_AUTHENTICATOR: string = '/two_factor_authenticator';
