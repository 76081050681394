import { FinancialReportMessages } from '../types';

export const financialReportHI: FinancialReportMessages = {
    'app.financialreport.title': 'उपयोग रिपोर्ट',
    'app.financialreport.organization_lbl': 'संगठन',
    'app.financialreport.start_data_lbl': 'आरंभ तिथि',
    'app.financialreport.end_data_lbl': 'अंतिम तिथि',
    'app.financialreport.created_forms_title': 'BEVI लेने के लिए आमंत्रित लोगों की संख्या',
    'app.financialreport.finished_forms_title': 'पूरा किए गए BEVI की संख्या',
    'app.financialreport.valid_forms_title': 'वैध BEVI की संख्या',
    'app.financialreport.invalid_forms_title': 'अवैध BEVI की संख्या',
    'app.financialreport.unanswered_forms_title': 'अउत्तरित BEVI की संख्या',
    'app.financialreport.error_header': 'त्रुटि',
    'app.financialreport.error_text': 'एक त्रुटि हुई और रिपोर्ट डेटा लोड नहीं किया जा सका, कृपया पुनः प्रयास करें।',
};
