import { InternalTemplateMessages } from '../types';

export const internalTemplatePageSW: InternalTemplateMessages = {
	'app.internaltemplate.welcome': 'Karibu',
	'app.internaltemplate.menu.dashboard': 'Dashibodi',
	'app.internaltemplate.menu.users': 'Watumiaji',
	'app.internaltemplate.menu.organization': 'Programu na Uzoefu',
	'app.internaltemplate.menu.institutions': 'Mashirika',
	'app.internaltemplate.menu.report': 'Ripoti',
	'app.internaltemplate.menu.sample_report': 'Sampuli za Ripoti',
	'app.internaltemplate.menu.permission_profile': 'Wasifu wa Ruhusa',
	'app.internaltemplate.menu.financial_report': 'Ripoti ya Matumizi',
	'app.internaltemplate.menu.logout': 'Toka',
	'app.internaltemplate.menu.virtualAccount': 'Akaunti ya kidigitali',
	'app.internaltemplate.system.maintenance_time': 'Mfumo utakuwa chini ya marekebisho kuanzia {0} hadi {1}.',
	'app.internaltemplate.menu.report_permission': 'Ruhusa ya Ripoti',
	'app.internaltemplate.menu.configureTwoFactorAuthentication': 'Sanidi Uthibitishaji wa Hatua Mbili'
}
