import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'Maswali yasiyo na majibu',
	'app.questions.confirmation': 'Je, kweli unataka kuendelea bila kujibu maswali yote? Hutaweza kurejea maswali haya baadaye.',
}

const scaleQuestions: ScaleQuestionsMessages = {
	'app.question.strongly_agree': 'Nakubali kabisa',
	'app.question.agree': 'Kubali',
	'app.question.disagree': 'Sikubaliani',
	'app.question.strongly_disagree': 'Sikubaliani kabisa',
	'app.question.q1': 'Wakati fulani sijui niende njia gani.',
	'app.question.q2': 'Watu walinifanyia mzaha nilipokuwa mkubwa.',
	'app.question.q3': 'Wakati fulani najilinganisha na wengine.',
	'app.question.q4': 'Ni afya kwa vijana kufanya majaribio ya kujamiiana.',
	'app.question.q5': 'Nimekuwa na migogoro mingi na mtu mmoja au zaidi wa familia yangu.',
	'app.question.q6': 'Ningeogopa kuwa peke yangu duniani.',
	'app.question.q7': 'Laiti mama yangu na/au baba yangu wangefanya mambo tofauti.',
	'app.question.q8': 'Wanawake karibu kila mara huwa na hisia zaidi wakati wa kipindi chao.',
	'app.question.q9': 'Tunapaswa kujaribu kuelewa tamaduni ambazo ni tofauti na zetu.',
	'app.question.q10': 'Mama yangu ni/alikuwa rafiki yangu mkubwa.',
	'app.question.q11': 'Hatimaye tutakubali kwamba wanaume na wanawake ni tofauti tu.',
	'app.question.q12': 'Mahali pa mwanamke ni nyumbani.',
	'app.question.q13': 'Mara nyingi mimi hushindwa na uzuri wa ulimwengu huu.',
	'app.question.q14': 'Baadhi ya watu wana jeni zenye nguvu zaidi.',
	'app.question.q15': 'Ubaguzi uko kila mahali na ni lazima tushinde.',
	'app.question.q16': 'Nimepitia tatizo chungu la utambulisho.',
	'app.question.q17': 'Mimi hulia mara kadhaa kwa mwaka.',
	'app.question.q18': 'Ninaweza/ninaweza kutegemea mama au baba yangu kwa ushauri mzuri.',
	'app.question.q19': 'Sehemu isiyo na fahamu ya akili yetu inaweza kuathiri hisia na tabia zetu.',
	'app.question.q20': 'Ni muhimu kufahamishwa vyema kuhusu matukio ya ulimwengu.',
	'app.question.q21': 'Mwanaume anapaswa kutenda kama mwanaume.',
	'app.question.q22': 'Mungu aliniumba jinsi nilivyo.',
	'app.question.q23': 'Mwishowe, watu huamua kuwa na furaha au kutokuwa na furaha.',
	'app.question.q24': 'Ninahisi uhusiano wa kina wa kiroho na Dunia.',
	'app.question.q25': 'Nilikuwa na maisha mazuri ya utotoni.',
	'app.question.q26': 'Sijisikii vizuri kila mara kuhusu mimi ni nani.',
	'app.question.q27': 'Ni makosa kutilia shaka uwepo wa Mungu.',
	'app.question.q28': 'Huwezi kumlaumu mtu yeyote isipokuwa wewe mwenyewe kwa matatizo yako.',
	'app.question.q29': 'Wanawake wanategemea zaidi kihisia kuliko wanaume.',
	'app.question.q30': 'Ninapenda kuwa na mazungumzo ya kina yanayoendelea kwa saa nyingi.',
	'app.question.q31': 'Tunapaswa kuongeza kima cha chini cha mshahara.',
	'app.question.q32': 'Nilikuwa na uhusiano mzuri na mzuri na baba yangu nilipokuwa nikikua.',
	'app.question.q33': 'UKIMWI unaweza kuwa ishara ya hasira ya Mungu.',
	'app.question.q34': 'Watu wengi sana wanatafuta kitini cha bure.',
	'app.question.q35': 'Elimu ya ngono inapaswa kufundishwa katika shule zetu.',
	'app.question.q36': 'Nilishuhudia hasira nyingi au uchokozi nilipokuwa mkubwa.',
	'app.question.q37': 'Nataka kupata hali bora ya usawa katika maisha yangu.',
	'app.question.q38': 'Ninachukua hisia zangu kwa uzito sana.',
	'app.question.q39': 'Wanawake wanaweza kuwa washindani wakali.',
	'app.question.q40': 'Hatupaswi kukataa asili yetu ya wanyama.',
	'app.question.q41': 'Baadhi ya matukio ya zamani yangu yanatia aibu kuyafikiria.',
	'app.question.q42': 'Nimekuwa na migogoro mingi katika mahusiano yangu.',
	'app.question.q43': 'Mimi ni huru zaidi kuliko kihafidhina katika masuala ya kijamii.',
	'app.question.q44': 'Nafikiri sana kuhusu maana ya maisha.',
	'app.question.q45': 'Ninahisi kuchoka wakati fulani.',
	'app.question.q46': 'Ni kawaida kwa vijana kufanya majaribio ya pombe na dawa za kujiburudisha.',
	'app.question.q47': 'Wanawake wana matatizo mengi ya kufanya maamuzi.',
	'app.question.q48': 'Maarifa ya tamaduni zingine hunisaidia kuelewa utamaduni wangu mwenyewe.',
	'app.question.q49': 'Kuna njia moja ya kwenda mbinguni.',
	'app.question.q50': 'Kwa kawaida watu hushindwa kwa sababu hawajaribu vya kutosha.',
	'app.question.q51': 'Nimelazimika kuweka umbali kutoka kwa wazazi wangu.',
	'app.question.q52': 'Upende usipende, wavulana watakuwa wavulana.',
	'app.question.q53': 'Binadamu wamebadilika zaidi ya mamilioni ya miaka.',
	'app.question.q54': 'Mtu mmoja au zaidi katika familia yangu walinyanyaswa kimwili, kingono, au kihisia.',
	'app.question.q55': 'Nina mahitaji halisi ya uchangamfu na mapenzi.',
	'app.question.q56': 'Tunapaswa kutumia pesa zaidi katika programu za elimu ya awali kwa watoto.',
	'app.question.q57': 'Nina hisia sana.',
	'app.question.q58': 'Naunga mkono kazi ya vyama vya wafanyakazi.',
	'app.question.q59': 'Nina wasiwasi kuhusu mazingira yetu.',
	'app.question.q60': 'Inaumiza kukataliwa.',
	'app.question.q61': 'Sina chochote kibaya cha kusema kuhusu wazazi wangu.',
	'app.question.q62': 'Nimejiuliza kuhusu mimi ni nani na ninaenda wapi.',
	'app.question.q63': 'Hisia zangu wakati mwingine zinaweza kunishinda.',
	'app.question.q64': 'Wanawake wanasengenya sana kuliko wanaume.',
	'app.question.q65': 'Bila dini hakuwezi kuwa na amani.',
	'app.question.q66': 'Hakuna ubishi kwamba wanaume wana nguvu zaidi kuliko wanawake.',
	'app.question.q67': 'Kama jamii, tuna mwelekeo wa watumiaji kupita kiasi.',
	'app.question.q68': 'Familia yangu ilikuwa na matatizo mengi ya pesa.',
	'app.question.q69': 'Kila mara ninajaribu kujielewa vizuri zaidi.',
	'app.question.q70': 'Ni kawaida tu kwamba wenye nguvu wataishi.',
	'app.question.q71': 'Wanaume wana akili zaidi kuliko wanawake.',
	'app.question.q72': 'Inasaidia kukabiliana na hisia za uchungu za zamani.',
	'app.question.q73': 'Wakati fulani wanaume hujihisi dhaifu kama wanawake.',
	'app.question.q74': 'Itakuwa vibaya kumvisha mvulana nguo za pinki.',
	'app.question.q75': 'Ninapenda kutazama filamu kuhusu mahusiano.',
	'app.question.q76': 'Nilikuwa na uhusiano mzuri na mzuri na mama yangu nilipokuwa nikikua.',
	'app.question.q77': 'Nina wasiwasi kuhusu afya ya sayari yetu.',
	'app.question.q78': 'Mwili wangu ni nyeti sana kwa kile ninachohisi.',
	'app.question.q79': 'Lazima nikubali kwamba mimi ni kama wazazi wangu.',
	'app.question.q80': 'Wanawake wana hisia zaidi kuliko wanaume.',
	'app.question.q81': 'Nina imani kali za kidini.',
	'app.question.q82': 'Mimi ni jinsi nilivyo kwa sababu nilizaliwa hivyo.',
	'app.question.q83': 'Wazazi wangu walikuwa na migogoro mingi katika uhusiano wao.',
	'app.question.q84': 'Ninapenda kufikiria kuhusu mimi ni nani.',
	'app.question.q85': 'Wanawake wamepiga hatua nzuri katika miongo michache iliyopita.',
	'app.question.q86': 'Baba yangu ni shujaa wangu.',
	'app.question.q87': 'Nimekuwa na wakati mgumu kujua la kufanya na maisha yangu.',
	'app.question.q88': 'Wakati fulani nahisi mambo kwa undani sana.',
	'app.question.q89': 'Tunapaswa kufikiria dunia kama mama yetu.',
	'app.question.q90': 'Nimejihisi mpweke maishani mwangu.',
	'app.question.q91': 'Maprofesa wengi ni huria sana katika maoni yao.',
	'app.question.q92': 'Tumaini pekee la kweli la uponyaji linatokana na uwezo wa juu zaidi.',
	'app.question.q93': 'Wakati fulani ninahisi mhitaji na niko hatarini.',
	'app.question.q94': 'Tunapaswa kufanya zaidi kusaidia vikundi vya wachache katika jamii yetu.',
	'app.question.q95': 'Kila mtu anaweza kufaidika kwa kujifunza zaidi ya lugha moja.',
	'app.question.q96': 'Ingawa tunatarajia wawe, wanaume hawajajengwa kuwa waaminifu katika ndoa.',
	'app.question.q97': 'Sielewi kwa nini baadhi ya mambo hutokea.',
	'app.question.q98': 'Baba yangu yuko/alikuwa mbali nami kihisia.',
	'app.question.q99': 'Tunapaswa kumchagua mwanamke kuongoza taifa letu.',
	'app.question.q100': 'Mwishowe, sote tunachagua aina ya maisha tuliyo nayo.',
	'app.question.q101': 'Tawi la mahakama la serikali yetu ni huria kupita kiasi.',
	'app.question.q102': 'Nataka kuchukuliwa kwa uzito.',
	'app.question.q103': 'Tunapaswa kufanya zaidi kuwasaidia wale ambao hawana bahati.',
	'app.question.q104': 'Sijali maonyesho ya hisia.',
	'app.question.q105': 'Niliumizwa kihisia nilipokuwa nikikua.',
	'app.question.q106': 'Ponografia inadhalilisha wanawake.',
	'app.question.q107': 'Nimekerwa na ukosefu wote wa haki duniani.',
	'app.question.q108': 'Programu kubwa za serikali hufanya madhara zaidi kuliko manufaa.',
	'app.question.q109': 'Hisia zangu zimeumizwa siku za nyuma.',
	'app.question.q110': 'Ninapenda kusoma vitabu vya kujisaidia.',
	'app.question.q111': 'Mama yangu na/au baba ana/amekuwa na matatizo ya kihisia.',
	'app.question.q112': 'Ninafurahia kujifunza kuhusu tamaduni zingine.',
	'app.question.q113': 'Lazima tuhifadhi mila zetu zinazoaminika.',
	'app.question.q114': 'Wanawake hawana shauku ya kutosha kufanikiwa.',
	'app.question.q115': 'Wakati fulani mimi huwa na hasira na watu wengine.',
	'app.question.q116': 'Hakuna mtu anayeweza kukuumiza isipokuwa umruhusu.',
	'app.question.q117': 'Ninahitaji mtu katika maisha yangu kusikiliza jinsi ninavyohisi.',
	'app.question.q118': 'Unaweza kushinda karibu tatizo lolote ukijaribu tu zaidi.',
	'app.question.q119': 'Maombi hayatimizi chochote.',
	'app.question.q120': 'Mimi/mara nyingi nilikuwa katikati ya masuala au migogoro ya wazazi wangu.',
	'app.question.q121': 'Kuna pengo kubwa sana kati ya matajiri na maskini katika nchi yetu.',
	'app.question.q122': 'Sielewi kila kitu ninachohisi.',
	'app.question.q123': 'Watu hawabadiliki.',
	'app.question.q124': 'Sikuwahi kujisikia kama ninaifaa familia yangu.',
	'app.question.q125': 'Wanaume kwa asili wana ushindani zaidi kuliko wanawake.',
	'app.question.q126': 'Lazima tufanye zaidi kulinda maliasili za dunia.',
	'app.question.q127': 'Nimekuwa na matatizo makubwa ya pesa maishani mwangu.',
	'app.question.q128': 'Sipendi kukosolewa.',
	'app.question.q129': 'Mama au baba yangu na mimi tuko/tulikuwa karibu.',
	'app.question.q130': 'Nina matatizo ambayo ninahitaji kufanyia kazi.',
	'app.question.q131': 'Maombi ni uponyaji zaidi kuliko tiba asilia.',
	'app.question.q132': 'Nilinyanyaswa/nilipuuzwa nilipokuwa mdogo.',
	'app.question.q133': 'Lazima tupigane zaidi katika vita dhidi ya dawa za kulevya.',
	'app.question.q134': 'Naunga mkono kwa dhati haki sawa kwa wanawake.',
	'app.question.q135': 'Baba yangu alinielewa na kunikubalia.',
	'app.question.q136': 'Elimu ni ufunguo wa jamii iliyoelimika zaidi.',
	'app.question.q137': 'Jukumu la mwanamume ni kuwa na nguvu.',
	'app.question.q138': 'Tunapaswa kulinda ardhi bila kujali ni nani anayeimiliki.',
	'app.question.q139': 'Wazazi wangu walidai mengi kutoka kwangu.',
	'app.question.q140': 'Sote tungefaidika ikiwa wanawake wangekuwa na mamlaka zaidi ya kiuchumi na kisiasa.',
	'app.question.q141': 'Wakati mwingine mimi hulia wakati watu wengine hulia.',
	'app.question.q142': 'Baadhi ya wanawake huomba kunyanyaswa kwa sababu ya jinsi wanavyovaa.',
	'app.question.q143': 'Ikiwa huwezi kusema kitu kizuri, usiseme chochote.',
	'app.question.q144': 'Huwezi kubadilisha yaliyopita kwa hivyo hakuna haja ya kuyazingatia.',
	'app.question.q145': 'Wakati maisha yanakuangusha unapaswa kuamka na kuendelea kusonga mbele.',
	'app.question.q146': 'Kukataliwa mara nyingi hukufanya uwe na nguvu zaidi.',
	'app.question.q147': 'Baadhi ya watu huchanganua ulimwengu kupita kiasi.',
	'app.question.q148': 'Kila ninapojisikia vibaya mimi hujaribu kufikiria mawazo chanya.',
	'app.question.q149': 'Daima ni bora kuzingatia uwezo kuliko udhaifu.',
	'app.question.q150': 'Njia bora ya kuishi ni kuwa na mtazamo wa furaha.',
	'app.question.q151': 'Kilichofanywa kimefanywa ili samehe na usahau.',
	'app.question.q152': 'Kwa kweli huwezi kusema kwamba mtazamo mmoja ni bora kuliko mwingine wowote.',
	'app.question.q153': 'Nadhani watu wanapaswa kushukuru kwa kile walicho nacho na kuacha kulalamika.',
	'app.question.q154': 'Nambari zingine hazina bahati zaidi kuliko zingine.',
	'app.question.q155': 'Hakuna sababu ya kutumia lugha chafu.',
	'app.question.q156': 'Watu wengi sana hawatimizi wajibu wao.',
	'app.question.q157': 'Saikolojia inaweza kutabiri siku zijazo.',
	'app.question.q158': 'Siku zote ni bora kujaribu na kurahisisha mambo.',
	'app.question.q159': 'Huwezi kupata wataalamu wawili kukubaliana juu ya jambo lolote.',
	'app.question.q160': 'Ukicheza kwa kufuata sheria unaenda vizuri.',
	'app.question.q161': 'Ingekuwa vyema ikiwa kila mtu angenipenda au angalau angeniheshimu.',
	'app.question.q162': 'Tunapaswa kuvumilia zaidi desturi tofauti za kitamaduni.',
	'app.question.q163': 'Mimi ni mtu huru.',
	'app.question.q164': 'Ninaamini katika uwezo wa unajimu.',
	'app.question.q165': 'Nina huzuni kwa watu wanaoishi katika umaskini.',
	'app.question.q166': 'Ninathamini mantiki iliyo wazi zaidi ya vitu vingine vingi.',
	'app.question.q167': 'Ninastarehe nikiwa na makundi ya watu ambao ni tofauti sana na mimi.',
	'app.question.q168': 'Wazazi wanapaswa kukaa pamoja kwa ajili ya watoto wao.',
	'app.question.q169': 'Ni afya kwa watu kuwa na mawazo ya ngono.',
	'app.question.q170': 'Lebo za uchunguzi sio zote muhimu.',
	'app.question.q171': 'Kila mara mimi hutazama upande mzuri wa mambo.',
	'app.question.q172': 'Viumbe kutoka sayari nyingine wamesaidia viumbe wetu.',
	'app.question.q173': 'Dunia ina umri wa mabilioni ya miaka.',
	'app.question.q174': 'Udhaifu unaweza kuwa wema.',
	'app.question.q175': 'Hakuna mtu anayependa kukata tamaa.',
	'app.question.q176': 'Ninasumbuliwa na maonyesho ya hisia.',
	'app.question.q177': 'Wazazi wangu walikuwa na uhusiano mzuri sana nilipokuwa nikikua.',
	'app.question.q178': 'Tunavumilia sana desturi tofauti za kitamaduni.',
	'app.question.q179': 'Tunafanya vya kutosha kuwasaidia maskini.',
	'app.question.q180': 'Watu wana wasiwasi sana kuhusu mazingira.',
	'app.question.q181': 'Kwa kweli ubaguzi sio tatizo tena.',
	'app.question.q182': 'Watu hubadilika kila wakati.',
	'app.question.q183': 'Kuna njia nyingi za kwenda mbinguni.',
	'app.question.q184': 'Wanaume na wanawake wanafanana zaidi kuliko tofauti.',
	'app.question.q185': 'Hatuchagui maisha tuliyo nayo.',
};

export const scaleQuestionsPageSW: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
