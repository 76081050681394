import { InstitutionMessages } from '../types';

export const institutionSW: InstitutionMessages = {
	'app.institutions.title': 'Usimamizi wa Shirika',
	'app.institutions.new': 'Shirika Jipya',
	'app.institutions.search': 'Tafuta',
	'app.institutions.list.name': 'Jina',
	'app.institutions.list.edit': 'Hariri',
	'app.institutions.list.action': 'Vitendo',
	'app.institutions.add.title': 'Shirika Jipya',
	'app.institutions.edit.title': 'Hariri Shirika',
	'app.institutions.add.successful_message': 'Shirika limesasishwa kwa ufanisi.',
	'app.institutions.add.error_message': 'Hitilafu imetokea, tafadhali, jaribu tena baada ya dakika chache.',
	'app.institutions.add.new_name': 'Jina jipya',
	'app.institutions.add.button_save': 'Hifadhi',
	'app.institutions.add.hide_demographics_questions': 'Ficha Maswali kuhusu Demografia',
	'app.institutions.add.demographics_extra_questions': 'Maswali ya Ziada ya Demografia',
	'app.institutions.hide_demographics_questions.successful_message': 'Maswali ya demografia yalisasishwa kwa ufanisi.',
	'app.institutions.demographics_questions.question': 'Swali',
	'app.institutions.demographics_questions.type': 'Aina',
	'app.institutions.demographics_questions.type_0': 'Chaguo-msingi',
	'app.institutions.demographics_questions.type_1': 'Nakala',
	'app.institutions.demographics_questions.type_2': 'Chaguo Moja',
	'app.institutions.demographics_questions.add.title': 'Swali Jipya la Ziada',
	'app.institutions.demographics_questions.edit.title': 'Hariri Swali la Ziada',
	'app.institutions.demographics_questions.title': 'Kichwa',
	'app.institutions.demographics_questions.options': 'Chaguo',
	'app.institutions.demographics_questions.description': 'Maelezo',
	'app.institutions.demographics_questions.delete': 'Je, una hakika ya kufuta kipengee hiki?',
	'app.institutions.chat.successful_message': 'Baada ya dakika chache, watumiaji wote wataweza kutumia gumzo la AI.',
	'app.institutions.chat.add_chat_all': 'Ongeza Gumzo kwa Watumiaji wote',
	'app.institutions.chat.question_add_chat_all': 'Je, una uhakika unataka kujumuisha gumzo kwa watumiaji wote katika shirika hili?'
}