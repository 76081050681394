import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsHI: DiscursiveQuestionsPageMessages = {
    'app.discursive_questions.introduction': 'जिस अनुभव के लिए आप BEVI पूरा कर रहे हैं उस पर विचार करते हुए कृपया निम्नलिखित तीन प्रश्नों के उत्तर दें',
    'app.discursive_questions.question1': '1. आपके अनुभव की किस घटना या पहलू का आप पर सबसे अधिक प्रभाव पड़ा और क्यों?',
    'app.discursive_questions.question2': '2. क्या आपके अपने \'स्व\' या \'पहचान\' (जैसे कि लिंग, जातीयता, यौन अभिवृत्ति, धार्मिक या राजनीतिक पृष्ठभूमि, आदि) का कोई ऐसा पहलू था जो इस अनुभव के परिणामस्वरूप आपके या दूसरों के लिए विशेष रूप से स्पष्ट या प्रासंगिक हो गया?',
    'app.discursive_questions.question3': '3. इस अनुभव के परिणामस्वरूप आपने क्या सीखा है या आप कैसे अलग हैं?',
    'app.discursive_questions.thank_you': 'BEVI पूरा करने के लिए धन्यवाद!',
    'app.discursive_questions.please_note': 'कृपया ध्यान दें:',
    'app.discursive_questions.go_to_report': 'यदि आप अपनी रिपोर्ट अभी देखना चाहते हैं, तो कृपया नीचे दिए गए बटन पर क्लिक करें। आप बाद में अपने डैशबोर्ड में भी अपनी रिपोर्ट की समीक्षा कर सकते हैं।',
    'app.discursive_questions.do_not_show_report': 'दुर्भाग्य से, कई संभावित कारणों (जैसे कि बहुत सारे प्रश्नों के उत्तर गायब थे) के कारण हम आपकी रिपोर्ट तैयार नहीं कर पा रहे हैं। यदि आप चाहें, तो आप BEVI को फिर से ले सकते हैं।',
    'app.discursive_questions.visualize_report': 'रिपोर्ट पढ़ें',
};
