import { NoInfoMessages } from '../types';

export const noInfoHI: NoInfoMessages = {
    'app.no.info.title.not_found': 'पृष्ठ नहीं मिला',
    'app.no.info.title.no_data': 'कोई डेटा नहीं मिला',
    'app.no.info.subtitle.not_found': 'लगता है कि आप खो गए हैं!',
    'app.no.info.subtitle.no_data': 'सुनिश्चित करें कि आपकी खोज पैरामीटर सही हैं, और अगर कोई त्रुटि है तो info@thebevi.com पर संपर्क करें।',
    'app.no.info.body.not_found': 'जिस पृष्ठ की आप तलाश कर रहे हैं वह मौजूद नहीं है या स्थानांतरित कर दिया गया है।',
    'app.no.info.body.no_data': 'आपके द्वारा चुने गए फ़ील्ड को देखें और यदि सब कुछ सही है, तो खाता प्रबंधक से संपर्क करें।',
    'app.no.info.title.not_authorized': 'पृष्ठ अधिकृत नहीं है',
    'app.no.info.subtitle.not_authorized': 'लगता है कि आप एक ऐसा पृष्ठ एक्सेस करने की कोशिश कर रहे हैं जिसके लिए आपके पास अनुमति नहीं है।',
    'app.no.info.body.not_authorized': 'यदि आप इसे एक्सेस करना चाहते हैं, तो कृपया अकाउंट प्रबंधक से संपर्क करें।',
    'app.no.info.title.maintenance': 'सिस्टम रखरखाव में है',
    'app.no.info.subtitle.maintenance': 'लगता है कि सिस्टम रखरखाव में है',
    'app.no.info.body.maintenance': 'कृपया कुछ मिनट प्रतीक्षा करें और पुनः प्रयास करें।',
}
