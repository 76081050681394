import React from 'react';
import { Button, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';

import { colors } from '../../../config/colors';
import { GetFormattedMessage } from '../../../utils/htmlHelper';

type Props = {
  showModal: boolean;
  user: any;
  loadingButton: boolean;
  success: boolean;
  error: boolean;
  onModalHide: () => void;
  onRemove2FA(id: number): void;
}

export const Remove2FAModal = (
  { loadingButton, showModal, user, success, error, onModalHide, onRemove2FA }: Props
): JSX.Element => {

  const onSubmitButtonClicked = () => {
      onRemove2FA(user.id);
  }

  return (
    <Modal show={showModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {GetFormattedMessage('app.users.listuser.clear_2fa').toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {success === true &&
            <div className="alert alert-primary" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.user.updateSuccessfulMessage') }} />
          }
          {error === true &&
            <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.reset_password.error_message') }} />
          }
          <Form.Group className='mb-3' as={Col}>
            <Form.Group className='mb-3' as={Row} controlId="formField2">
              {user !== undefined &&
                <div dangerouslySetInnerHTML={{ __html: GetFormattedMessage('app.users.confirm_deletion_body') }} />
              }
            </Form.Group>
          </Form.Group>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: colors.darkSlateBlue }}
          variant='primary'
          onClick={onSubmitButtonClicked}
          disabled={success}
        >
          {loadingButton &&
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: 10 }}
            />
          }
          {GetFormattedMessage('app.btn_confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};