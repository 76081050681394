import { InternalTemplateMessages } from '../types';

export const internalTemplatePageHI: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'स्वागत है',
    'app.internaltemplate.menu.dashboard': 'डैशबोर्ड',
    'app.internaltemplate.menu.users': 'उपयोगकर्ता',
    'app.internaltemplate.menu.organization': 'कार्यक्रम और अनुभव',
    'app.internaltemplate.menu.institutions': 'संस्थान',
    'app.internaltemplate.menu.report': 'रिपोर्ट',
    'app.internaltemplate.menu.sample_report': 'सैंपल रिपोर्ट',
    'app.internaltemplate.menu.permission_profile': 'अनुमति प्रोफ़ाइल',
    'app.internaltemplate.menu.financial_report': 'उपयोग रिपोर्ट',
    'app.internaltemplate.menu.logout': 'लॉगआउट',
    'app.internaltemplate.menu.virtualAccount': 'वर्चुअल अकाउंट',
    'app.internaltemplate.system.maintenance_time': 'सिस्टम {0} से {1} तक रखरखाव में रहेगा।',
    'app.internaltemplate.menu.report_permission': 'रिपोर्ट अनुमतियाँ',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': 'दो कारक प्रमाणीकरण कॉन्फ़िगर करें',
}
