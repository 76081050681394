import { PermissionMessages } from '../types';

export const permissionSW: PermissionMessages = {
'app.permission.title': 'Ruhusa - Ripoti ya Mtu Binafsi - Wasifu',
'app.permission.remove_permission_to_all': 'Ondoa ruhusa kwa wote',
'app.permission.give_permission_to_all': 'Toa ruhusa kwa wote',
'app.permission.remove_permission': 'Ondoa ruhusa',
'app.permission.give_permission': 'Toa ruhusa',
'app.permission.table_title.permission': 'Ruhusa',
'app.permission.table_title.name': 'Jina',
'app.permission.table_title.email': 'Barua pepe',
'app.permission.button.update': 'Sasisha',
'app.permission.message': 'Watumiaji wamesasishwa kwa ufanisi',
'app.permission.add_permissions_with_excel_file': 'Ongeza ruhusa kwa kutumia faili ya Excel',
'app.permission.add_permissions': 'Ongeza ruhusa',
'app.permission.permissions': 'Ruhusa',
'app.permission.download_tooltip': 'Pakua kiolezo cha faili'
}
