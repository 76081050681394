import { ExternalPageMessages } from '../types';

export const externalHI: ExternalPageMessages = {
    'app.external.title.thankyou': 'BEVI पूरा करने के लिए धन्यवाद।',
    'app.external.thankyou_static_1_1': 'बेलिफ्स, इवेंट्स, और वैल्यूज़ इन्वेंटरी (BEVI) और इस BEVI रिपोर्ट का कॉपीराइट है। ' +
        'BEVI की लिखित और स्पष्ट अनुमति के बिना, BEVI और BEVI रिपोर्ट, आइटम, आइटम सामग्री, या स्केल को पूर्ण या आंशिक रूप से संशोधित, कॉपी, वितरित या प्रकाशित नहीं किया जा सकता।',
    'app.external.thankyou_static_1_2': '',
    'app.external.thankyou_static_2': 'जैसे कि BEVI के साथ, इन रिपोर्टों की संरचना और डिज़ाइन भी लगातार समीक्षा और विकास की प्रक्रियाओं के अधीन हैं। यदि आपके पास BEVI या इस रिपोर्ट के किसी भी पहलू के बारे में सुझाव या टिप्पणियाँ हैं, तो कृपया हमसे सीधे संपर्क करें: info@ibavi.org',
    'app.external.thankyou_static_3': 'विश्वासों और मूल्यों के बारे में अधिक जानने के लिए, कृपया इंटरनेशनल बिलीफ्स और वैल्यूज़ इंस्टीट्यूट (IBAVI) की वेबसाइट www.ibavi.org और www.thebevi.com पर जाएँ।',
};
