import { successfulCode } from '../config/constants';

export class BaseResponse<T> {
  status?: number;
  body?: T;
  keyErrorMessage?: string;
  serviceUnavailable?: boolean;
}

export function fetchPostMethodWithoutToken<T>(path: string, body: T): Promise<Response> {
  return fetch(path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function fetchBasicPostMethod<T>(path: string, username: string, password: string, code: string, key: string, body: T): Promise<Response> {
  return fetch(path, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(`${username}:${password}:${code}:${key}`),
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function fetchPostMethod<T>(path: string, userToken: string, body: T): Promise<Response> {
  return fetch(path, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function fetchPostDataObjectMethod<T>(path: string, userToken: string, body: T): Promise<Response> {
  return fetch(path, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Accept': 'application/octet-stream',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export function fetchPostWithFormDataMethod(path: string, userToken: string, formData: FormData): Promise<Response> {
  return fetch(path, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Accept': 'application/json',
    },
    body: formData
  });
}

export function fetchGetMethod(path: string, userToken: string): Promise<Response> {
  return fetch(path, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Accept': 'application/json'
    }
  });
}

export function fetchGetDataObjectMethod(path: string, userToken: string): Promise<Response> {
  return fetch(path, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Accept': 'application/octet-stream',
      'Content-Type': 'application/json'
    },
  });
}

export function getResponseObjectWithoutBody(
  promise: Promise<Response>, defaultErrorMessage: string
): Promise<BaseResponse<{}>> {
  return new Promise<BaseResponse<{}>>((accept) => {
    promise.then((response: Response) => {
      if (response.status === successfulCode) {
        accept({ status: response.status });
      } else {
        console.log('getResponseObject - response status different of successful code', response);
        accept({ keyErrorMessage: defaultErrorMessage, status: response.status });
      }
    }).catch((error) => {
      accept({ serviceUnavailable: true } as BaseResponse<{}>);
    });
  });
}

export function getResponseObject<T>(
  promise: Promise<Response>, defaultErrorMessage: string
): Promise<BaseResponse<T>> {
  return new Promise<BaseResponse<T>>((accept) => {
    promise.then((response: Response) => {
      // TODO(rodrigo.santos): Get body with failed code.
      if (response.status === successfulCode) {
        response.json()
          .then((body) => accept({ body: body, status: response.status } as BaseResponse<T>))
          .catch((error) => {
            console.log('getResponseObject - error (json response catch)', error);
            accept({ keyErrorMessage: error.message } as BaseResponse<T>);
          });
      } else {
        console.log('getResponseObject - response status different of successful code', response);
        accept({ keyErrorMessage: defaultErrorMessage, status: response.status } as BaseResponse<T>);
      }
    }).catch((error) => {
      accept({ serviceUnavailable: true } as BaseResponse<T>);
    });
  });
}

// It will download instantly.
export function getResponseDataObject<T>(
  promise: Promise<Response>, defaultErrorMessage: string, fileName: string
): Promise<BaseResponse<T>> {
  return new Promise<BaseResponse<T>>((accept) => {
    promise.then((response: Response) => {
      if (response.status === successfulCode) {
        response.blob().then(blob => {
          accept({ status: response.status });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      } else {
        accept({ keyErrorMessage: defaultErrorMessage, status: response.status } as BaseResponse<T>);
      }
    }).catch((error) => {
      accept({ serviceUnavailable: true } as BaseResponse<T>);
    });
  });
}
