import { InternalTemplateMessages } from '../types';

export const internalTemplatePageCA: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'Benvinguts',
    'app.internaltemplate.menu.dashboard': 'Tauler',
    'app.internaltemplate.menu.users': 'Usuaris',
    'app.internaltemplate.menu.organization': 'Programes i experiències',
    'app.internaltemplate.menu.institutions': 'Organitzacions',
    'app.internaltemplate.menu.report': 'Informes',
    'app.internaltemplate.menu.sample_report': 'Informe de mostra',
    'app.internaltemplate.menu.permission_profile': 'Perfil de Permisos',
    'app.internaltemplate.menu.financial_report': 'Informe d’ús',
    'app.internaltemplate.menu.logout': 'Tanca la sessió',
    'app.internaltemplate.menu.virtualAccount': 'Compte virtual',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': "Configura l'autenticació de dos factors",
    'app.internaltemplate.system.maintenance_time': '(TODO) The system will be under maintenance from {0} to {1}.',
    'app.internaltemplate.menu.report_permission': "Permís d'informe"
}
