import detectBrowserLanguage from 'detect-browser-language';
import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { ca, en, es, ja, zhCN, zhTW, de, sw, hi } from './locales';
import { PageMessages } from './locales/types';
import { ReportLanguage } from './services/reportService';

// http://www.lingoes.net/en/translator/langcode.htm
const availableLanguages = ['ca', 'en', 'ja', 'es', 'zf', 'zh_CN', 'zn_TW', 'de', 'sw', 'hi']; // 'Catalan', 'English', 'Japanese', 'Spanish', 'Chinese Simplified', 'Chinese Trandicional', 'German', 'Swahili', 'Hindi'
export const LocalStorage_BeviLanguage = 'bevi-language';

export type IntlContextProps = {
    switchToEnglish: () => void;
    switchToJapanese: () => void;
    switchToChineseSimplified: () => void;
    switchToChineseTradicional: () => void;
    switchToSpanish: () => void;
    switchToCatalan: () => void;
    switchToGerman: () => void;
    switchToSwahili: () => void;
    switchToHindi: () => void;
    getReportLanguage: () => ReportLanguage;
    messages: PageMessages;
    locale: string;
};

export const IntlContext = React.createContext<Partial<IntlContextProps>>({});
const getMessage = (language: string): PageMessages => {
    switch (language) {
        case 'ca': return ca;
        case 'ja': return ja;
        case 'es': return es;
        case 'zh-CN': return zhCN;
        case 'zh-TW': return zhTW;
        case 'de': return de;
        case 'sw': return sw;
        case 'hi': return hi;
        case 'en':
        default:
            return en;
    }
}

const getAvailableLanguage = (language: string): string => {
    let defaultLanguage = 'en';

    const currentLocaleIndex = availableLanguages.findIndex(
        (searchString) => language.startsWith(searchString),
        language)

    if (currentLocaleIndex > -1) defaultLanguage = availableLanguages[currentLocaleIndex];

    return defaultLanguage;
}

type Props = {
    children?: ReactNode;
};
type State = {
    locale: string;
    messages: PageMessages;
};

export class IntlProviderWrapper extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let defaultLanguage = 'en';

        const localStorageLanguage = localStorage.getItem(LocalStorage_BeviLanguage);
        if (localStorageLanguage && localStorageLanguage != null) {
            defaultLanguage = getAvailableLanguage(localStorageLanguage);
        } else {
            const detectedLanguage = detectBrowserLanguage();
            defaultLanguage = getAvailableLanguage(detectedLanguage);
        }

        const defaultMessages = getMessage(defaultLanguage);

        // Pass everything in state to avoid creating object inside render method.
        this.state = {
            locale: defaultLanguage,
            messages: defaultMessages
        };

        localStorage.setItem(LocalStorage_BeviLanguage, defaultLanguage);
    }

    switchLanguage = (language: string) => {
        this.setState({ locale: language, messages: getMessage(language) });
        localStorage.setItem(LocalStorage_BeviLanguage, language);
    }

    getReportLanguage = (): ReportLanguage => {
        const locale = this.state.locale;
        if (locale === "ja") return ReportLanguage.Japanese;
        if (locale === "ca") return ReportLanguage.Catalan;
        if (locale === "es") return ReportLanguage.Spanish;
        if (locale === 'zh-CN') return ReportLanguage.ChineseSimplified;
        if (locale === 'zh-TW') return ReportLanguage.ChineseTradicional;
        if (locale === 'de') return ReportLanguage.German;
        if (locale === 'sw') return ReportLanguage.Swahili;
        if (locale === 'hi') return ReportLanguage.Hindi;

        return ReportLanguage.English;
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        return (
            <IntlContext.Provider
                value={{
                    switchToCatalan: () => this.switchLanguage('ca'),
                    switchToEnglish: () => this.switchLanguage('en'),
                    switchToJapanese: () => this.switchLanguage('ja'),
                    switchToSpanish: () => this.switchLanguage('es'),
                    switchToChineseSimplified: () => this.switchLanguage('zh-CN'),
                    switchToChineseTradicional: () => this.switchLanguage('zh-TW'),
                    switchToGerman: () => this.switchLanguage('de'),
                    switchToSwahili: () => this.switchLanguage('sw'),
                    switchToHindi: () => this.switchLanguage('hi'),
                    getReportLanguage: () => this.getReportLanguage(),
                    messages: messages,
                    locale: locale
                }}
            >
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </IntlContext.Provider>
        );
    }
}
