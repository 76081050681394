import { LoginPageMessages } from '../types';

export const loginPageDE: LoginPageMessages = {
    'app.login.text_welcome': 'Willkommen beim Beliefs, Events, and Values Inventory (Überzeugungen, Lebensereignisse und Werte Inventar, BEVI)',
    'app.login.text_information': `Um auf diese Seite zugreifen zu können, müssen Sie von einer vom BEVI lizenzierten Einrichtung, Organisation oder einer/m Administrator:in dazu eingeladen worden sein.`,
    'app.login.text_login_requirement': `Bitte geben Sie den erforderlichen Benutzernamen und das Passwort ein (werden Ihnen zur Verfügung gestellt).`,
    'app.login.lbl_username': 'Benutzername:',
    'app.login.lbl_password': 'Passwort:',
    'app.login.btn_submit': 'Anmelden',
    'app.login.lbl_new_password': 'Neues Passwort',
    'app.login.lbl_new_password_confirmation': 'Passwort erneut eingeben',
    'app.login.btn_submit_new_password': 'Passwort zurücksetzen',
    'app.login.placeholder.login': 'email@beispiel.com',
    'app.login.placeholder.password': 'Ihr Passwort',
    'app.login.invalid_credentials': 'Oh nein! Ihre Anmeldedaten scheinen nicht mit unseren Aufzeichnungen übereinzustimmen. Bitte versuchen Sie es erneut oder setzen Sie Ihr Konto zurück, indem Sie unten auf Passwort vergessen klicken.',
    'app.login.forgot_password.invalid_credentials': 'Die E-Mail ist ungültig. Bitte versuchen Sie es erneut.',
    'app.login.forgot_password': 'Passwort vergessen?',
    'app.login.forgot_password_confirmation_title': 'Passwort zurücksetzen',
    'app.login.forgot_password_confirmation_body': 'Es wurde eine E-Mail an die angegebene E-Mail-Adresse gesendet. Befolgen Sie die Anweisungen in der E-Mail, um Ihr Passwort zurückzusetzen.',
    'app.login.btn_submit_forgot_password': 'Passwort wiederherstellen',
    'app.login.passwords_dont_match': 'Die Passwörter stimmen nicht überein.',
    'app.login.change_password.expired_confirmation_title': 'Diese Sitzung ist abgelaufen',
    'app.login.change_password.expired_confirmation_body': 'Ihre Zugangsberechtigung für diese Seite ist abgelaufen. Bitte beantragen Sie den Zugang erneut oder loggen Sie sich ein, um auf BEVI zuzugreifen.',
    'app.login.change_password.success_confirmation_title': 'Passwort aktualisieren',
    'app.login.change_password.success_confirmation_body': 'Ihr Passwort wurde erfolgreich aktualisiert. Benutzen Sie Ihr neues Passwort, um sich auf der Startseite einzuloggen; dort werden Sie dann weitergeleitet.',
    'app.login.first_access.title': 'Bitte ändern Sie Ihr Passwort, bevor Sie das System benutzen.',
    "app.login.auth.qrcode": "Scannen Sie diesen QR-Code mit Google Authenticator",
    'app.login.auth.type': 'Geben Sie den Google Authenticator-Code ein, um zu validieren',
    "app.login.auth.backLogin": "Zurück zum Login",
    "app.login.auth.invalid_code": "Ungültiger Code",
    'app.login.auth.validate_success': 'Authentifikator-Validierung erfolgreich'
}
