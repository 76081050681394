import { ProgramsMessages } from '../types';

export const programsHI: ProgramsMessages = {
  'app.programs.add.title': 'नया कार्यक्रम',
  'app.programs.rename': 'कार्यक्रम का नाम बदलें',
  'app.programs.edit.title': 'कार्यक्रम संपादित करें',
  'app.programs.add.successful_message': 'कार्यक्रम सफलतापूर्वक अपडेट किया गया।',
  'app.programs.add.error_message': 'कुछ गलत हो गया, कृपया कुछ मिनटों में पुनः प्रयास करें।',
  'app.programs.add.new_name': 'नया नाम',
  'app.programs.add.button_save': 'सहेजें',
  'app.programs.chat.successful_message': 'कुछ ही मिनटों में, सभी उपयोगकर्ता AI चैट का उपयोग कर सकेंगे।',
  'app.programs.chat.add_chat_all': 'सभी उपयोगकर्ताओं के लिए चैट जोड़ें',
  'app.programs.chat.question_add_chat_all': 'क्या आप सुनिश्चित हैं कि आप इस कार्यक्रम में सभी उपयोगकर्ताओं के लिए चैट शामिल करना चाहते हैं?'
}
