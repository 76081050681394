import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

import { PageMessages } from '../../locales/types';
import { H2 } from '../../components/styled/h2';
import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';
import { MultipleUser } from './components/multiple_user';
import { SingleUser } from './components/single_user';

const ReactExport: any = require('react-export-excel-xlsx-fix');

const ExcelFile = ReactExport.modules.ExcelFile;
const ExcelSheet = ReactExport.modules.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.modules.ExcelFile.ExcelColumn;

export type UserErrorDetails = {
    lineNumber: string;
    email: string;
    userError: string;
}

export const valueColor: any = {
    32: 'magenta',
    64: 'red',
    128: 'volcano',
    256: 'orange',
    512: 'gold',
    1024: 'lime',
    2048: 'green',
    4096: 'cyan',
    8192: 'blue',
    16384: 'geekblue',
    32768: 'blueviolet',
    65536: 'aqua',
    131072: 'rebeccapurple'
}

type Props = {
    hasCreateUser: boolean;
    errorMessage?: string;
    errorDetails?: UserErrorDetails[];
    loading: boolean;
    clearAlertMessages: () => void;
}

export const CreateUser = ({ hasCreateUser, errorMessage, errorDetails, loading, clearAlertMessages }: Props): JSX.Element => {

    const [userRadioType, setUserRadioType] = useState<number>(0);

    const excelEmailColumn = GetFormattedMessage('app.create_batch_user.excel.email_column');
    const excelLineNumberColumn = GetFormattedMessage('app.create_batch_user.excel.line_number_column');
    const excelUserErrorColumn = GetFormattedMessage('app.create_batch_user.excel.user_error_column');
    const errorDetailsFilename = GetFormattedMessage('app.create_batch_user.error_details_filename');

    const radioClick = (option: number) => {
        clearAlertMessages();
        setUserRadioType(option);
    }

    const erroKey = errorMessage && (errorMessage as unknown as keyof (PageMessages));
    return (
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <H2 textAlign='center'>{GetFormattedMessage('app.create.user.title')}</H2>
                <hr />
                <Alert variant='success' show={hasCreateUser}
                    // @ts-ignore
                    style={styles.alert}>
                    {GetFormattedMessage('app.user.successfulMessage')}
                </Alert>
                <Alert variant='danger' show={!!errorMessage || (errorDetails != null && errorDetails?.length > 0)} style={styles.alert}>
                    <p> {GetFormattedMessage(erroKey)} </p>
                    {errorDetails &&
                        <div className="d-flex justify-content-end">
                            <ExcelFile
                                filename={errorDetailsFilename}
                                element={
                                    <Button className=' bg-transparent' style={{ color: 'black' }}>
                                        <FaDownload color={colors.darkSlateBlue} style={{ marginRight: 10 }} />
                                        {GetFormattedMessage('app.create_batch_user.excel.download_error_details')}
                                    </Button>
                                }
                            >
                                <ExcelSheet
                                    data={errorDetails}
                                    name={errorDetailsFilename}
                                >
                                    <ExcelColumn label={excelLineNumberColumn} value='lineNumber' />
                                    <ExcelColumn label={excelEmailColumn} value='email' />
                                    <ExcelColumn label={excelUserErrorColumn} value='userError' />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    }
                </Alert>

                <br />
                <div style={styles.alignCenter}>
                    <p><b>{GetFormattedMessage('app.create.user.choose.option.new.user')}</b></p>
                    <Form.Check
                        key={'single_user'}
                        inline={true}
                        type={'radio'}
                        label={GetFormattedMessage('app.create.user.single.user')}
                        name={'single_user'}
                        id={'save_user_type'}
                        checked={userRadioType === 1}
                        onClick={() => radioClick(1)}
                    />
                    <br />
                    <Form.Check
                        key={'multiple_users'}
                        inline={true}
                        type={'radio'}
                        label={GetFormattedMessage('app.create.user.multiple.users')}
                        name={'multiple_users'}
                        id={'multiple_users'}
                        checked={userRadioType === 2}
                        onClick={() => radioClick(2)}
                    />
                </div>
                {userRadioType === 1 && <SingleUser loading={loading} />}
                {userRadioType === 2 && <MultipleUser loading={loading} />}
            </div>
        </div>
    );
}

const styles = {
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    alignCenter: {
        textAlign: 'center'
    } as React.CSSProperties,
    alert: {
        width: 300,
        margin: '0 auto',
    } as React.CSSProperties,
    alertInternDiv: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
    } as React.CSSProperties,
};
