import { CreateReportMessages } from '../types';

export const createReportSW: CreateReportMessages = {
	'app.create_report.btn_fake_name_all': 'Jina bandia kwa washiriki wote',
	'app.create_report.btn_real_name_all': 'Jina halisi kwa washiriki wote',
	'app.create_report.btn_fake_name': 'Tumia jina bandia',
	'app.create_report.btn_real_name': 'Tumia jina halisi',
	'app.create_report.title': 'Usimamizi wa Ripoti',
	'app.create_report.summary_line1': ' Mfumo wa Usimamizi wa BEVI hukuruhusu kutoa ripoti ambazo zinatokana na data zote kutoka kwa kikundi ambacho unafanya kazi nacho. Kuna aina nne za ripoti zinazowezekana: <br />' +
	'&nbsp;&nbsp;1) Ripoti ya Kikundi (ripoti hii inategemea kipindi kimoja cha wakati ambapo BEVI inasimamiwa); <br />' +
	'&nbsp;&nbsp;2) Ripoti ya Longitudinal (hii ni ripoti ambayo inakamilishwa baada ya muda kama vile T1, T2, T3, n.k.) <br />' +
	'&nbsp;&nbsp;3) Kati ya Ripoti za Kikundi (hizi ni ripoti zinazokuruhusu kulinganisha vikundi tofauti ndani na katika taasisi au mashirika). <br />' +
	'&nbsp;&nbsp;4) Ndani ya Ripoti za Kikundi (hizi ni ripoti zinazolinganisha watu binafsi ndani ya kikundi na kila mmoja katika mizani ya BEVI, lakini hufanya hivyo bila kujulikana)',
	'app.create_report.summary_line2': ' Ripoti hizi zote zinaweza kuzalishwa moja kwa moja (yaani, zitatumia data zote moja kwa moja katika kikundi chako au vikundi). Hata hivyo, unaweza pia kuboresha kila moja ya aina hizi za ripoti ili kuzingatia michanganyiko tofauti ya vipengee vya Maelezo ya halisi. Kwa mfano, unaweza kutoa ripoti ambayo ililinganisha wanawake na wanaume kutoka nchi mbili tofauti pekee. Kuna mamia ya michanganyiko tofauti ambayo unaweza kuchagua kwa kutumia utendakazi huu wa uboreshaji. ',
	'app.create_report.step1': 'HATUA YA 1 - KUTENGENEZA RIPOTI YAKO',
	'app.create_report.step2': 'HATUA YA 2 - ONGEZA KIKUNDI CHA RIPOTI',
	'app.create_report.step3': 'HATUA YA 3 - KUBORESHA RIPOTI YAKO',
	'app.create_report.step3Individual': 'HATUA YA 3 - NANI ANAWEZA KUONA RIPOTI?',
	'app.create_report.step4': 'HATUA YA 4 - KUDHIBITI WASHIRIKI',
	'app.create_report.step3_username_column': 'Jina la mtumiaji',
	'app.create_report.step3_aliasname_column': 'Jina lingine',
	'app.create_report.step3_action_column': 'Kitendo',
	'app.create_report.step3_no_user_title': 'Hakuna watumiaji waliopatikana',
	'app.create_report.step3_no_user_subtitle': 'Tafadhali badilisha vigezo vya hatua za awali na ujaribu kutafuta watumiaji tena.',
	'app.create_report.step3_user_name_column_title': 'Washiriki',
	'app.create_report.step3_user_exporting_column_title': 'Imejumuishwa',
	'app.create_report.requester': 'Muombaji',
	'app.create_report.name': 'Jina la Ripoti ',
	'app.create_report.report_type': 'Aina',
	'app.create_report.select_subprogram': 'Chagua data ili kutoa ripoti',
	'app.create_report.select_subprogram_warning': '(tafadhali jaza sehemu zilizotangulia)',
	'app.create_report.group_report': 'Ripoti ya Kikundi',
	'app.create_report.longitudinal_report': 'Ripoti ya Mabadiliko ya Kikundi',
	'app.create_report.comparative_by_group_report': 'Ripoti kati ya kikundi',
	'app.create_report.comparative_by_individual_report': 'Ripoti Ndani ya Kikundi',
	'app.create_report.select_organization': 'Chagua shirika.',
	'app.create_report.select_program': 'Chagua programu au matumizi.',
	'app.create_report.select_sub_program': 'Chagua usimamizi wa BEVI.',
	'app.create_report.organization_name': 'Shirika',
	'app.create_report.program_name': 'Programu au Uzoefu',
	'app.create_report.sub_program_name': 'Usimamizi wa BEVI',
	'app.create_report.actions': 'Ondoa',
	'app.create_report.action_remove': 'Ondoa',
	'app.create_report.show_filters': 'Onyesha vichujio vya vigezo',
	'app.create_report.hide_filters': 'Ficha vichujio vya vigezo',
	'app.create_report.filter_select_default_option': 'Chagua kuchuja...',
	'app.create_report.filter_select_with_values': 'Kuchuja',
	'app.create_report.filter_age': 'Umri',
	'app.create_report.filter_gender': 'Jinsia',
	'app.create_report.filter_years_of_education': 'Miaka ya Elimu',
	'app.create_report.filter_primary_country_of_origin': 'Nchi ya Asili',
	'app.create_report.filter_marital_status': 'Hali ya Uhusiano',
	'app.create_report.filter_political_orientation': 'Mwelekeo wa Kisiasa (Conservative to Liberal)',
	'app.create_report.filter_ethnic_background': 'Asili ya Kikabila',
	'app.create_report.filter_religious_orientation': 'Mwelekeo wa Kidini',
	'app.create_report.filter_religious_commitment': 'Ahadi ya Kidini',
	'app.create_report.filter_academic_rank': 'Cheo cha elimu',
	'app.create_report.filter_time_spent_in_other_countries': 'Muda uliotumika katika nchi nyingine',
	'app.create_report.filter_nro_visited_countries': 'Nchi zilizotembelewa',
	'app.create_report.filter_fluency': 'Ufasaha',
	'app.create_report.filter_years_of_language_study': 'Miaka ya Masomo ya Lugha',
	'app.create_report.filter_mother_country': 'Nchi ya Mama',
	'app.create_report.filter_father_country': 'Nchi ya Baba',
	'app.create_report.filter_mother_education': 'Elimu ya Mama',
	'app.create_report.filter_father_education': 'Elimu ya Baba',
	'app.create_report.filter_minority_group': 'Kikundi cha Wachache',
	'app.create_report.filter_personal_interest': 'Kuvutiwa na Uzoefu wa Kimataifa / Kitamaduni',
	'app.create_report.filter_study_abroad_satisfaction': 'Kuridhika na Uzoefu wa Kimataifa / Kitamaduni',
	'app.create_report.filter_primary_area_of_work': 'Eneo la Msingi la Kazi',
	'app.create_report.filter_area_of_occupations': 'Eneo la Kazi',
	'app.create_report.filter_income_level_of_your_home_country': 'Kiwango cha Mapato',
	'app.create_report.btn_search': 'Tafuta',
	'app.create_report.btn_save': 'Tengeneza',
	'app.create_report.placeholder.name': 'Andika jina la ripoti',
	'app.create_report.select.report_type': 'Chagua aina ya ripoti',
	'app.create_report.select_countries_options_lbl': 'Chagua angalau Nchi moja ya asili ili kulinganisha na nyingine zote:',
	'app.create_report.selected_countries_lbl': 'Nchi zilizochaguliwa:',
	'app.create_report.select_ethnics_options_lbl': 'Chagua angalau Kabila moja ili kulinganisha na mengine yote:',
	'app.create_report.selected_ethnics_lbl': 'Mwelekeo wa makabila uliochaguliwa:',
	'app.create_report.select_number_of_forms_lbl': 'Chagua idadi ya juu zaidi ya majibu kwa vipengee vya kuakisi uzoefu ili vionyeshwe kwenye ripoti:',
	'app.create_report.select_relationship_status_options_lbl': 'Chagua angalau Hali moja ya Uhusiano ili kutofautisha na nyingine zote:',
	'app.create_report.select_religion_options_lbl': 'Chagua angalau Dini moja ili kulinganisha na nyingine zote:',
	'app.create_report.select_primary_area_work_options_lbl': 'Chagua angalau Eneo la Msingi moja la Kazi ili kutofautisha na mengine yote:',
	'app.create_report.selected_relationship_status_lbl': 'Hali Iliyochaguliwa ya Uhusiano',
	'app.create_report.selected_religion_lbl': 'Mwelekeo wa Kidini Uliochaguliwa',
	'app.create_report.selected_primary_area_work_lbl': 'Eneo la Msingi Lililochaguliwa',
	'app.create_report.individual_report': 'Ripoti ya Mtu binafsi',
	'app.create_report.longitudinal_individual_report': 'Ripoti ya Mabadiliko ya Mtu Binafsi',
	'app.create_report.combined_report': 'Ripoti ya Washirika',
	'app.create_report.individual': 'Binafsi',
	'app.create_report.partner': 'Mshirika',
	'app.create_report.group': 'Kikundi',
	'app.create_report.select.group': 'Chagua kikundi',
	'app.create_report.partner_change_report': 'Ripoti ya Mabadiliko ya Washirika',
	'app.create_report.email_in_use': 'Barua pepe inatumika',
	'app.create_report.add_new_participant': 'Ongeza mshiriki mpya'
}
