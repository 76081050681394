import { VirtualAccountMessages } from '../types';

export const virtualAccountHI: VirtualAccountMessages = {
  'app.virtual_account.title': 'संस्थान द्वारा वर्चुअल खाता',
  'app.virtual_account.add_interactions': 'इंटरैक्शन जोड़ें',
  'app.virtual_account.add_interactions_to_organization': 'संगठन के लिए इंटरैक्शन जोड़ें:',
  'app.virtual_account.interactions_placeholder': 'पुनरावृत्तियों की संख्या टाइप करें',
  'app.virtual_account.successful_message': 'वर्चुअल खाता सफलतापूर्वक अपडेट किया गया।',
  'app.virtual_account.day': 'दिन',
  'app.virtual_account.interaction_credit': 'इंटरेक्शन क्रेडिट',
  'app.virtual_account.interaction_debit': 'इंटरेक्शन डेबिट',
  'app.virtual_account.balance': 'बैलेंस',
  'app.virtual_account.total_balance_month': 'महिनें के लिए कुल बैलेंस',
  'app.virtual_account.interactions_available': 'उपलब्ध इंटरैक्शन',
};
