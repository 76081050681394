import { create } from 'zustand';
import { serviceUrl, successfulCode } from '../config/constants';
import { fetchGetMethod, fetchPostMethod, getResponseObject } from '../rpc/api';
import { PageMessages } from '../locales/types';

interface State {
    getQrCode: (token: string) => void;
    validateQrCode: (token: string, code: string) => void;
    loadingQrCode: boolean;
    qrCode?: string;
    qrCodeKey?: string;
    errorQrCode?: string;
    successMessage?: keyof PageMessages;
    clearError: () => void;
}

interface QrCodeResponse {
    authQrCode: string;
    authKey: string;
}

export const useUserStore = create<State>((set, get) => ({
    loadingQrCode: false,
    successMessage: undefined,
    getQrCode: async (token: string) => {
        set(() => ({ loadingQrCode: true, authQrCode: undefined, authKey: undefined }));
        const response = fetchGetMethod(`${serviceUrl}/api/user/getQrCode`, token);
        const responseData = await getResponseObject<QrCodeResponse>(response, 'app.login.auth.invalid_code');

        set(() => ({ loadingQrCode: false, qrCode: responseData.body?.authQrCode, qrCodeKey: responseData.body?.authKey }));
    }, validateQrCode: async (token: string, code: string) => {
        set(() => ({ loadingQrCode: true }));
        const { qrCodeKey } = get();
        try {
            const response = fetchPostMethod(`${serviceUrl}/api/user/validateQrCode`, token, { key: qrCodeKey, code });
            const responseData = await getResponseObject<boolean>(response, 'app.login.auth.invalid_code');

            set(() => ({ loadingQrCode: false }));
            if (responseData.status === successfulCode) {
                set({ successMessage: 'app.login.auth.validate_success' });
                setTimeout(() => {
                    set(() => ({ successMessage: undefined }));
                }, 1);
            } else {
                set(() => ({ errorQrCode: 'app.login.auth.invalid_code' }));
            }
        } catch (error: any) {
            set(() => ({ errorQrCode: 'app.login.auth.invalid_code', loadingQrCode: false }));
        }
    }, clearError: () => {
        set(() => ({ errorQrCode: undefined }))
    }
}))