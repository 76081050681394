import { NoInfoMessages } from '../types';

export const noInfoSW: NoInfoMessages = {
	'app.no.info.title.not_found': 'UKURASA HAUJAPATIKANA',
	'app.no.info.title.no_data': 'HAKUNA DATA IMEPATIKANA',
	'app.no.info.subtitle.not_found': 'Inaonekana kama umepotea!',
	'app.no.info.subtitle.no_data': 'Hakikisha vigezo vya utafutaji wako ni sahihi, na kama kuna hitilafu wasiliana na info@thebevi.com.',
	'app.no.info.body.not_found': 'Ukurasa unaoutafuta haupo au umehamishwa.',
	'app.no.info.body.no_data': 'Angalia sehemu unazochagua na ikiwa kila kitu kiko sawa, wasiliana na msimamizi wa akaunti',
	'app.no.info.title.not_authorized': 'UKURASA HAUJAIDHINISHWA',
	'app.no.info.subtitle.not_authorized': 'Inaonekana kama unajaribu kufikia ukurasa ambao huna ruhusa',
	'app.no.info.body.not_authorized': 'Ikiwa unataka kuifikia, tafadhali, wasiliana na msimamizi wa akaunti.',
	'app.no.info.title.maintenance': 'MFUMO UNAREKEBISHWA',
	'app.no.info.subtitle.maintenance': 'Inaonekana kama mfumo uko chini ya marekebisho',
	'app.no.info.body.maintenance': 'Tafadhali, subiri dakika chache na ujaribu tena.',
}
