import React, { useCallback, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TwoFactorAuthenticator } from "./TwoFactorAuthenticator";
import { useUserStore } from "../../redux/user.store";
import { AppActions, appLogic, AppProps } from "../../redux/appLogic";
import { useValues, useActions } from 'kea';
import { notification } from "antd";
import { GetFormattedMessage } from "../../utils/htmlHelper";
import { isNil } from "lodash";

type Props = RouteComponentProps;

export const TwoFactorAuthenticatorContainer = withRouter((props: Props): JSX.Element => {
    const { getQrCode, loadingQrCode, errorQrCode, qrCode, successMessage, validateQrCode, clearError } = useUserStore();
    const { token }: AppProps = useValues(appLogic);
    const { refreshToken }: AppActions = useActions(appLogic);
    const messageSuccess = successMessage ? GetFormattedMessage(successMessage) : undefined;

    useEffect(() => {
        getQrCode(token);
    }, [getQrCode, token])

    useEffect(() => {
        if (messageSuccess != null) {
            notification['success']({ message: messageSuccess, key: 'qrCodeCallAfterSuccess', duration: 2 });
            refreshToken();
            setTimeout(() => {
                props.history.push('/');
            }, 2000)
        }
    }, [props.history, refreshToken, messageSuccess]);

    useEffect(() => {
        if(!isNil(errorQrCode)) {
            setTimeout(() => clearError(), 2000);
        }
    }, [errorQrCode])

    const callValidateQrCode = useCallback((code: string) => {
        validateQrCode(token, code);
    }, [token, validateQrCode])

    return <TwoFactorAuthenticator loading={loadingQrCode} errorKey={errorQrCode} qrCode={qrCode}
        validateQrCode={callValidateQrCode} />
})
