import { LoginPageMessages } from '../types';

export const loginPageZHCN: LoginPageMessages = {
    'app.login.text_welcome': '歡迎訪問 the Beliefs, Events, and Values Inventory (BEVI)',
    'app.login.text_information': '若想訪問BEVI網站，您必須受到有BEVI使用權的大學、機構或管理者的邀請',
    'app.login.text_login_requirement': '請輸入您的用戶名和密碼。',
    'app.login.lbl_username': '用戶名:',
    'app.login.lbl_password': '密碼:',
    'app.login.btn_submit': '登錄',
    'app.login.lbl_new_password': '新密碼',
    'app.login.lbl_new_password_confirmation': '再次輸入新密碼',
    'app.login.btn_submit_new_password': '重設密碼',
    'app.login.placeholder.login': '電子郵箱',
    'app.login.placeholder.password': '您的密碼',
    'app.login.invalid_credentials': '電子郵箱或密碼錯誤。請重試。或點擊以下“密碼設定或重設”按鈕，重新設置你的帳號。',
    'app.login.forgot_password.invalid_credentials': '電子郵箱錯誤。請重試。',
    'app.login.forgot_password': '密碼設定或重設',
    'app.login.forgot_password_confirmation_title': '重設密碼',
    'app.login.forgot_password_confirmation_body': '系統已給您剛才登錄的電子郵箱發了郵件。請查收郵件並按其指示重設密碼。',
    'app.login.btn_submit_forgot_password': '密碼重設（輸入電子郵箱並點擊）',
    'app.login.passwords_dont_match': '密碼不一致。 ',
    'app.login.change_password.expired_confirmation_title': '無法顯示該頁面。',
    'app.login.change_password.expired_confirmation_body': '頁面權限已過期。再次請求訪問或重新登錄BEVI。',
    'app.login.change_password.success_confirmation_title': '成功完成了再設定。',
    'app.login.change_password.success_confirmation_body': '成功設置了新密碼。下次請使用新密碼登入。',
    'app.login.first_access.title': '請在密碼變更後使用。',
    "app.login.auth.qrcode": "使用Google身份验证器扫描此二维码",
    'app.login.auth.type': '输入Google身份验证器代码进行验证',
    "app.login.auth.backLogin": "返回登录",
    "app.login.auth.invalid_code": "无效代码",
    'app.login.auth.validate_success': '验证器验证成功'
}
