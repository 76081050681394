import { LoginPageMessages } from '../types';

export const loginPageHI: LoginPageMessages = {
    'app.login.text_welcome': 'विश्वास, घटनाएँ, और मूल्य सूची (BEVI) में आपका स्वागत है',
    'app.login.text_information': `इस साइट तक पहुँचने के लिए, आपको एक BEVI-लाइसेंस प्राप्त संस्था, संगठन, या प्रशासक द्वारा आमंत्रित किया जाना चाहिए।`,
    'app.login.text_login_requirement': `कृपया आवश्यक यूज़रनेम और पासवर्ड प्रदान करें (जो आपको प्रदान किया गया था)।`,
    'app.login.lbl_username': 'यूज़रनेम',
    'app.login.lbl_password': 'पासवर्ड:',
    'app.login.btn_submit': 'लॉगिन',
    'app.login.lbl_new_password': 'नया पासवर्ड',
    'app.login.lbl_new_password_confirmation': 'पासवर्ड दोबारा दर्ज करें',
    'app.login.btn_submit_new_password': 'पासवर्ड रीसेट करें',
    'app.login.placeholder.login': 'email@example.com',
    'app.login.placeholder.password': 'आपका पासवर्ड',
    'app.login.invalid_credentials': 'ओह नहीं! आपके प्रमाणपत्र हमारी रिकॉर्ड से मेल नहीं खाते। कृपया, फिर से प्रयास करें या नीचे Forgot Password पर क्लिक करके अपना खाता रीसेट करें।',
    'app.login.forgot_password.invalid_credentials': 'ईमेल मान्य नहीं है। कृपया, फिर से प्रयास करें।',
    'app.login.forgot_password': 'पासवर्ड भूल गए?',
    'app.login.forgot_password_confirmation_title': 'पासवर्ड रीसेट',
    'app.login.forgot_password_confirmation_body': 'आपके द्वारा प्रदान किए गए ईमेल पते पर एक ईमेल भेजा गया है। अपने पासवर्ड को रीसेट करने के लिए ईमेल में दिए गए निर्देशों का पालन करें।',
    'app.login.btn_submit_forgot_password': 'पासवर्ड पुनः प्राप्त करें',
    'app.login.passwords_dont_match': 'पासवर्ड मेल नहीं खाते।',
    'app.login.change_password.expired_confirmation_title': 'समाप्त पृष्ठ',
    'app.login.change_password.expired_confirmation_body': 'इस पृष्ठ तक पहुँचने की आपकी अनुमति समाप्त हो गई है। कृपया फिर से एक्सेस का अनुरोध करें या BEVI तक पहुँचने के लिए लॉगिन करें।',
    'app.login.change_password.success_confirmation_title': 'पासवर्ड अपडेट हो गया',
    'app.login.change_password.success_confirmation_body': 'आपका पासवर्ड सफलतापूर्वक बदल दिया गया है। होम पेज पर लॉगिन करने के लिए अपने नए पासवर्ड का उपयोग करें, जहाँ आपको पुनर्निर्देशित किया जाएगा।',
    'app.login.first_access.title': 'कृपया, सिस्टम का उपयोग करने से पहले अपना पासवर्ड बदलें।',
    'app.login.auth.qrcode': 'इस QR कोड को Google Authenticator से स्कैन करें',
    'app.login.auth.type': 'मान्यता के लिए Google Authenticator कोड दर्ज करें',
    'app.login.auth.backLogin': 'लॉगिन पर वापस जाएं',
    'app.login.auth.invalid_code': 'अमान्य कोड',
    'app.login.auth.validate_success': 'Authenticator मान्यता सफल'
}
