import { InstitutionMessages } from '../types';

export const institutionHI: InstitutionMessages = {
  'app.institutions.title': 'संस्थान प्रबंधन',
  'app.institutions.new': 'नई संस्था',
  'app.institutions.search': 'खोजें',
  'app.institutions.list.name': 'नाम',
  'app.institutions.list.edit': 'संपादित करें',
  'app.institutions.list.action': 'क्रियाएँ',
  'app.institutions.add.title': 'नई संस्था',
  'app.institutions.edit.title': 'संस्थान संपादित करें',
  'app.institutions.add.successful_message': 'संस्थान को सफलतापूर्वक अपडेट किया गया।',
  'app.institutions.add.error_message': 'कुछ गलत हो गया, कृपया कुछ मिनटों में पुनः प्रयास करें।',
  'app.institutions.add.new_name': 'नया नाम',
  'app.institutions.add.button_save': 'सहेजें',
  'app.institutions.add.hide_demographics_questions': 'जनसांख्यिकी प्रश्न छिपाएँ',
  'app.institutions.add.demographics_extra_questions': 'जनसांख्यिकी अतिरिक्त प्रश्न',
  'app.institutions.hide_demographics_questions.successful_message': 'जनसांख्यिकी प्रश्न सफलतापूर्वक अपडेट किए गए।',
  'app.institutions.demographics_questions.question': 'प्रश्न',
  'app.institutions.demographics_questions.type': 'प्रकार',
  'app.institutions.demographics_questions.type_0': 'डिफ़ॉल्ट',
  'app.institutions.demographics_questions.type_1': 'पाठ',
  'app.institutions.demographics_questions.type_2': 'एकल विकल्प',
  'app.institutions.demographics_questions.add.title': 'नया अतिरिक्त प्रश्न',
  'app.institutions.demographics_questions.edit.title': 'अतिरिक्त प्रश्न संपादित करें',
  'app.institutions.demographics_questions.title': 'शीर्षक',
  'app.institutions.demographics_questions.options': 'विकल्प',
  'app.institutions.demographics_questions.description': 'विवरण',
  'app.institutions.demographics_questions.delete': 'क्या आप इस आइटम को हटाना चाहते हैं?',
  'app.institutions.chat.successful_message': 'कुछ ही मिनटों में, सभी उपयोगकर्ता AI चैट का उपयोग करने में सक्षम होंगे।',
  'app.institutions.chat.add_chat_all': 'सभी उपयोगकर्ताओं के लिए चैट जोड़ें',
  'app.institutions.chat.question_add_chat_all': 'क्या आप इस संस्था के सभी उपयोगकर्ताओं के लिए चैट शामिल करना चाहते हैं?'
}
