import {
    ScaleQuestionsConfirmationMessages, ScaleQuestionsMessages, ScaleQuestionsPageMessages
} from '../types';

const scaleQuestionConfirmation: ScaleQuestionsConfirmationMessages = {
    'app.questions.confirmation_title': 'अनुत्तरित प्रश्न',
    'app.questions.confirmation':
        'क्या आप वाकई बिना सभी प्रश्नों का उत्तर दिए जारी रखना चाहते हैं? आप बाद में इन प्रश्नों पर वापस नहीं आ सकेंगे।',
}

const scaleQuestions: ScaleQuestionsMessages = {
    'app.question.strongly_agree': 'पूरी तरह सहमत',
    'app.question.agree': 'सहमत',
    'app.question.disagree': 'असहमत',
    'app.question.strongly_disagree': 'पूरी तरह असहमत',
    'app.question.q1': 'कभी-कभी मुझे यह नहीं पता होता कि मुझे किस दिशा में जाना है।',
    'app.question.q2': 'जब मैं बड़ा हो रहा था तो लोग मेरा मजाक उड़ाते थे।',
    'app.question.q3': 'कभी-कभी मैं अपनी तुलना दूसरों से करता हूँ।',
    'app.question.q4': 'युवा लोगों के लिए यौन रूप से प्रयोग करना स्वस्थ होता है।',
    'app.question.q5': 'मेरे एक या अधिक परिवार के सदस्यों के साथ मेरा बहुत विवाद हुआ है।',
    'app.question.q6': 'सारी दुनिया में अकेले होने से मैं डर जाऊंगा।',
    'app.question.q7': 'काश मेरी माँ और/या पिता ने चीजें अलग ढंग से की होतीं।',
    'app.question.q8': 'महिलाएं आम तौर पर अपने मासिक धर्म के समय अधिक भावुक होती हैं।',
    'app.question.q9': 'हमें उन संस्कृतियों को समझने की कोशिश करनी चाहिए जो हमारी अपनी संस्कृति से भिन्न हैं।',
    'app.question.q10': 'मेरी माँ मेरी सबसे अच्छी दोस्त है/थी।',
    'app.question.q11': 'हम अंततः स्वीकार करेंगे कि पुरुष और महिलाएं बस अलग हैं।',
    'app.question.q12': 'महिलाओं की जगह घर में है।',
    'app.question.q13': 'इस दुनिया की सुंदरता से मैं अक्सर अभिभूत हो जाता हूँ।',
    'app.question.q14': 'कुछ लोगों के जीन अधिक मजबूत होते हैं।',
    'app.question.q15': 'पूर्वाग्रह हर जगह है और हमें इसे दूर करना चाहिए।',
    'app.question.q16': 'मैंने एक दर्दनाक पहचान संकट का सामना किया है।',
    'app.question.q17': 'मैं साल में कई बार रोता हूँ।',
    'app.question.q18': 'मैं हमेशा अपनी माँ या पिता पर अच्छे सलाह के लिए भरोसा कर सकता हूँ/था।',
    'app.question.q19': 'हमारे मस्तिष्क का अवचेतन हिस्सा हमारी भावनाओं और व्यवहार को प्रभावित कर सकता है।',
    'app.question.q20': 'विश्व की घटनाओं के बारे में सूचित रहना महत्वपूर्ण है।',
    'app.question.q21': 'एक पुरुष को पुरुष की तरह व्यवहार करना चाहिए।',
    'app.question.q22': 'भगवान ने मुझे जैसा बनाया है, वैसा ही बनाया है।',
    'app.question.q23': 'आखिर में, लोग तय करते हैं कि वे खुश रहना चाहते हैं या नहीं।',
    'app.question.q24': 'मुझे पृथ्वी से गहरा आध्यात्मिक संबंध महसूस होता है।',
    'app.question.q25': 'मेरा बचपन बहुत अद्भुत था।',
    'app.question.q26': 'मैं हमेशा अपने बारे में अच्छा महसूस नहीं करता।',
    'app.question.q27': 'भगवान के अस्तित्व पर संदेह करना गलत है।',
    'app.question.q28': 'आप अपनी समस्याओं के लिए किसी और को दोष नहीं दे सकते।',
    'app.question.q29': 'महिलाएं भावनात्मक रूप से पुरुषों की तुलना में अधिक निर्भर होती हैं।',
    'app.question.q30': 'मुझे गहरी बातचीत करना पसंद है जो घंटों तक चलती है।',
    'app.question.q31': 'हमें न्यूनतम मजदूरी बढ़ानी चाहिए।',
    'app.question.q32': 'जब मैं बड़ा हो रहा था तब मेरे पिता के साथ मेरे अच्छे और स्वस्थ संबंध थे।',
    'app.question.q33': 'एड्स भगवान के गुस्से का संकेत हो सकता है।',
    'app.question.q34': 'बहुत से लोग मुफ्त सहायता की तलाश में हैं।',
    'app.question.q35': 'हमारे स्कूलों में यौन शिक्षा सिखाई जानी चाहिए।',
    'app.question.q36': 'जब मैं बड़ा हो रहा था तो मैंने बहुत गुस्सा या आक्रामकता देखी।',
    'app.question.q37': 'मैं अपने जीवन में बेहतर संतुलन पाना चाहता हूँ।',
    'app.question.q38': 'मैं अपनी भावनाओं को बहुत गंभीरता से लेता हूँ।',
    'app.question.q39': 'महिलाएं भी मजबूत प्रतिस्पर्धी हो सकती हैं।',
    'app.question.q40': 'हमें अपनी पशु प्रवृत्ति से इनकार नहीं करना चाहिए।',
    'app.question.q41': 'मेरे अतीत की कुछ घटनाओं के बारे में सोचना शर्मनाक है।',
    'app.question.q42': 'मेरे रिश्तों में बहुत विवाद हुआ है।',
    'app.question.q43': 'सामाजिक मुद्दों पर मैं उदार विचारों वाला हूँ।',
    'app.question.q44': 'मैं जीवन के अर्थ के बारे में बहुत सोचता हूँ।',
    'app.question.q45': 'कभी-कभी मुझे बोरियत महसूस होती है।',
    'app.question.q46': 'युवा लोगों के लिए शराब और मनोरंजक दवाओं के साथ प्रयोग करना सामान्य है।',
    'app.question.q47': 'महिलाओं को अपने निर्णय लेने में बहुत परेशानी होती है।',
    'app.question.q48': 'अन्य संस्कृतियों का ज्ञान मुझे अपनी संस्कृति को समझने में मदद करता है।',
    'app.question.q49': 'स्वर्ग का केवल एक रास्ता है।',
    'app.question.q50': 'लोग आमतौर पर असफल होते हैं क्योंकि वे पर्याप्त प्रयास नहीं करते।',
    'app.question.q51': 'मुझे अपने माता-पिता से दूरी बनानी पड़ी है।',
    'app.question.q52': 'चाहे आप इसे पसंद करें या नहीं, लड़के लड़के ही रहेंगे।',
    'app.question.q53': 'मनुष्य लाखों वर्षों में विकसित हुआ है।',
    'app.question.q54': 'मेरे परिवार के एक या अधिक व्यक्ति शारीरिक, यौन या भावनात्मक रूप से शोषित हुए थे।',
    'app.question.q55': 'मुझे गर्मजोशी और स्नेह की वास्तविक जरूरतें हैं।',
    'app.question.q56': 'हमें बच्चों के लिए प्रारंभिक शिक्षा कार्यक्रमों पर अधिक पैसा खर्च करना चाहिए।',
    'app.question.q57': 'मैं बहुत भावुक व्यक्ति हूँ।',
    'app.question.q58': 'मैं ट्रेड यूनियनों के काम का समर्थन करता हूँ।',
    'app.question.q59': 'मैं हमारे पर्यावरण के बारे में चिंतित हूँ।',
    'app.question.q60': 'अस्वीकृति से दर्द होता है।',
    'app.question.q61': 'मेरे माता-पिता के बारे में कहने के लिए मेरे पास कुछ भी नकारात्मक नहीं है।',
    'app.question.q62': 'मैंने सोचा है कि मैं कौन हूँ और कहाँ जा रहा हूँ।',
    'app.question.q63': 'कभी-कभी मेरी भावनाएं मुझ पर हावी हो जाती हैं।',
    'app.question.q64': 'महिलाएं पुरुषों की तुलना में अधिक गपशप करती हैं।',
    'app.question.q65': 'बिना धर्म के शांति नहीं हो सकती।',
    'app.question.q66': 'यह नकारा नहीं जा सकता कि पुरुष महिलाओं की तुलना में शारीरिक रूप से मजबूत होते हैं।',
    'app.question.q67': 'एक समाज के रूप में हम बहुत अधिक उपभोक्तावादी हैं।',
    'app.question.q68': 'मेरे परिवार को पैसों की बहुत समस्याएँ थीं।',
    'app.question.q69': 'मैं हमेशा खुद को बेहतर समझने की कोशिश करता हूँ।',
    'app.question.q70': 'यह स्वाभाविक ही है कि मजबूत ही जीवित रहेंगे।',
    'app.question.q71': 'पुरुष तर्कसंगत होते हैं महिलाओं की तुलना में।',
    'app.question.q72': 'अतीत की दर्दनाक भावनाओं को काम में लाना सहायक होता है।',
    'app.question.q73': 'कभी-कभी पुरुष भी महिलाओं की तरह कमजोर महसूस करते हैं।',
    'app.question.q74': 'लड़के को गुलाबी रंग पहनाना गलत होगा।',
    'app.question.q75': 'मुझे रिश्तों के बारे में फिल्में देखना पसंद है।',
    'app.question.q76': 'जब मैं बड़ा हो रहा था तब मेरी माँ के साथ मेरा अच्छा और स्वस्थ संबंध था।',
    'app.question.q77': 'मैं हमारे ग्रह के स्वास्थ्य के बारे में चिंतित हूँ।',
    'app.question.q78': 'मेरा शरीर मेरे द्वारा महसूस की गई चीजों के प्रति बहुत संवेदनशील है।',
    'app.question.q79': 'मुझे यह स्वीकार करना होगा कि मैं अपने माता-पिता जैसा ही हूँ।',
    'app.question.q80': 'महिलाएं पुरुषों की तुलना में अधिक भावुक होती हैं।',
    'app.question.q81': 'मेरे पास मजबूत धार्मिक विश्वास हैं।',
    'app.question.q82': 'मैं वैसे ही हूं जैसा मैं पैदा हुआ था।',
    'app.question.q83': 'मेरे माता-पिता के रिश्ते में बहुत संघर्ष हुआ।',
    'app.question.q84': 'मुझे यह सोचना पसंद है कि मैं कौन हूं।',
    'app.question.q85': 'महिलाओं ने पिछले कुछ दशकों में अद्भुत प्रगति की है।',
    'app.question.q86': 'मेरे पिता मेरे नायक हैं।',
    'app.question.q87': 'मुझे अपने जीवन के साथ क्या करना है, इसे समझने में कठिनाई हुई है।',
    'app.question.q88': 'कभी-कभी मैं चीजों को बहुत गहराई से महसूस करता हूं।',
    'app.question.q89': 'हमें पृथ्वी को अपनी माँ के रूप में सोचना चाहिए।',
    'app.question.q90': 'मैंने अपने जीवन में अकेलापन महसूस किया है।',
    'app.question.q91': 'कई प्रोफेसर अपने विचारों में बहुत उदार हैं।',
    'app.question.q92': 'उपचार के लिए एक उच्च शक्ति से ही वास्तविक आशा आती है।',
    'app.question.q93': 'कभी-कभी मैं जरूरतमंद और असहाय महसूस करता हूं।',
    'app.question.q94': 'हमें अपने समाज में अल्पसंख्यक समूहों की मदद के लिए और अधिक करना चाहिए।',
    'app.question.q95': 'हर कोई एक से अधिक भाषा सीखने से लाभान्वित हो सकता है।',
    'app.question.q96': 'हालांकि हम उनसे उम्मीद करते हैं, पुरुष वास्तव में विवाह में वफादार बनने के लिए नहीं बने हैं।',
    'app.question.q97': 'मुझे समझ नहीं आता कि कुछ चीजें क्यों होती हैं।',
    'app.question.q98': 'मेरे पिता मेरे से भावनात्मक रूप से दूर हैं/थे।',
    'app.question.q99': 'हमें अपने राष्ट्र का नेतृत्व करने के लिए एक महिला का चुनाव करना चाहिए।',
    'app.question.q100': 'आखिर में, हम सभी अपने जीवन के प्रकार का चुनाव करते हैं।',
    'app.question.q101': 'हमारी सरकार की न्यायिक शाखा बहुत उदार है।',
    'app.question.q102': 'मैं गंभीरता से लिया जाना चाहता हूं।',
    'app.question.q103': 'हमें उन लोगों की मदद के लिए और अधिक करना चाहिए जो कम भाग्यशाली हैं।',
    'app.question.q104': 'मुझे भावनाओं के प्रदर्शन की परवाह नहीं है।',
    'app.question.q105': 'बड़ा होते हुए मुझे भावनात्मक नुकसान हुआ था।',
    'app.question.q106': 'पोर्नोग्राफी महिलाओं को अपमानित करती है।',
    'app.question.q107': 'मैं दुनिया में सभी अन्याय के बारे में गुस्से में हूं।',
    'app.question.q108': 'बड़ी सरकारी योजनाएं अच्छे से अधिक नुकसान पहुंचाती हैं।',
    'app.question.q109': 'मेरी भावनाएं अतीत में आहत हुई हैं।',
    'app.question.q110': 'मुझे आत्म-सहायता पुस्तकें पढ़ना पसंद है।',
    'app.question.q111': 'मेरी माँ और/या पिता के पास भावनात्मक समस्याएं हैं/थी।',
    'app.question.q112': 'मुझे अन्य संस्कृतियों के बारे में सीखना पसंद है।',
    'app.question.q113': 'हमें अपनी विश्वसनीय परंपराओं को संरक्षित करना चाहिए।',
    'app.question.q114': 'महिलाएं सफल होने के लिए पर्याप्त महत्वाकांक्षी नहीं हैं।',
    'app.question.q115': 'कभी-कभी मैं दूसरों से गुस्सा हूं।',
    'app.question.q116': 'कोई वास्तव में आपको चोट नहीं पहुंचा सकता जब तक आप उन्हें अनुमति नहीं देते।',
    'app.question.q117': 'मुझे अपने जीवन में किसी की जरूरत है जो मेरी भावनाओं को सुन सके।',
    'app.question.q118': 'अगर आप बस अधिक मेहनत करें तो आप लगभग किसी भी समस्या को पार कर सकते हैं।',
    'app.question.q119': 'प्रार्थना कुछ भी हासिल नहीं करती।',
    'app.question.q120': 'मैं अक्सर अपने माता-पिता के मुद्दों या संघर्षों के बीच में था।',
    'app.question.q121': 'हमारे देश में अमीर और गरीब के बीच बहुत बड़ा अंतर है।',
    'app.question.q122': 'मैं जो महसूस करता हूं उसे मैं पूरी तरह से समझ नहीं पाता।',
    'app.question.q123': 'लोग वास्तव में नहीं बदलते हैं।',
    'app.question.q124': 'मुझे कभी महसूस नहीं हुआ कि मैं अपने परिवार के लिए पर्याप्त अच्छा था।',
    'app.question.q125': 'पुरुष प्राकृतिक रूप से महिलाओं की तुलना में अधिक प्रतिस्पर्धी होते हैं।',
    'app.question.q126': 'हमें पृथ्वी के प्राकृतिक संसाधनों की रक्षा के लिए और अधिक करना चाहिए।',
    'app.question.q127': 'मेरे जीवन में गंभीर धन संबंधी समस्याएं रही हैं।',
    'app.question.q128': 'मुझे आलोचना पसंद नहीं है।',
    'app.question.q129': 'मेरी माँ या पिता और मैं करीब थे/हैं।',
    'app.question.q130': 'मेरे पास समस्याएं हैं जिन पर मुझे काम करने की जरूरत है।',
    'app.question.q131': 'प्रार्थना पारंपरिक चिकित्सा से अधिक उपचारात्मक है।',
    'app.question.q132': 'बड़ा होते हुए मुझे दुर्व्यवहार/उपेक्षा का सामना करना पड़ा था।',
    'app.question.q133': 'हमें ड्रग्स के खिलाफ युद्ध में अधिक कड़ी लड़ाई लड़नी चाहिए।',
    'app.question.q134': 'मैं महिलाओं के समान अधिकारों का दृढ़ता से समर्थन करता हूं।',
    'app.question.q135': 'मेरे पिता ने मुझे समझा और स्वीकार किया।',
    'app.question.q136': 'शिक्षा एक अधिक जागरूक समाज की कुंजी है।',
    'app.question.q137': 'एक पुरुष की भूमिका मजबूत होना है।',
    'app.question.q138': 'हमें जमीन की रक्षा करनी चाहिए चाहे वह किसके पास हो।',
    'app.question.q139': 'मेरे माता-पिता ने मुझसे बहुत अधिक की मांग की।',
    'app.question.q140': 'यदि महिलाओं के पास अधिक आर्थिक और राजनीतिक शक्ति होती, तो हम सभी लाभान्वित होते।',
    'app.question.q141': 'जब अन्य लोग रोते हैं तो मैं कभी-कभी रोता हूं।',
    'app.question.q142': 'कुछ महिलाएं उनके कपड़ों के कारण परेशान किए जाने का अनुरोध करती हैं।',
    'app.question.q143': 'यदि आप कुछ अच्छा नहीं कह सकते, तो कुछ भी न कहें।',
    'app.question.q144': 'आप अतीत को नहीं बदल सकते इसलिए उस पर विचार करने का कोई मतलब नहीं है।',
    'app.question.q145': 'जब जीवन आपको गिराता है तो आपको उठना चाहिए और आगे बढ़ना चाहिए।',
    'app.question.q146': 'अस्वीकार करना अक्सर आपको मजबूत बनाता है।',
    'app.question.q147': 'कुछ लोग दुनिया का बहुत अधिक विश्लेषण करते हैं।',
    'app.question.q148': 'जब भी मुझे बुरा महसूस होता है, मैं सकारात्मक विचार करने की कोशिश करता हूं।',
    'app.question.q149': 'हमेशा कमजोरियों की तुलना में ताकत पर ध्यान केंद्रित करना बेहतर होता है।',
    'app.question.q150': 'जीवन में आगे बढ़ने का सबसे अच्छा तरीका एक खुशहाल रवैया बनाए रखना है।',
    'app.question.q151': 'जो हो चुका है वह हो चुका है, इसलिए माफ करें और भूल जाएं।',
    'app.question.q152': 'आप वास्तव में नहीं कह सकते कि एक दृष्टिकोण किसी अन्य से बेहतर है।',
    'app.question.q153': 'मुझे लगता है कि लोगों को जो उनके पास है उसके लिए आभारी होना चाहिए और शिकायत करना बंद करना चाहिए।',
    'app.question.q154': 'कुछ नंबर दूसरों की तुलना में अधिक बदकिस्मत होते हैं।',
    'app.question.q155': 'गाली-गलौज करने का कोई कारण नहीं है।',
    'app.question.q156': 'बहुत से लोग अपनी जिम्मेदारियों को पूरा नहीं करते।',
    'app.question.q157': 'साइकेश भविष्य की भविष्यवाणी कर सकते हैं।',
    'app.question.q158': 'चीजों को सरल बनाने की कोशिश करना हमेशा सबसे अच्छा होता है।',
    'app.question.q159': 'आप दो विशेषज्ञों को किसी भी चीज पर सहमत नहीं करा सकते।',
    'app.question.q160': 'यदि आप नियमों का पालन करते हैं तो आप ठीक से मिल जाते हैं।',
    'app.question.q161': 'यह अच्छा होगा यदि हर कोई मुझे पसंद करे या कम से कम सम्मान करे।',
    'app.question.q162': 'हमें विभिन्न सांस्कृतिक प्रथाओं के प्रति अधिक सहिष्णु होना चाहिए।',
    'app.question.q163': 'मैं एक स्वतंत्र आत्मा हूं।',
    'app.question.q164': 'मैं ज्योतिष के शक्ति में विश्वास करता हूं।',
    'app.question.q165': 'मैं उन लोगों के लिए दुखी महसूस करता हूं जो गरीबी में जीते हैं।',
    'app.question.q166': 'मैं अधिकांश अन्य चीजों की तुलना में स्पष्ट तर्क को महत्व देता हूं।',
    'app.question.q167': 'मैं उन लोगों के समूहों के साथ आरामदायक हूं जो मुझसे बहुत अलग हैं।',
    'app.question.q168': 'माता-पिता को अपने बच्चों के लिए साथ रहना चाहिए।',
    'app.question.q169': 'लोगों के लिए यौन कल्पनाएं रखना स्वस्थ है।',
    'app.question.q170': 'निदान लेबल उतने सहायक नहीं हैं।',
    'app.question.q171': 'मैं हमेशा चीजों के उज्जवल पक्ष पर देखता हूं।',
    'app.question.q172': 'अन्य ग्रहों से प्राणी ने हमारी प्रजाति की मदद की है।',
    'app.question.q173': 'पृथ्वी अरबों साल पुरानी है।',
    'app.question.q174': 'कमजोरी एक गुण हो सकती है।',
    'app.question.q175': 'कोई निराशावादी पसंद नहीं करता।',
    'app.question.q176': 'मुझे भावनाओं के प्रदर्शन से परेशानी होती है।',
    'app.question.q177': 'जब मैं बड़ा हो रहा था तब मेरे माता-पिता का बहुत अच्छा रिश्ता था।',
    'app.question.q178': 'हम विभिन्न सांस्कृतिक प्रथाओं के प्रति बहुत अधिक सहिष्णु हैं।',
    'app.question.q179': 'हम गरीबों की मदद के लिए पर्याप्त करते हैं।',
    'app.question.q180': 'लोग पर्यावरण के बारे में बहुत अधिक चिंतित होते हैं।',
    'app.question.q181': 'पूर्वाग्रह वास्तव में अब ज्यादा समस्या नहीं है।',
    'app.question.q182': 'लोग हमेशा बदलते रहते हैं।',
    'app.question.q183': 'स्वर्ग के कई रास्ते हैं।',
    'app.question.q184': 'पुरुष और महिलाएं अधिक समान हैं बजाय भिन्न।',
    'app.question.q185': 'हम वास्तव में अपना जीवन चुनते नहीं हैं।'
};

export const scaleQuestionsPageHI: ScaleQuestionsPageMessages = {
    ...scaleQuestions,
    ...scaleQuestionConfirmation
}
