import { VirtualAccountMessages } from '../types';

export const virtualAccountSW: VirtualAccountMessages = {
	'app.virtual_account.title': 'Akaunti ya kidigitali kwa Taasisi',
	'app.virtual_account.add_interactions': 'Ongeza Ushirikiano',
	'app.virtual_account.add_interactions_to_organization': 'Ongeza ushirikiano wa shirika:',
	'app.virtual_account.interactions_placeholder': 'Andika idadi ya marudio',
	'app.virtual_account.successful_message': 'Akaunti ya kidigitali imesasishwa kwa ufanisi.',
	'app.virtual_account.day': 'Siku',
	'app.virtual_account.interaction_credit': 'Mikopo ya Ushirikiano',
	'app.virtual_account.interaction_debit': 'Malipo ya Ushirikiano',
	'app.virtual_account.balance': 'Salio',
	'app.virtual_account.total_balance_month': 'Jumla ya salio ya mwezi ni ',
	'app.virtual_account.interactions_available': 'ushirikiano unapatikana',
}