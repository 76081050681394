import { ListReportMessages } from '../types';

export const listReportSW: ListReportMessages = {
	'app.listreport.report_area': 'Usimamizi wa Ripoti',
	'app.listreport.new': 'Ripoti Mpya',
	'app.listreport.new_group': 'Kikundi Kipya cha Ripoti',
	'app.listreport.add_group': 'Ongeza Kikundi Kingine',
	'app.listreport.please_add_group_name': 'Tafadhali ongeza jina la kikundi',
	'app.listreport.search': 'Tafuta',
	'app.listreport.name': 'Jina',
	'app.listreport.report_type': 'Aina',
	'app.listreport.created_on': 'Imeundwa',
	'app.listreport.confirm_deletion_header': 'Thibitisha ufutaji',
	'app.listreport.confirm_deletion_body': 'Je, una hakika unataka kuendelea na mchakato wa kufuta ripoti. Ukiendelea, ripoti hii itaondolewa kwenye hifadhidata yetu kabisa.',
	'app.listorganizations.view': 'Tazama',
	'app.listorganizations.edit': 'Hariri',
	'app.listorganizations.delete': 'Futa',
	'app.listreport.report_status': 'Hali ya Ripoti',
	'app.listreport.report_status_pending_approval': 'Inasubiri Idhini ya Mshiriki',
	'app.listreport.report_status_completed': 'Inapatikana',
}
