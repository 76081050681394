import React, { useEffect, useState } from "react";
import { GetFormattedMessage } from "../../utils/htmlHelper";
import { Alert, Input, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { colors } from "../../config/colors";

type Props = {
    qrCode?: string;
    loading?: boolean;
    errorKey?: string;
    validateQrCode: (code: string) => void;
}

export const TwoFactorAuthenticator = ({ qrCode, loading, errorKey, validateQrCode }: Props): JSX.Element => {
    const [code, setCode] = useState<string>();

    useEffect(() => {
        if (code && code.trim() !== '')
            validateQrCode(code);
    }, [code, validateQrCode])

    useEffect(() => {
        if (errorKey != null && loading === false) {
            setCode('');
            if (document.getElementsByClassName('ant-otp-input').length > 0) {
                (document.getElementsByClassName('ant-otp-input')[0] as HTMLInputElement).focus()
            }
        }
    }, [errorKey, loading])

    return <div>
        <div style={styles.divBackground} className='background'>
            <div style={styles.divCard} className='card'>
                <h2 style={styles.h2Title}>
                    {GetFormattedMessage('app.internaltemplate.menu.configureTwoFactorAuthentication')}
                </h2>
                <hr />
                <Spin spinning={loading}>
                    {errorKey &&
                        <div style={styles.errorDiv}>
                            <Alert closable message={<FormattedMessage id={errorKey} />} type='error' showIcon />
                        </div>
                    }
                    {qrCode != null && <>
                        <div style={styles.qrCodeDiv}>
                            <h3>{GetFormattedMessage('app.login.auth.qrcode')}</h3>
                            <img src={qrCode} alt='google authenticator' />
                        </div>
                        <div style={styles.qrCodeDiv}>
                            <h3>{GetFormattedMessage('app.login.auth.type')}</h3>
                            <Input.OTP value={code} onChange={(value) => setCode(value)} formatter={(str) => str.toUpperCase()} length={6} />
                        </div>
                    </>}
                </Spin>
            </div>
        </div>
    </div>
}


const styles = {
    h2Title: {
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 32,
    } as React.CSSProperties,
    divBackground: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    } as React.CSSProperties,
    divCard: {
        width: '100%',
        background: colors.white,
        borderRadius: '15px',
        padding: '16px',
    } as React.CSSProperties,
    errorDiv: {
        flex: 1,
        paddingBottom: 20,
    } as React.CSSProperties,
    qrCodeDiv: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 16,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 16
    } as React.CSSProperties,
}
