import { CreateReportMessages } from '../types';

export const createReportHI: CreateReportMessages = {
    'app.create_report.btn_fake_name_all': 'काल्पनिक नाम सभी प्रतिभागियों के लिए',
    'app.create_report.btn_real_name_all': 'वास्तविक नाम सभी प्रतिभागियों के लिए',
    'app.create_report.btn_fake_name': 'काल्पनिक नाम का उपयोग करें',
    'app.create_report.btn_real_name': 'वास्तविक नाम का उपयोग करें',
    'app.create_report.title': 'रिपोर्ट प्रबंधन',
    'app.create_report.summary_line1': 'BEVI प्रबंधन प्रणाली आपको उन समूह के सभी डेटा पर आधारित रिपोर्ट तैयार करने की सुविधा देती है जिनके साथ आप काम कर रहे हैं। चार संभावित रिपोर्ट प्रकार हैं: <br />' +
        '&nbsp;&nbsp;1) समूह रिपोर्ट (यह रिपोर्ट एक निश्चित समय अवधि पर आधारित होती है जब BEVI का प्रशासन किया गया होता है);<br />' +
        '&nbsp;&nbsp;2) लंबकालिक रिपोर्ट (यह रिपोर्ट समय के साथ पूरी की जाती है, जैसे T1, T2, T3, आदि)  <br />' +
        '&nbsp;&nbsp;3) समूहों के बीच रिपोर्ट (ये रिपोर्टें विभिन्न समूहों की तुलना करने की सुविधा प्रदान करती हैं, चाहे वे एक ही संस्थान या संगठन में हों या विभिन्न संस्थानों या संगठनों के बीच हों)। <br />' +
        '&nbsp;&nbsp;4) समूह के भीतर रिपोर्ट (ये रिपोर्टें समूह के भीतर व्यक्तियों की तुलना BEVI स्केल्स के माध्यम से एक दूसरे से करती हैं, लेकिन यह गुमनाम रूप में होती है) ',
    'app.create_report.summary_line2': 'इन सभी रिपोर्टों को स्वचालित रूप से जनरेट किया जा सकता है (यानी, ये स्वचालित रूप से आपके समूह या समूहों के सभी डेटा का उपयोग करेंगी)। हालांकि, आप प्रत्येक रिपोर्ट प्रकार को पृष्ठभूमि जानकारी के विभिन्न संयोजनों पर ध्यान केंद्रित करने के लिए भी परिष्कृत कर सकते हैं। उदाहरण के लिए, आप एक ऐसी रिपोर्ट तैयार कर सकते हैं जो केवल दो विभिन्न देशों से महिलाओं और पुरुषों की तुलना करे। वास्तव में, इस परिष्करण सुविधा का उपयोग करके आप सैकड़ों विभिन्न संयोजन चुन सकते हैं।',
    'app.create_report.step1': 'चरण 1 - आपकी रिपोर्ट को कॉन्फ़िगर करना',
    'app.create_report.step2': 'चरण 2 - रिपोर्ट समूह जोड़ें',
    'app.create_report.step3': 'चरण 3 - अपनी रिपोर्ट को परिष्कृत करना',
    'app.create_report.step3Individual': 'चरण 3 - रिपोर्ट कौन देख सकता है?',
    'app.create_report.step4': 'चरण 4 - प्रतिभागियों का प्रबंधन',
    'app.create_report.step3_username_column': 'उपयोगकर्ता नाम',
    'app.create_report.step3_aliasname_column': 'उपनाम',
    'app.create_report.step3_action_column': 'ऐक्शन',
    'app.create_report.step3_no_user_title': 'कोई उपयोगकर्ता नहीं मिला',
    'app.create_report.step3_no_user_subtitle': 'कृपया पिछले चरणों के पैरामीटर्स को बदलें और पुनः उपयोगकर्ताओं की खोज करने का प्रयास करें।',
    'app.create_report.step3_user_name_column_title': 'प्रतिभागी',
    'app.create_report.step3_user_exporting_column_title': 'शामिल',
    'app.create_report.requester': 'अनुरोधकर्ता',
    'app.create_report.name': 'रिपोर्ट का नाम',
    'app.create_report.report_type': 'प्रकार',
    'app.create_report.select_subprogram': 'रिपोर्ट उत्पन्न करने के लिए डेटा चुनें',
    'app.create_report.select_subprogram_warning': '(कृपया पिछले फ़ील्ड भरें)',
    'app.create_report.group_report': 'समूह रिपोर्ट',
    'app.create_report.longitudinal_report': 'समूह परिवर्तन रिपोर्ट',
    'app.create_report.comparative_by_group_report': 'समूहों के बीच रिपोर्ट',
    'app.create_report.comparative_by_individual_report': 'समूह के भीतर रिपोर्ट',
    'app.create_report.select_organization': 'एक संगठन चुनें।',
    'app.create_report.select_program': 'एक कार्यक्रम या अनुभव चुनें।',
    'app.create_report.select_sub_program': 'एक BEVI प्रशासन चुनें।',
    'app.create_report.organization_name': 'संगठन',
    'app.create_report.program_name': 'कार्यक्रम या अनुभव',
    'app.create_report.sub_program_name': 'BEVI प्रशासन',
    'app.create_report.actions': 'हटाएँ',
    'app.create_report.action_remove': 'हटाएँ',
    'app.create_report.show_filters': 'मानदंड फ़िल्टर दिखाएँ',
    'app.create_report.hide_filters': 'मानदंड फ़िल्टर छुपाएँ',
    'app.create_report.filter_select_default_option': 'फ़िल्टर करने के लिए चुनें...',
    'app.create_report.filter_select_with_values': 'फ़िल्टरिंग',
    'app.create_report.filter_age': 'उम्र',
    'app.create_report.filter_gender': 'लिंग',
    'app.create_report.filter_years_of_education': 'शिक्षा के वर्ष',
    'app.create_report.filter_primary_country_of_origin': 'उद्गम देश',
    'app.create_report.filter_marital_status': 'संबंध स्थिति',
    'app.create_report.filter_political_orientation': 'राजनीतिक रुझान (रूढ़िवादी से उदारवादी)',
    'app.create_report.filter_ethnic_background': 'जातीय बैकग्राउंड',
    'app.create_report.filter_religious_orientation': 'धार्मिक रुझान',
    'app.create_report.filter_religious_commitment': 'धार्मिक प्रतिबद्धता',
    'app.create_report.filter_academic_rank': 'शैक्षणिक रैंक',
    'app.create_report.filter_time_spent_in_other_countries': 'अन्य देशों में बिताया गया समय',
    'app.create_report.filter_nro_visited_countries': 'यात्रा किए गए देश',
    'app.create_report.filter_fluency': 'धाराप्रवाह',
    'app.create_report.filter_years_of_language_study': 'भाषा अध्ययन के वर्ष',
    'app.create_report.filter_mother_country': 'माँ का देश',
    'app.create_report.filter_father_country': 'पिता का देश',
    'app.create_report.filter_mother_education': 'माँ की शिक्षा',
    'app.create_report.filter_father_education': 'पिता की शिक्षा',
    'app.create_report.filter_minority_group': 'अल्पसंख्यक समूह',
    'app.create_report.filter_personal_interest': 'अंतर्राष्ट्रीय / सांस्कृतिक अनुभवों में रुचि',
    'app.create_report.filter_study_abroad_satisfaction': 'अंतर्राष्ट्रीय/सांस्कृतिक अनुभवों से संतुष्टि',
    'app.create_report.filter_primary_area_of_work': 'मुख्य कार्यक्षेत्र',
    'app.create_report.filter_area_of_occupations': 'व्यवसायों का क्षेत्र',
    'app.create_report.filter_income_level_of_your_home_country': 'आय स्तर',
    'app.create_report.btn_search': 'सर्च',
    'app.create_report.btn_save': 'उत्पन्न',
    'app.create_report.placeholder.name': 'रिपोर्ट का नाम टाइप करें',
    'app.create_report.select.report_type': 'रिपोर्ट का प्रकार चुनें',
    'app.create_report.select_countries_options_lbl': 'सभी अन्य देशों के साथ तुलना करने के लिए कम से कम एक उत्पत्ति देश चुनें:',
    'app.create_report.selected_countries_lbl': 'चयनित देश:',
    'app.create_report.select_ethnics_options_lbl': 'कम से कम एक जातीयता चुनें:',
    'app.create_report.selected_ethnics_lbl': 'चयनित जातीय उन्मुखीकरण/पहचान:',
    'app.create_report.select_number_of_forms_lbl': 'रिपोर्ट में दिखाने के लिए अनुभवात्मक प्रतिबिंब आइटमों पर प्रतिक्रियाओं की अधिकतम संख्या चुनें:',
    'app.create_report.select_relationship_status_options_lbl': 'सभी अन्य से तुलना करने के लिए कम से कम एक संबंध स्थिति चुनें:',
    'app.create_report.select_religion_options_lbl': 'सभी अन्य धर्मों से तुलना करने के लिए कम से कम एक धर्म चुनें:',
    'app.create_report.select_primary_area_work_options_lbl': 'सभी अन्य क्षेत्रों से तुलना करने के लिए कम से कम एक मुख्य कार्यक्षेत्र चुनें:',
    'app.create_report.selected_relationship_status_lbl': 'चयनित संबंध स्थिति',
    'app.create_report.selected_religion_lbl': 'चयनित धार्मिक रुझान',
    'app.create_report.selected_primary_area_work_lbl': 'चयनित मुख्य कार्यक्षेत्र',
    'app.create_report.individual_report': 'व्यक्तिगत रिपोर्ट',
    'app.create_report.longitudinal_individual_report': 'व्यक्तिगत परिवर्तन रिपोर्ट',
    'app.create_report.combined_report': 'साझेदार रिपोर्ट',
    'app.create_report.individual': 'व्यक्तिगत/व्यक्ति',
    'app.create_report.partner': 'साझेदार',
    'app.create_report.group': 'समूह',
    'app.create_report.select.group': 'समूह का चयन करें',
    'app.create_report.partner_change_report': 'साझेदार परिवर्तन रिपोर्ट',
    'app.create_report.email_in_use': 'ईमेल प्रयोग में है',
    'app.create_report.add_new_participant': 'नया प्रतिभागी जोड़ें'
};
