import { useActions, useValues } from 'kea';
import React from 'react';

import { SelectItem } from '../../../components/FormDropdown';
import { UsersActions, usersLogic, UsersProps } from '../../../redux/usersLogic';
import { TokenInfo } from '../../../services/loginService';
import { OrganizationDetails } from '../../../services/organizationService';
import { FilterUsers } from './FilterUsers';

type Props = {
    organizations: OrganizationDetails[];
    onCreateNewUserHandleClick: () => void;
    userInfo: TokenInfo;
    setLastSearch?: React.Dispatch<React.SetStateAction<{
        organizationId: any;
        userNameQuery: any;
        userEmailQuery: any;
        userType?: any
    } | undefined>>
};

export const FilterUsersContainer = ({ organizations, onCreateNewUserHandleClick, setLastSearch, userInfo }: Props): JSX.Element => {

    const { searchUsers }: UsersActions = useActions(usersLogic);
    const { searchUserData }: UsersProps = useValues(usersLogic);

    const onSearchUsersHandleClick = (organizationId: number, userNameQuery: string, userEmailQuery: string, userType?: number) => {
        searchUsers(organizationId, userNameQuery, userEmailQuery, userType);
        console.log(userType)
        if (setLastSearch) {
            setLastSearch({
                organizationId,
                userNameQuery,
                userEmailQuery,
                userType
            });
        }
    }

    const organizationSelectOptions: Array<SelectItem> =
        organizations.map((item: OrganizationDetails) => ({ value: item.id, label: item.name }));
    return (
        <FilterUsers
            searchUserData={searchUserData}
            organizations={organizationSelectOptions}
            searchHandleClick={onSearchUsersHandleClick}
            onCreateUserHandleClick={() => onCreateNewUserHandleClick()}
            userInfo={userInfo}
        />
    );
}
