import { ListOrganizationsMessages } from '../types';

export const listOrganizationsSW: ListOrganizationsMessages = {
	'app.listorganizations.created.on': 'Imeundwa',
	'app.listorganizations.active': 'Inayotumika',
	'app.listorganizations.inactive': 'Isiyotumika',
	'app.listorganizations.new.program': 'Mpango Mpya au Uzoefu',
	'app.listorganizations.new.group': 'Unda na utaje usimamizi upya wa BEVI.',
	'app.listorganizations.view': 'Tazama',
	'app.listorganizations.report': 'Ripoti',
	'app.listorganizations.details': 'Kagua na udhibiti usimamizi wa BEVI.',
	'app.listorganizations.tooltip.invite_by_qr_code': 'Alika watumiaji wapya kujiunga kwenye kikundi hiki kwa Msimbo wa QR.',
	'app.listorganizations.tooltip.sub_program_edit': 'Hariri vigezo vya usimamizi wa BEVI.',
	'app.listorganizations.table.beviname': 'Jina la usimamizi la BEVI',
	'app.listorganizations.table.created_on': 'Imeundwa',
	'app.listorganizations.table.due_date': 'Tarehe ya Kukamilisha',
	'app.listorganizations.table.completed_percentage': 'Imekamilika %',
	'app.listorganizations.table.status': 'Hali',
	'app.listorganizations.table.actions': 'Vitendo',
	'app.listorganizations.select_organization': 'Chagua shirika.',
	'app.listorganizations.search': 'Tafuta',
	'app.listorganizations.new_organization': 'Shirika Jipya',
	'app.listorganizations.input_program_placeholder': 'Andika jina la programu au matumizi.',
	'app.listorganizations.input_subprogram_placeholder': 'Andika jina la usimamizi wa BEVI.',
	'app.listorganizations.organization_area': 'Usimamizi wa Mipango na Uzoefu',
	'app.listorganizations.download.data_report': 'Pakua data kama faili ya Excel.',
	'app.listorganizations.sort_name_date': 'Panga kwa jina au tarehe',
	'app.listorganizations.sort_by': 'Panga kulingana na',
	'app.sample.data.footer.message_line1': '<b><i>Kumbuka.</i> Mfumo wa kuripoti wa BEVI unaruhusu uundaji wa aina nane tofauti za ripoti: 1) Ripoti za Mtu Binafsi, 2) Ripoti za Mabadiliko ya Mtu Binafsi, 3) Ripoti za Washirika, 4) Ripoti za Mabadiliko ya Washirika, 5) Ripoti za Kikundi, 6) Ripoti za Mabadiliko ya Kikundi, 7) Ripoti za Ndani ya Kikundi, na 8) Kati ya Ripoti za Kikundi. Ili kutoa hisia za jinsi ripoti hizi zinavyofanya kazi, na aina ya maelezo ambayo yanaweza kufikiwa, mifano miwili ya kila aina ya ripoti -- A na B -- imetolewa.</b>',
	'app.sample.data.footer.message_line2': '<b>Ingawa ripoti za sampuli zinalingana na ripoti halisi katika mfumo wa BEVI, mifano hii imetolewa kwa madhumuni ya kielelezo pekee. Kwa hivyo, baadhi ya taarifa katika ripoti za sampuli hizi zinaweza kuwa zimerekebishwa (k.m., alama za mizani) au kuondolewa (k.m., majibu ya ubora) ili kuhakikisha kuwa matokeo hayatambuliwi na/au yameunganishwa na watu mahususi.</b>',
	'app.sample.data.footer.message_line3': '<b>Ikiwa una maswali yoyote kuhusu ripoti hizi za sampuli, au kipengele kingine chochote cha BEVI, tafadhali wasiliana na mtu aliyeidhinisha ufikiaji wa usimamizi wa BEVI kwa ajili yako au uwasiliane na Timu ya BEVI kwenye <a href="https://thebevi.com/contact/">https://thebevi.com/contact/.</a> </b>',
}
