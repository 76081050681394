import { appHI } from './hi/app';
import { consentPageHI } from './hi/consent';
import { counterPageHI } from './hi/counter';
import { createReportHI } from './hi/create_report';
import { createProgramHI } from './hi/createProgram';
import { dataDownloadHI } from './hi/dataDownload';
import { demographicQuestionsHI } from './hi/demographicQuestions';
import { discursiveQuestionsHI } from './hi/discursiveQuestions';
import { externalHI } from './hi/external';
import { individualReportPageHI } from './hi/individualReport';
import { internalTemplatePageHI } from './hi/internalTemplate';
import { listFormsPageHI } from './hi/listforms';
import { listOrganizationsHI } from './hi/listOrganizations';
import { listReportHI } from './hi/listReport';
import { loginPageHI } from './hi/login';
import { noInfoHI } from './hi/noInfo';
import { permissionHI } from './hi/permission';
import { reportsHI } from './hi/reports';
import { scaleQuestionsPageHI } from './hi/scaleQuestions';
import { timeHI } from './hi/time';
import { usersHI } from './hi/users';
import { PageMessages } from './types';
import { financialReportHI } from './hi/financialReport';
import { invitationHI } from './hi/invitation';
import { institutionHI } from './hi/institutions';
import { programsHI } from './hi/programs';
import { virtualAccountHI } from './hi/virtualAccount';

export const hi: PageMessages = {
    ...appHI,
    ...counterPageHI,
    ...loginPageHI,
    ...scaleQuestionsPageHI,
    ...consentPageHI,
    ...listFormsPageHI,
    ...demographicQuestionsHI,
    ...listOrganizationsHI,
    ...discursiveQuestionsHI,
    ...individualReportPageHI,
    ...internalTemplatePageHI,
    ...createProgramHI,
    ...usersHI,
    ...dataDownloadHI,
    ...noInfoHI,
    ...createReportHI,
    ...reportsHI,
    ...timeHI,
    ...listReportHI,
    ...permissionHI,
    ...externalHI,
    ...financialReportHI,
    ...invitationHI,
    ...institutionHI,
    ...programsHI,
    ...virtualAccountHI
}
