import { UsersMessages } from '../types';

const users: UsersMessages = {
    'app.users.listuser.title': 'उपयोगकर्ताओं का प्रबंधन करें ',
    'app.users.name': 'उपयोगकर्ता का नाम',
    'app.users.login': 'ई-मेल',
    'app.users.status.inactive': 'निष्क्रिय',
    'app.users.status.active': 'सक्रिय',
    'app.users.status': 'स्थिति',
    'app.create_batch_user.excel.email_column': 'ईमेल',
    'app.create_batch_user.excel.line_number_column': 'लाइन नंबर',
    'app.create_batch_user.excel.user_error_column': 'गलती',
    'app.create_batch_user.excel.download_error_details': 'विवरण डाउनलोड करें',
    'app.create_batch_user.error_already_exists': 'उपयोगकर्ता पहले से मौजूद है',
    'app.create_batch_user.error_invalid_line': 'अमान्य लाइन',
    'app.create_batch_user.error_wrong_email': 'गलत ईमेल के साथ उपयोगकर्ता',
    'app.create_batch_user.error_missing_name': 'उपयोगकर्ता का नाम गुम',
    'app.create_batch_user.error_details_filename': 'त्रुटि विवरण',
    'app.user.organization': 'संस्था',
    'app.user.select_organization': 'एक संस्था चुनें।',
    'app.user.select_program': 'कोई प्रोग्राम या अनुभव चुनें',
    'app.user.program': 'प्रोग्राम',
    'app.users.listuser.action': 'ऐक्शन',
    'app.users.listuser.reset_password': 'पासवर्ड रीसेट',
    'app.users.listuser.details': 'उपयोगकर्ता प्रबंधन',
    'app.users.listuser.programs_action': 'कार्यक्रम और अनुभव',
    'app.users.add_new_user': 'नए उपयोगकर्ता',
    'app.user_programs.programName': 'कार्यक्रम या अनुभव का नाम',
    'app.user_programs.subprogramName': 'BEVI प्रशासन का नाम',
    'app.user_programs.status': 'स्थिति',
    'app.user_programs.completedOn': 'पूर्ण किया गया ',
    'app.user_programs.invalid_reason': 'अमान्य कारण',
    'app.listorganizations.input_username_placeholder': 'एक उपयोगकर्ता नाम टाइप करें',
    'app.listorganizations.input_useremail_placeholder': 'एक उपयोगकर्ता ई-मेल टाइप करें',
    'app.create.batch_user_error': 'संस्थान के लिए कई उपयोगकर्ताओं को बनाने का प्रयास करते समय एक या अधिक त्रुटियाँ हुईं। अधिक विवरण के लिए त्रुटि सूचना फ़ाइल डाउनलोड करें।',
    'app.create.user.title': 'एक नया उपयोगकर्ता बनाएँ',
    'app.create.user.single.user': 'BEVI को पूरा करने और/या प्रबंधित करने के लिए अधिकृत किसी एक व्यक्ति का ईमेल पता प्रदान करें।',
    'app.create.user.send_welcome_email': 'स्वागत ईमेल भेजें',
    'app.create.user.multiple.users': 'BEVI को पूरा करने के लिए अधिकृत हो सकते हैं, ऐसे व्यक्तियों के ईमेल पते प्रदान करें।',
    'app.create.user.choose.option.new.user': 'नीचे दिए गए दो विकल्पों में से सही विकल्प चुनें।',
    'app.create.user.language_codes': 'भाषा कोड',
    'app.create.user.download.template.file': 'टेम्प्लेट फ़ाइल डाउनलोड करें.',
    'app.create.user.download.data.example': 'सैम्पल फ़ाइल डाउनलोड करें',
    'app.create.user.upload.file': 'अपलोड करें',
    'app.create.user.drag.n.drop.text': 'यहां कुछ फ़ाइलें खींचें और छोड़ें, या फ़ाइलों का चयन करने के लिए क्लिक करें',
    'app.create.user.dran.n.drop.available.files': '(केवल *.xlsx और *.xls फ़ाइलें स्वीकार की जाएंगी)',
    'app.create.user.remove': 'हटाएँ',
    'app.create.user.selected.file': 'चयनित:',
    'app.create.user.create.multiple.user.title': 'उन व्यक्तियों के लिए ईमेल पतों प्रदान करें जो BEVI पूरा करने के लिए अधिकृत हो सकते हैं।',
    'app.create.user.create.single.user.title': 'एक उपयोगकर्ता बनाएं',
    'app.create.user.table.column_actions': 'संगठन हटाएँ',
    'app.create.user.edit.single.user.title': 'उपयोगकर्ता प्रबंधन',
    'app.create.user.is.anonymous': 'गुमनाम है',
    'app.create.user.user.type': 'उपयोगकर्ता प्रकार',
    'app.create.user.language': 'ई-मेल भाषा',
    'app.create.user.capability_visualize_user': 'देखें - उपयोगकर्ता',
    "app.create.user.capability_add_user": "जोड़ें - उपयोगकर्ता",
    "app.create.user.capability_reset_password_user": "पासवर्ड रीसेट करें - उपयोगकर्ता",
    "app.create.user.capability_manage_user": "प्रबंधित करें - उपयोगकर्ता",
    "app.create.user.capability_visualize_user_programs": "देखें - उपयोगकर्ता कार्यक्रम",
    "app.create.user.capability_visualize_program_and_experience": "देखें - कार्यक्रम और अनुभव",
    "app.create.user.capability_add_program_and_experience": "जोड़ें - कार्यक्रम और अनुभव",
    "app.create.user.capability_rename_program_and_experience": "नाम बदलें - कार्यक्रम और अनुभव",
    "app.create.user.capability_add_subprogram": "जोड़ें - उपकार्यक्रम",
    "app.create.user.capability_edit_subprogram": "संपादित करें - उपकार्यक्रम",
    "app.create.user.capability_manage_subprogram": "प्रबंधित करें - उपकार्यक्रम",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "QR कोड दिखाएँ नए उपयोगकर्ताओं को आमंत्रित करने के लिए - उपकार्यक्रम",
    "app.create.user.capability_visualize_report": "देखें - रिपोर्ट",
    "app.create.user.capability_add_report": "जोड़ें - रिपोर्ट",
    'app.user.profile.participant': 'प्रतिभागी',
    'app.user.profile.programAdmin': 'कार्यक्रम व्यवस्थापक',
    'app.user.profile.institutional': 'संस्थानिक व्यवस्थापक',
    'app.user.profile.accountManager': 'अकाउंट प्रबंधक',
    'app.user.profile.superAdmin': 'सुपर व्यवस्थापक',
    'app.user.language.message': '(भाषा जिसमें उपयोगकर्ता को ई-मेल प्राप्त होगा)',
    'app.user.successfulMessage': 'उपयोगकर्ता सफलतापूर्वक बनाया गया',
    'app.edit.user.confirmation_title_fail': 'अरे नहीं! दुर्भाग्यवश, हम इसे अपडेट नहीं कर सके',
    'app.edit.user.confirmation_body_fail': 'उपयोगकर्ता अपडेट नहीं किया जा सका। कृपया, प्रदान की गई डेटा, इंटरनेट कनेक्शन की समीक्षा करें और पुनः प्रयास करें। यदि समस्या बनी रहती है, तो अकाउंट प्रबंधक से संपर्क करें।',
    'app.edit.user.confirmation_body_fail_already_exists': 'उपयोगकर्ता को अपडेट नहीं किया जा सका क्योंकि किसी अन्य उपयोगकर्ता के पास पहले से ही प्रदान की गई ई-मेल है।',
    'app.edit.user.confirmation_body_fail_wrong_email': 'उपयोगकर्ता को अपडेट नहीं किया जा सका क्योंकि प्रदान की गई ई-मेल अवैध है।',
    'app.edit.user.confirmation_title_success': 'सफलतापूर्वक अपडेट किया गया',
    'app.edit.user.confirmation_body_success': 'उपयोगकर्ता को अपडेट कर दिया गया है और आप अब उपयोगकर्ता सूची में इसे देख सकते हैं, जहां आप पुनः निर्देशित किए जा रहे हैं।',
    'app.create.user.confirmation_title_fail': 'अरे नहीं! दुर्भाग्यवश, हम इसे नहीं बना सके',
    'app.create.user.confirmation_body_fail': 'उपयोगकर्ता नहीं बनाया जा सका। कृपया, प्रदान की गई डेटा, इंटरनेट कनेक्शन की समीक्षा करें और पुनः प्रयास करें। यदि समस्या बनी रहती है, तो अकाउंट प्रबंधक से संपर्क करें।',
    'app.create.user.confirmation_title_success': 'सफलतापूर्वक बनाया गया',
    'app.create.user.confirmation_body_success': 'उपयोगकर्ता सफलतापूर्वक बनाया गया।',
    'app.create.user.confirmation_body_fail_already_exists': 'उपयोगकर्ता नहीं बनाया जा सका क्योंकि किसी अन्य उपयोगकर्ता के पास पहले से ही प्रदान की गई ई-मेल है।',
    'app.create.user.confirmation_body_fail_wrong_email': 'उपयोगकर्ता नहीं बनाया जा सका क्योंकि प्रदान की गई ई-मेल अवैध है।',
    'app.reset_password.title': 'पासवर्ड रीसेट करें',
    'app.reset_password.body': 'पासवर्ड रीसेट करने पर, आप <b>{username}</b> के लिए अस्थायी रूप से पासवर्ड रीसेट करेंगे।<br/><br/>जब वे सिस्टम में लॉग इन करेंगे, तो वे अपना पासवर्ड बदल सकेंगे।',
    'app.reset_password.successful_message': '<b>{username}</b> को सफलतापूर्वक अपडेट किया गया।',
    'app.reset_password.error_message': 'कुछ गलत हो गया, कृपया कुछ मिनटों में पुनः प्रयास करें।',
    'app.reset_password.new_temporary_password': 'नीचे नया अस्थायी पासवर्ड टाइप करें',
    'app.reset_password.new_password': 'नया पासवर्ड',
    'app.reset_password.button_reset': 'पासवर्ड रीसेट करें',
    'app.users_type.select': 'उपयोगकर्ता प्रकार चुनें',
    'app.users_type.all': 'सभी',
    'app.users_type.participant': 'प्रतिभागी',
    'app.users_type.program_admin': 'कार्यक्रम व्यवस्थापक',
    'app.users_type.institutional': 'संस्थानिक',
    'app.users_type.account_manager': 'अकाउंट प्रबंधक',
    'app.users_type.super_admin': 'सुपर व्यवस्थापक',
    'app.users_type.external_partner': 'बाहरी भागीदार',
    'app.user.updateSuccessfulMessage': 'उपयोगकर्ता सफलतापूर्वक अपडेट किया गया।',
    'app.users.listuser.clear_2fa': 'दो-चरणीय प्रमाणीकरण हटाएँ',
    'app.users.confirm_deletion_body': 'क्या आप सुनिश्चित हैं कि आप 2FA हटाने की प्रक्रिया लागू करना चाहते हैं? यदि आप ऐसा करते हैं, तो उपयोगकर्ता को फिर से Google Authentication में कोड दर्ज करने के लिए कहा जाएगा।'
}

export const usersHI: UsersMessages =
    { ...users }
