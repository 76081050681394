import { AppMessages } from '../types';

export const appHI: AppMessages = {
    'app.unknown_error': 'एक अज्ञात त्रुटि उत्पन्न हुई है, कृपया जिम्मेदार लोगों से संपर्क करें।',
    'app.btn_start': 'शुरू करें।',
    'app.btn_restart': 'पुनः प्रारंभ करें।',
    'app.btn_cancel': 'रद्द करें।',
    'app.btn_next': 'अगला',
    'app.btn_ok': 'ठीक है',
    'app.btn_confirm': 'पुष्ट करें',
    'app.btn_finish': 'समाप्त करें',
    'app.btn_back': 'वापस',
    'app.dropdown_select': 'चुनें.."',
    'app.loading': 'लोड हो रहा है',
    'app.response_yes': 'हाँ',
    'app.response_no': 'नहीं',
    'app.bevi_copyright_text_1': 'बीलीफ्स, इवेंट्स, एंड वैल्यूज़ इन्वेंटरी  (BEVI) एक कॉपीराइटेड इन्वेंट्री है। BEVI और BEVI रिपोर्ट्स, आइटम, आइटम सामग्री, या स्केल्स को पूरी तरह से या आंशिक रूप से बिना क्रेग एन. शीली, पीएच.डी. की लिखित और स्पष्ट अनुमति के संशोधित, कॉपी, प्रसारित, या प्रकाशित नहीं किया जा सकता।',
    'app.bevi_copyright_text_2': '',
    'app.empty.data': 'कोई डेटा नहीं',
    'app.systemerro': 'अरे नहीं! सिस्टम में अस्थिरता दिखाई दे रही है, कृपया कुछ मिनटों बाद पुनः प्रयास करें।',
    'app.loading_document': 'डॉक्यूमेंट लोड हो रहा है...',
    'app.download_document': 'पीडीएफ डाउनलोड करें',
    'app.download_excel': 'डाउनलोड करें',
    'app.btn_search': 'खोजें',
    'app.english': 'अंग्रेज़ी',
    'app.catalan': 'कैटालान/कैटलन',
    'app.spanish': 'स्पेनिश',
    'app.japanese': 'जापानी',
    'app.chinese-t': 'परंपरागत चीनी',
    'app.chinese-s': 'सरलीकृत चीनी',
    'app.true': 'सही',
    'app.false': 'गलत',
    'app.save': 'सेव ',
    'app.btn_reset': 'रिसेट',
    'app.btn_submit': 'सबमिट करें',
    'app.search_placeholder': 'खोज़ पाठ टाइप करें',
    'app.notification.error_title': 'त्रुटि',
    'app.error.user_not_found': 'उपयोगकर्ता नहीं मिला',
    'app.save_with_success': 'सफलतापूर्वक सहेजा गया',
    'app.swahili': 'स्वाहिली',
    'app.german': 'जर्मन',
    'app.hindi': 'Hindi'
}
