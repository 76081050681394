import '../../style/login.css';

import { Alert, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { colors } from '../../config/colors';
import { GetFormattedMessage } from '../../utils/htmlHelper';

type Props = {
    login: (username: string, password: string, code?: string, key?: string) => void;
    forgotPasswordClicked: () => void;
    backToLogin: () => void;
    qrCode: string;
    qrCodeKey: string;
    qrCodeHasKey: boolean;
    loading: boolean;
    errorKey: string;
};

export const Login = ({ login, forgotPasswordClicked, backToLogin, loading, errorKey, qrCode, qrCodeKey, qrCodeHasKey }: Props): JSX.Element => {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [forgotPasswordMouseIn, setForgotPasswordMouseIn] = useState<boolean>(false);

    useEffect(() => {
        if (code != null && code.trim() !== '') {
            login(username, password, code, qrCodeKey);
        }
    }, [code, login, qrCodeKey, username, password, loading])

    useEffect(() => {
        if (errorKey != null && loading === false) {
            setCode('');
            if(document.getElementsByClassName('ant-otp-input').length > 0) {
                (document.getElementsByClassName('ant-otp-input')[0] as HTMLInputElement).focus()
            }
        }
    }, [errorKey, loading])

    return (
        <React.Fragment>
            {errorKey &&
                <div style={styles.errorDiv}>
                    <Alert message={<FormattedMessage id={errorKey} />} type='error' showIcon />
                </div>
            }

            {(qrCode == null && !qrCodeHasKey) && <>
                <Form.Group className='mb-3' as={Row} controlId='formField1'>
                    <FormattedMessage id='app.login.lbl_username' />
                    <Form.Control
                        type='email'
                        placeholder={GetFormattedMessage('app.login.placeholder.login')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className='mb-3' as={Row} controlId="formField2">
                    <FormattedMessage id='app.login.lbl_password' />
                    <Input
                        type='password'
                        className="form-control mb-3"
                        placeholder={GetFormattedMessage('app.login.placeholder.password')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        onPressEnter={() => { login(username, password) }}
                    />
                </Form.Group>
                <div className="d-flex justify-content-end mb-4">
                    <Button className="btn px-4 shadow-lg btn-primary ml-auto rounded-pill"
                        disabled={loading}
                        style={styles.button}
                        type='submit'
                        onClick={() => { login(username, password) }}
                    >
                        {loading ?
                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            :
                            <FormattedMessage id='app.login.btn_submit' />
                        }
                    </Button>
                </div>
            </>}
            {qrCode != null && <>
                <div style={styles.qrCodeDiv}>
                    <h2>{GetFormattedMessage('app.login.auth.qrcode')}</h2>
                    <img src={qrCode} alt='google authenticator' />
                </div>
            </>}
            {(qrCode != null || qrCodeHasKey) && <Spin spinning={loading}>
                <div style={styles.qrCodeDiv}>
                    <h2>{GetFormattedMessage('app.login.auth.type')}</h2>
                    <Input.OTP value={code} onChange={(value) => setCode(value)} formatter={(str) => str.toUpperCase()} length={6} />
                </div>
            </Spin>}
            <div style={styles.divForgotPassword}>
                {(!qrCode && !qrCodeHasKey) ?
                    <label
                        style={forgotPasswordMouseIn ? styles.textForgotPasswordMouseIn : styles.textForgotPassword}
                        onClick={forgotPasswordClicked}
                        onMouseEnter={() => setForgotPasswordMouseIn(true)}
                        onMouseLeave={() => setForgotPasswordMouseIn(false)}
                    >
                        {GetFormattedMessage('app.login.forgot_password')}
                    </label> : <label
                        style={forgotPasswordMouseIn ? styles.textForgotPasswordMouseIn : styles.textForgotPassword}
                        onClick={() => {
                            setCode('');
                            backToLogin();
                        }}
                        onMouseEnter={() => setForgotPasswordMouseIn(true)}
                        onMouseLeave={() => setForgotPasswordMouseIn(false)}
                    >
                        {GetFormattedMessage('app.login.auth.backLogin')}
                    </label>}
            </div>
        </React.Fragment>
    );
}

const styles = {
    button: {
        width: 200,
    } as React.CSSProperties,
    divForgotPassword: {
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
    } as React.CSSProperties,
    textForgotPassword: {
        color: colors.orange,
        fontStyle: 'italic',
        textDecorationLine: 'underline',
    } as React.CSSProperties,
    textForgotPasswordMouseIn: {
        color: colors.orange,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecorationLine: 'underline',
    } as React.CSSProperties,
    errorDiv: {
        flex: 1,
        paddingBottom: 20,
    } as React.CSSProperties,
    qrCodeDiv: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 16,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 16
    } as React.CSSProperties,
}
