import { InvitationPageMessages } from '../types';

export const invitationSW: InvitationPageMessages = {
	'app.invitation.invite_label_template': 'Ulialikwa kushiriki katika BEVI na {0}. Utasajiliwa kwa programu {1} na kikundi {2}.',
	'app.invitation.email': 'Barua pepe',
	'app.invitation.password': 'Nenosiri',
	'app.invitation.confirm_password': 'Rudia nenosiri',
	'app.invitation.name': 'Jina',
	'app.invitation.language': 'Lugha',
	'app.invitation.blank_feedback': 'Sehemu hii haiwezi kuwa tupu!',
	'app.invitation.invalid_email_feedback': 'Barua pepe hii si sahihi!',
	'app.invitation.password_length_feedback_template': 'Sehemu hii lazima iwe na angalau vibambo {0}!',
	'app.invitation.confirm_password_not_equal_feedback': 'Sehemu hii lazima iwe sawa na nenosiri!',
	'app.invitation.success_notification_header': 'Karibu kwenye BEVI!',
	'app.invitation.success_notification_body': 'Barua pepe yako iliyosajiliwa na nenosiri zitatumika kuingia kwenye mfumo mara chache zijazo.',
	'app.invitation.error_notification_header': 'Hitilafu imetokea.',
	'app.invitation.error_notification_body': 'Tafadhali angalia data iliyotumwa na ujaribu tena.',
}
