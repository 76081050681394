import { appSW } from './sw/app';
import { consentPageSW } from './sw/consent';
import { counterPageSW } from './sw/counter';
import { createReportSW } from './sw/create_report';
import { createProgramSW } from './sw/createProgram';
import { dataDownloadSW } from './sw/dataDownload';
import { demographicQuestionsSW } from './sw/demographicQuestions';
import { discursiveQuestionsSW } from './sw/discursiveQuestions';
import { externalSW } from './sw/external';
import { individualReportPageSW } from './sw/individualReport';
import { internalTemplatePageSW } from './sw/internalTemplate';
import { listFormsPageSW } from './sw/listforms';
import { listOrganizationsSW } from './sw/listOrganizations';
import { listReportSW } from './sw/listReport';
import { loginPageSW } from './sw/login';
import { noInfoSW } from './sw/noInfo';
import { permissionSW } from './sw/permission';
import { reportsSW } from './sw/reports';
import { scaleQuestionsPageSW } from './sw/scaleQuestions';
import { timeSW } from './sw/time';
import { usersSW } from './sw/users';
import { PageMessages } from './types';
import { financialReportSW } from './sw/financialReport';
import { invitationSW } from './sw/invitation';
import { institutionSW } from './sw/institutions';
import { programsSW } from './sw/programs';
import { virtualAccountSW } from './sw/virtualAccount';

export const sw: PageMessages = {
    ...appSW,
    ...counterPageSW,
    ...loginPageSW,
    ...scaleQuestionsPageSW,
    ...consentPageSW,
    ...listFormsPageSW,
    ...demographicQuestionsSW,
    ...listOrganizationsSW,
    ...discursiveQuestionsSW,
    ...individualReportPageSW,
    ...internalTemplatePageSW,
    ...createProgramSW,
    ...usersSW,
    ...dataDownloadSW,
    ...noInfoSW,
    ...createReportSW,
    ...reportsSW,
    ...timeSW,
    ...listReportSW,
    ...permissionSW,
    ...externalSW,
    ...financialReportSW,
    ...invitationSW,
    ...institutionSW,
    ...programsSW,
    ...virtualAccountSW
}
