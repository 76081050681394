import { InternalTemplateMessages } from '../types';

export const internalTemplatePageEN: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'Welcome',
    'app.internaltemplate.menu.dashboard': 'Dashboard',
    'app.internaltemplate.menu.users': 'Users',
    'app.internaltemplate.menu.organization': 'Programs and Experiences',
    'app.internaltemplate.menu.institutions': 'Organizations',
    'app.internaltemplate.menu.report': 'Reports',
    'app.internaltemplate.menu.sample_report': 'Sample Reports',
    'app.internaltemplate.menu.permission_profile': 'Permission Profile',
    'app.internaltemplate.menu.financial_report': 'Usage Report',
    'app.internaltemplate.menu.logout': 'Logout',
    'app.internaltemplate.menu.virtualAccount': 'Virtual account',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': 'Configure Two Factor Authentication',
    'app.internaltemplate.system.maintenance_time': 'The system will be under maintenance from {0} to {1}.',
    'app.internaltemplate.menu.report_permission': 'Report Permission',
}
