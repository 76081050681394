import { UsersMessages } from '../types';

const users: UsersMessages = {
	'app.users.listuser.title': 'Simamia Watumiaji',
	'app.users.name': 'Jina la Mtumiaji',
	'app.users.login': 'Barua pepe',
	'app.users.status.inactive': 'Isiyotumika',
	'app.users.status.active': 'Inatumika',
	'app.users.status': 'Hali',
	'app.create_batch_user.excel.email_column': 'Barua pepe',
	'app.create_batch_user.excel.line_number_column': 'Nambari ya mstari',
	'app.create_batch_user.excel.user_error_column': 'Hitilafu',
	'app.create_batch_user.excel.download_error_details': 'Pakua maelezo',
	'app.create_batch_user.error_already_exists': 'Mtumiaji tayari yupo',
	'app.create_batch_user.error_invalid_line': 'Mstari batili',
	'app.create_batch_user.error_wrong_email': 'Mtumiaji mwenye barua pepe isiyo sahihi',
	'app.create_batch_user.error_missing_name': 'Jina la mtumiaji halipo',
	'app.create_batch_user.error_details_filename': 'Maelezo ya hitilafu',
	'app.user.organization': 'Shirika',
	'app.user.select_organization': 'Chagua shirika.',
	'app.user.select_program': 'Chagua programu au uzoefu',
	'app.user.program': 'Programu',
	'app.users.listuser.action': 'Kitendo',
	'app.users.listuser.reset_password': 'Weka Upya Nenosiri',
	'app.users.listuser.details': 'Usimamizi wa Mtumiaji',
	'app.users.listuser.programs_action': 'Programu na Uzoefu',
	'app.users.add_new_user': 'Mtumiaji Mpya',
	'app.user_programs.programName': 'Jina la Mpango au Uzoefu',
	'app.user_programs.subprogramName': 'Jina la Usimamizi wa BEVI',
	'app.user_programs.status': 'Hali',
	'app.user_programs.completedOn': 'Imekamilika',
	'app.user_programs.invalid_reason': 'Sababu batili',
	'app.listorganizations.input_username_placeholder': 'Andika Jina la Mtumiaji',
	'app.listorganizations.input_useremail_placeholder': 'Andika Barua pepe ya Mtumiaji',
	'app.create.batch_user_error': 'Hitilafu moja au zaidi ilipatikana wakati wa kujaribu kuunda watumiaji wengi wa shirika. Kwa maelezo zaidi pakua faili ya maelezo ya hitilafu.',
	'app.create.user.title': 'Unda Mtumiaji Mpya',
	'app.create.user.single.user': 'Toa anwani ya barua pepe moja kwa mtu ambaye ameidhinishwa kukamilisha na/au kusimamia BEVI.',
	'app.create.user.send_welcome_email': 'Tuma barua pepe ya kukaribisha',
	'app.create.user.multiple.users': 'Toa anwani za barua pepe za watu binafsi ambao wanaweza kuidhinishwa kukamilisha BEVI.',
	'app.create.user.choose.option.new.user': 'Chagua chaguo sahihi kutoka kwa chaguo mbili zilizo hapa chini.',
	'app.create.user.language_codes': 'Misimbo ya lugha',
	'app.create.user.download.template.file': 'Pakua faili ya kiolezo.',
	'app.create.user.download.data.example': 'Pakua sampuli ya faili',
	'app.create.user.upload.file': 'Pakia',
	'app.create.user.drag.n.drop.text': 'Buruta \'n\' dondosha baadhi ya faili hapa, au ubofye ili kuchagua faili',
	'app.create.user.dran.n.drop.available.files': '(faili za *.xlsx na *.xls pekee ndizo zitakubaliwa)',
	'app.create.user.remove': 'Ondoa',
	'app.create.user.selected.file': 'Imechaguliwa:',
	'app.create.user.create.multiple.user.title': 'Toa anwani za barua pepe za watu ambao wanaweza kuidhinishwa kukamilisha BEVI.',
	'app.create.user.create.single.user.title': 'Unda Mtumiaji',
	'app.create.user.table.column_actions': 'Ondoa Shirika',
	'app.create.user.edit.single.user.title': 'Usimamizi wa Mtumiaji',
	'app.create.user.is.anonymous': 'Haijulikani',
	'app.create.user.user.type': 'Aina ya Mtumiaji',
	'app.create.user.language': 'Lugha ya Barua pepe',
	'app.create.user.capability_visualize_user': 'TAZAMA - MTUMIAJI',
	"app.create.user.capability_add_user": "ONGEZA - MTUMIAJI",
	"app.create.user.capability_reset_password_user": "WEKA UPYA NENOSIRI - MTUMIAJI",
	"app.create.user.capability_manage_user": "SIMAMIA - MTUMIAJI",
	"app.create.user.capability_visualize_user_programs": "TAZAMA - PROGRAMU ZA MTUMIAJI",
	"app.create.user.capability_visualize_program_and_experience": "TAZAMA - PROGRAMU NA UZOEFU",
	"app.create.user.capability_add_program_and_experience": "ONGEZA - PROGRAMU NA UZOEFU",
	"app.create.user.capability_rename_program_and_experience": "IPE JINA JIPYA - PROGRAMU NA UZOEFU",
	"app.create.user.capability_add_subprogram": "ONGEZA - PROGRAMU NDOGO",
	"app.create.user.capability_edit_subprogram": "HARIRI - PROGRAMU NDOGO",
	"app.create.user.capability_manage_subprogram": "DHIBITI - PROGRAMU NDOGO",
	"app.create.user.capability_show_qr_code_invite_new_users_subprogram": "ONYESHA MSIMBO WA QR ALIKA WATUMIAJI WAPYA - PROGRAMU NDOGO",
	"app.create.user.capability_visualize_report": "TAZAMA - RIPOTI",
	"app.create.user.capability_add_report": "ONGEZA - RIPOTI",
	'app.user.profile.participant': 'Mshiriki',
	'app.user.profile.programAdmin': 'Msimamizi wa Programu',
	'app.user.profile.institutional': 'Msimamizi wa Kitaasisi',
	'app.user.profile.accountManager': 'Msimamizi wa Akaunti',
	'app.user.profile.superAdmin': 'Msimamizi Mkuu',
	'app.user.language.message': '(Lugha ambayo mtumiaji atapokea barua pepe)',
	'app.user.successfulMessage': 'Mtumiaji alifanikiwa kuundwa',
	'app.edit.user.confirmation_title_fail': 'La! Kwa bahati mbaya, hatukuweza kuisasisha',
	'app.edit.user.confirmation_body_fail': 'Mtumiaji hakuweza kusasishwa. Tafadhali, kagua data iliyotolewa, muunganisho wa intaneti na ujaribu tena. Tatizo likiendelea, zungumza na msimamizi wa akaunti.',
	'app.edit.user.confirmation_body_fail_already_exists': 'Mtumiaji hakuweza kusasishwa kwa sababu mtumiaji mwingine tayari ana barua pepe iliyotolewa.',
	'app.edit.user.confirmation_body_fail_wrong_email': 'Mtumiaji hakuweza kusasishwa kwa sababu barua pepe iliyotolewa si sahihi.',
	'app.edit.user.confirmation_title_success': 'Imesasishwa kwa mafanikio',
	'app.edit.user.confirmation_body_success': 'Mtumiaji alisasishwa na sasa unaweza kuiona taswira katika orodha ya watumiaji, ambapo unaelekezwa kwingine.',
	'app.create.user.confirmation_title_fail': 'La! Kwa bahati mbaya, hatukuweza kuunda',
	'app.create.user.confirmation_body_fail': 'Mtumiaji hakuweza kuundwa. Tafadhali, kagua data iliyotolewa, muunganisho wa intaneti na ujaribu tena. Tatizo likiendelea, zungumza na msimamizi wa akaunti.',
	'app.create.user.confirmation_title_success': 'Imeundwa kwa ufanisi',
	'app.create.user.confirmation_body_success': 'Mtumiaji aliundwa kwa mafanikio.',
	'app.create.user.confirmation_body_fail_already_exists': 'Mtumiaji hakuweza kuundwa kwa sababu mtumiaji mwingine tayari ana barua pepe iliyotolewa.',
	'app.create.user.confirmation_body_fail_wrong_email': 'Mtumiaji hakuweza kuundwa kwa sababu barua pepe iliyotolewa si sahihi.',
	'app.reset_password.title': 'WEKA UPYA NENOSIRI',
	'app.reset_password.body': 'Kubofya kwa Kuweka Upya Nenosiri, utaweka upya nenosiri la muda la <b>{username}</b>.<br/><br/>Pindi wanapoingia kwenye mfumo, wataweka upya nenosiri la muda. waweze kubadilisha nenosiri lao.',
	'app.reset_password.successful_message': '<b>{username}</b> imesasishwa kwa ufanisi.',
	'app.reset_password.error_message': 'Hitilafu imetokea, tafadhali, jaribu tena baada ya dakika chache.',
	'app.reset_password.new_temporary_password': 'Andika chini nenosiri jipya la muda',
	'app.reset_password.new_password': 'Nenosiri Jipya',
	'app.reset_password.button_reset': 'Weka Upya Nenosiri',
	'app.users_type.select': 'Chagua Aina ya Mtumiaji',
	"app.users_type.all": "Yote",
	"app.users_type.participant": "Mshiriki",
	"app.users_type.program_admin": "Msimamizi wa Programu",
	"app.users_type.institutional": "Taasisi",
	"app.users_type.account_manager": "Mdhibiti wa Akaunti",
	"app.users_type.super_admin": "Msimamizi Mkuu",
	"app.users_type.external_partner": "Mshirika wa Nje",
	
	'app.user.updateSuccessfulMessage': 'Mtumiaji amesasishwa kwa mafanikio',
	'app.users.listuser.clear_2fa': 'Futa Uthibitishaji wa Hatua Mbili',
	'app.users.confirm_deletion_body': 'Je, una uhakika unataka kulazimisha mchakato wa kuondoa 2FA? Ikiwa utaendelea, mtumiaji ataombwa tena kuingiza msimbo katika Google Authentication.'

}

export const usersSW: UsersMessages =
    { ...users }
