import { InternalTemplateMessages } from '../types';

export const internalTemplatePageJA: InternalTemplateMessages = {
    'app.internaltemplate.welcome': 'Welcome',
    'app.internaltemplate.menu.dashboard': 'ダッシュボード',
    'app.internaltemplate.menu.users': 'ユーザー',
    'app.internaltemplate.menu.organization': 'プログラム',
    'app.internaltemplate.menu.institutions': '組織',
    'app.internaltemplate.menu.report': 'レポート',
    'app.internaltemplate.menu.sample_report': 'サンプルレポート',
    'app.internaltemplate.menu.permission_profile': '権限プロファイル',
    'app.internaltemplate.menu.financial_report': '使用状況レポート',
    'app.internaltemplate.menu.logout': 'ログアウト',
    'app.internaltemplate.menu.virtualAccount': 'バーチャルアカウント',
    'app.internaltemplate.menu.configureTwoFactorAuthentication': '二要素認証を設定する',
    'app.internaltemplate.system.maintenance_time': 'システムは{0}から{1}までメンテナンス中です。',
    'app.internaltemplate.menu.report_permission': 'レポートの許可',
}
