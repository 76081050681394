import { useContext } from 'react';

import { SelectItem } from '../components/FormDropdown';
import { IntlContext } from '../IntlProviderWrapper';
import { PageMessages } from '../locales/types';
import { Languages, UserType } from '../services/usersService';

export function GetFormattedMessage(id?: keyof (PageMessages) | ""): string {
    const { messages }: { messages?: PageMessages } = useContext(IntlContext);
    if (messages) {
        if (!id) return messages['app.unknown_error'].toString();
        return messages[id]?.toString() ?? messages['app.unknown_error'].toString();
    }
    return ''
}

export type FormattedMessageWithIds = {
    key: keyof (PageMessages);
    value: number;
}

export function convertFormattedMessagesToSelectItem(arr: Array<FormattedMessageWithIds>): Array<SelectItem> {
    return arr.map((item) => ({ value: item.value, label: GetFormattedMessage(item.key) }));
}

export const getLanguages = (): SelectItem[] => [
    { value: Languages.English, label: GetFormattedMessage('app.english') },
    { value: Languages.Japanese, label: GetFormattedMessage('app.japanese') },
    { value: Languages.ChineseTradicional, label: GetFormattedMessage('app.chinese-t') },
    { value: Languages.ChineseSimplified, label: GetFormattedMessage('app.chinese-s') },
    { value: Languages.Spanish, label: GetFormattedMessage('app.spanish') },
    { value: Languages.Catalan, label: GetFormattedMessage('app.catalan') },
    { value: Languages.German, label: GetFormattedMessage('app.german') },
    { value: Languages.Swahili, label: GetFormattedMessage('app.swahili') },
    { value: Languages.Hindi, label: GetFormattedMessage('app.hindi') },
];

//TODO(rubens | rodrigo): compare and return values based on user profile.
export const getUserProfile = (): SelectItem[] => [
    { value: UserType.participant, label: GetFormattedMessage('app.user.profile.participant') },
    { value: UserType.programAdmin, label: GetFormattedMessage('app.user.profile.programAdmin') },
    { value: UserType.institutional, label: GetFormattedMessage('app.user.profile.institutional') },
    { value: UserType.accountManager, label: GetFormattedMessage('app.user.profile.accountManager') },
    { value: UserType.superAdmin, label: GetFormattedMessage('app.user.profile.superAdmin') },
];

export const getIsAnonymous = (): SelectItem[] => [
    { value: 1, label: GetFormattedMessage('app.false') },
    { value: 0, label: GetFormattedMessage('app.true') },
]
