import { InvitationPageMessages } from '../types';

export const invitationHI: InvitationPageMessages = {
    'app.invitation.invite_label_template': 'आपको {0} द्वारा BEVI में भाग लेने के लिए आमंत्रित किया गया था। आपको कार्यक्रम {1} और समूह {2} के लिए पंजीकृत किया जाएगा।',
    'app.invitation.email': 'ईमेल',
    'app.invitation.password': 'पासवर्ड',
    'app.invitation.confirm_password': 'पासवर्ड दोहराएँ',
    'app.invitation.name': 'नाम',
    'app.invitation.language': 'भाषा',
    'app.invitation.blank_feedback': 'यह फ़ील्ड खाली नहीं हो सकती!',
    'app.invitation.invalid_email_feedback': 'यह ईमेल अमान्य है!',
    'app.invitation.password_length_feedback_template': 'इस फ़ील्ड में कम से कम {0} अक्षर होने चाहिए!',
    'app.invitation.confirm_password_not_equal_feedback': 'यह फ़ील्ड पासवर्ड के समान होना चाहिए!',
    'app.invitation.success_notification_header': 'BEVI में आपका स्वागत है!',
    'app.invitation.success_notification_body': 'आपका पंजीकृत ईमेल और पासवर्ड अगली बार सिस्टम में लॉग इन करने के लिए उपयोग किया जाएगा।',
    'app.invitation.error_notification_header': 'एक त्रुटि हुई।',
    'app.invitation.error_notification_body': 'कृपया भेजे गए डेटा की जांच करें और पुनः प्रयास करें।',
}