import { ConsentPageMessages } from '../types';

export const consentPageSW: ConsentPageMessages = {
   'app.consent.title': 'Ripoti ya makubaliano ya ukaguzi',
 'app.consent.form_title': 'Fomu ya Idhini',
 'app.consent.greeting': 'Mpendwa Mshiriki:',
 'app.consent.introduction': 'Asante kwa hiari yako ya kukamilisha Orodha ya Imani, Matukio, na Maadili au BEVI.',
 'app.consent.general.observation': 'Fomu hii ya idhini ya jumla imetengenezwa kutokana na mchango wa michakato mingi ya Bodi ya Ukaguzi ya Kitaasisi (IRB). Kabla ya kukamilisha BEVI, kuna uwezekano kwamba unaweza kuhitajika kutoa idhini kupitia mfumo au fomu tofauti ambayo imeidhinishwa na taasisi au shirika fulani. Ikiwa una maswali yoyote kuhusu mchakato huu, tafadhali wasiliana na msimamizi wa BEVI katika taasisi au shirika lako.',
 'app.consent.explanation_1': 'Kama jina la orodha hii linavyopendekeza, BEVI imeundwa kutathmini njia nyingi tofauti ambazo watu wanajiona, wengine, na ulimwengu mkubwa zaidi. Baada ya kukamilisha mfululizo wa maswali ya usuli, utaulizwa kujibu vipengee vinavyoshughulikia masuala na mada mbalimbali. Unaweza kuwa na athari tofauti kwa vitu tofauti. Baadhi ya vitu hivi vitaonekana kuwa vinahusiana moja kwa moja na kujifunza, ukuaji na maendeleo, lakini vingine vinaweza kutokuwa hivyo; baadhi ya vitu vinaweza kuonekana moja kwa moja ilhali vingine vinaweza kuonekana kuwa na utata. Kwa sababu hatua hii inatathmini mambo mengi changamano na yanayohusiana, vipengee hivi vyote pamoja na maswali ya usuli ni muhimu ili kutusaidia kuelewa michakato inayohusiana na kujifunza, ukuaji na maendeleo na pia jinsi watu tofauti wanavyojiona, wengine na ulimwengu mkubwa.',
 'app.consent.explanation_2': 'Ingawa watu tofauti wanaweza kujibu vipengee hivi kwa njia tofauti, tafadhali fahamu kwamba hakuna majibu "sahihi" au "mabaya" kwenye BEVI. Kwa kuwa mara nyingi tunatofautiana katika imani, maadili, na mambo tuliyojionea maishani, ni jambo la kawaida na linalotarajiwa kwamba mtu mmoja anaweza kukubaliana kabisa na jambo fulani ilhali mtu tofauti anaweza kutokubaliana vikali na jambo hilohilo. Kwa kuruhusu majibu kutoka Kubali Kabisa, Kubali, Sikubali, na Sikubali Kabisa, BEVI inatambua wazi kwamba watu tofauti wanaweza kuwa na imani, maadili, na uzoefu tofauti sana wa maisha.',
    'app.consent.participant.rights.1': 'Kushiriki kwako katika utafiti huu ni kwa hiari na unaweza kuchagua kusitisha ushiriki wakati wowote. Faragha yako italindwa kwa kiwango cha juu kinachoruhusiwa na sheria. Majibu yako yote yatashughulikiwa <b>kwa uaminifu kamili</b>. Majibu ya mtu binafsi utakayotoa kwa maswali <b>hayataunganishwa nawe isipokuwa kama umeidhinisha uunganisho kama huo mahususi kwa maandishi.</b> Hata hivyo, katika baadhi ya matukio, unaweza kujihusisha na matumizi ambapo majibu yako yanaweza kukaguliwa moja kwa moja na wewe. Hilo likitokea, majibu yako yatashughulikiwa kwa imani kali; faragha yako italindwa kwa kiwango cha juu kinachoruhusiwa na sheria, na kwa njia ambayo inaambatana na habari maalum. Ni waratibu wa mradi pekee ndio wataweza kufikia data ghafi. Kwa madhumuni ya kitaaluma, ufundishaji, taaluma, shirika, habari, au kielelezo, inawezekana kwamba mizani ya mtu binafsi au vipengee, data ya wasifu wa kikundi, au ripoti za shirika zinaweza kuwasilishwa au kuchapishwa. Hata hivyo, ikiwa ni hivyo, hakuna taarifa ya kutambua ambayo itawahi kuwasilishwa au kuchapishwa ambayo inaweza kuunganisha wasifu, mizani, vipengee au majibu mahususi kwako moja kwa moja (k.m., kitambulisho chochote ambacho unaweza kuwa umetoa kitafutwa), isipokuwa ukiidhinisha mahususi uhusiano kama huo. kwa maandishi.',
 'app.consent.participant.rights.2': 'Kulingana na lini na katika muktadha gani unakamilisha BEVI, inawezekana kwamba unaweza kupewa fursa ya kukagua ripoti kuhusu majibu yako. Ikiwa ndivyo, unaweza kuhitajika kukubali kutenganisha masharti kabla ya kuweza kusoma ripoti hii. Pia inawezekana kwamba unaweza kuombwa kushiriki katika kikao ambapo ripoti ya kikundi itajadiliwa; ripoti hii inaweza kujumuisha data ya BEVI kutoka kwa kikundi kikubwa ambacho unaweza kuwa mshiriki. Kama sehemu ya mradi wa tathmini ya BEVI, inawezekana pia kwamba unaweza kuulizwa kukamilisha kiasi kingine (k.m., chaguo nyingi) au ubora (k.m., majibu yaliyoandikwa kwa maswali) na/au kutoa maelezo ya ziada kupitia mahojiano ya maandishi au ya mdomo. maswali. Utaratibu huu unaweza kuhusisha majadiliano ya kufuatilia na msimamizi au mkalimani wa kipimo fulani. Kushiriki kwako katika michakato kama hii ni kwa hiari na unaweza kuchagua kuacha kushiriki wakati wowote. Kama ilivyobainishwa hapo juu, majibu yako binafsi katika ripoti yoyote ya kikundi au hatua nyingine hayataunganishwa nawe. Hatimaye, unaweza pia kupata fursa ya kushiriki katika kipindi cha maoni baada ya kukamilika kwa BEVI au hatua nyingine za kiasi au ubora kama ilivyoonyeshwa hapo juu.',
 'app.consent.understand.agree': 'Ikiwa unaelewa na kukubaliana na maelezo na miongozo iliyo hapo juu, na kwa hiari yako ukubali kukamilisha BEVI na kushiriki katika matumizi moja au zaidi ambapo BEVI inatumika (k.m., kama ilivyoelezwa hapo juu) , tafadhali bofya kitufe cha \'Kubali\'.',
 'app.consent.have.questions': 'Ikiwa una maswali yoyote, tafadhali jisikie huru kuwasiliana nasi kwa <a href="mailto:info@thebevi.com" style="color:Black">info@thebevi.com </a>',
 'app.consent.thanks': 'Asante kwa ushiriki wako.',
 'app.consent.giving.consent': 'Utoaji wa Idhini',
 'app.consent.read.and.agree': 'Nimesoma kuhusu BEVI na fomu hii ya idhini na ninaelewa kile ninachoombwa kama mshiriki. Ninakubali kwa uhuru kushiriki. Nimesoma maelezo ya hapo juu ya mradi huu na haki zangu kama mshiriki. Kwa kubofya kitufe cha Kubali hapa chini, na kukamilisha na kuwasilisha utafiti huu wa siri mtandaoni, ninakubali kushiriki.',
 'app.consent.agree': 'Kubali',
 'app.consent.disagree': 'Sikubaliani',
 'app.custom_agreement.modal_title': 'Ripoti ya makubaliano ya ukaguzi',
    'app.custom_agreement.modal_explanation_text': 'Tafadhali hariri maandishi ya makubaliano maalum kabla ya kuyawasilisha.',
 'app.custom_agreement.default_custom_agreement': 'Mpendwa Mshiriki wa BEVI:<br/><br/>Tayari umetoa kibali cha kuchukua BEVI, lakini kabla hatujafanya kazi nawe kwenye ripoti yako ya BEVI, tunakuomba ukubali 1) kwamba unataka kushiriki katika kipindi kimoja au zaidi ambapo ripoti yako binafsi itajadiliwa nawe na 2) kwamba unaidhinisha <strong>[hapa tunahitaji kuwa na uwezo wa kujaza jina moja au zaidi la watu binafsi]</strong > kukagua ripoti yako binafsi (k.m., maelezo yako, alama) na kujadili maana au athari za ripoti yako ya BEVI nawe.<br/>Bila shaka, kama ilivyoonyeshwa katika fomu ya idhini ya BEVI, matokeo yako binafsi yatasalia kuwa siri. Ikiwa una maswali yoyote, au unahitaji maelezo zaidi, tafadhali wasiliana na msimamizi wa BEVI ambaye anafanya kazi nawe.<br/><br/>Ikiwa unaelewa na kukubaliana na masharti haya mawili hapo juu, tafadhali bofya kitufe cha "kukubali" hapa chini. Asante kwa shauku yako katika BEVI!',
'app.custom_agreement.partner_report_agreement': `
 <h5>Tayari umetoa idhini ya kuchukua BEVI, lakini kabla hatujafanya kazi nawe kuhusu ripoti yako moja au zaidi ya BEVI (k.m., kwa madhumuni ya kufundisha, ushauri, ushauri, n.k.), tunakuuliza kama unakubali maelezo yafuatayo: </ h5>
 <br />

 <p>
 1. Ninaelewa kuwa kuna aina kadhaa za ripoti za BEVI ambapo matokeo yangu yanaweza kujadiliwa nami na mtu ambaye ameidhinishwa na BEVI: 1) Ripoti ya Mtu Binafsi, 2) Ripoti ya Mabadiliko ya Mtu Binafsi, 3) Ripoti ya Mshirika, na 4) Ripoti ya Mabadiliko ya Mshirika. Pia ninaelewa kwamba lazima nitoe ruhusa kwa mtu mwingine yeyote kukagua na kujadili ripoti yangu moja au zaidi nami.
 </p>
 <p>
 2. Kwa kubofya kitufe cha Kubali kilicho hapa chini, ninaidhinisha mtu au watu binafsi wafuatao walioidhinishwa na BEVI, <b>[#USERS#]</b>, kukagua na kujadili ripoti yangu moja au zaidi nami.
 </p>
 <p>
 3. Pia ninaelewa na kukubali kwamba:
 <p style="padding-left: 20px;">
 a) Ninawaidhinisha mshirika au washirika wafuatao, <b>[#PARTNERS#]</b>, kukagua na kujadili ripoti/matokeo yangu, na ripoti/matokeo yao, na mimi, na mtu binafsi au watu binafsi walioidhinishwa na BEVI. ambayo nimeidhinisha hapo juu;
 </p>
 <p style="padding-left: 20px;">
 b) Sitashiriki au kujadili Ripoti yoyote ya Mshirika au Ripoti ya Mabadiliko ya Washirika kwa ujumla au sehemu - au kwa njia yoyote (k.m., kupitia barua pepe, kutuma, kusambaza, kuonyesha, kunakili, n.k.) - na mtu yeyote isipokuwa mshirika wangu( s) na mtu/watu walioidhinishwa na BEVI ambao wameorodheshwa hapo juu; chochote kisichoambatana na masharti haya kitakubaliwa kwa maandishi na mimi na watu wote walioorodheshwa hapa - mshirika au washirika na Mtu au watu binafsi Walioidhinishwa na BEVI;
 </p>
 <p style="padding-left: 20px;">
 c) mshirika au washirika ambao nimeidhinisha hapo juu wanaweza kufuta Ripoti zao za Washirika au Ripoti za Mabadiliko ya Washirika wakati wowote kupitia kubadilisha kubadilisha ruhusa ya wasifu katika dashibodi yao ya BEVI, jambo ambalo litaondoa ripoti kutoka kwa BEVI yao na dashibodi yangu.
 </p>
 </p>
 <p>
 4. Pamoja na masharti yaliyo hapo juu, vifungu vingine vyote vilivyoorodheshwa katika Fomu ya Idhini ya BEVI
 itaendelea kutumika (k.m., hali ya hiari ya ushiriki wangu, nk.). Ikiwa una maswali yoyote, au unahitaji maelezo zaidi, tafadhali wasiliana na mtu aliyeidhinishwa na BEVI au watu binafsi ambao wanafanya kazi nawe.
 </ p>
 <p>
 5. Ikiwa unakubaliana na maelezo yote yaliyo hapo juu, tafadhali bofya, "Nakubali," hapa chini. Ikiwa hukubaliani na maelezo yote yaliyo hapo juu, tafadhali bofya, "Sikubali," hapa chini.
 </ p>
 <center>Asante kwa shauku yako katika BEVI!</center>
 `,
}



