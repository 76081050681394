import { AppMessages } from '../types';

export const appEN: AppMessages = {
    'app.unknown_error': 'An unknown error has occurred, please contact those responsible.',
    'app.btn_start': 'Start',
    'app.btn_restart': 'Restart',
    'app.btn_cancel': 'Cancel',
    'app.btn_next': 'Next',
    'app.btn_ok': 'Ok',
    'app.btn_confirm' : 'Confirm',
    'app.btn_finish': 'Finish',
    'app.btn_back': 'Back',
    'app.dropdown_select': 'Select...',
    'app.loading': 'Loading',
    'app.response_yes': 'Yes',
    'app.response_no': 'No',
    'app.bevi_copyright_text_1': 'The Beliefs, Events, and Values Inventory or BEVI is a copyrighted inventory. The BEVI and BEVI reports, items, item content, or scales may not be modified, copied, disseminated, or published, in whole or part, without the written and express permission of Craig N. Shealy, Ph.D.',
    'app.bevi_copyright_text_2': '',
    'app.empty.data': 'No data',
    'app.systemerro': 'Oh no! The system is showing instability, try again in a few minutes.',
    'app.loading_document': 'Loading document...',
    'app.download_document': 'Download PDF',
    'app.download_excel': 'Download XLSX',
    'app.btn_search': 'Search',
    'app.english': 'English',
    'app.catalan': 'Catalan',
    'app.spanish': 'Spanish',
    'app.japanese': 'Japanese',
    'app.chinese-t': 'Traditional Chinese',
    'app.chinese-s': 'Simplified Chinese',
    'app.true': 'True',
    'app.false': 'False',
    'app.save': 'Save',
    'app.btn_reset': 'Reset',
    'app.btn_submit': 'Submit',
    'app.search_placeholder': 'Type search text',
    'app.notification.error_title': 'Error',
    'app.error.user_not_found': 'User not found',
    'app.save_with_success': 'Save with Success',
    'app.swahili': 'Swahili',
    'app.german': 'German',
    'app.hindi': 'Hindi'
}
