import { UsersMessages } from '../types';

const users: UsersMessages = {
    'app.users.listuser.title': 'ユーザーの管理',
    'app.users.name': 'ユーザー名',
    'app.users.login': 'メールアドレス',
    'app.users.status.inactive': 'Inactive',
    'app.users.status.active': 'Active',
    'app.users.status': '状態',
    'app.create_batch_user.excel.email_column': 'Email',
    'app.create_batch_user.excel.line_number_column': 'Line number',
    'app.create_batch_user.excel.user_error_column': 'Error',
    'app.create_batch_user.excel.download_error_details': 'Download details',
    'app.create_batch_user.error_already_exists': 'User already exists',
    'app.create_batch_user.error_invalid_line': 'Invalid line',
    'app.create_batch_user.error_wrong_email': 'User with wrong email',
    'app.create_batch_user.error_missing_name': 'Missing user name',
    'app.create_batch_user.error_details_filename': 'Error details',
    'app.user.organization': '大学/機関',
    'app.user.select_organization': '大学/機関の選択',
    'app.user.select_program': 'プログラムの選択',
    'app.user.program': 'プログラム',
    'app.users.listuser.action': '選択 ',
    'app.users.listuser.details': 'ユーザーの管理',
    'app.users.listuser.reset_password': 'パスワードのリセット',
    'app.users.listuser.programs_action': 'プログラム',
    'app.users.add_new_user': '新規ユーザーの作成',
    'app.user_programs.programName': 'プログラム名',
    'app.user_programs.subprogramName': 'グループ名',
    'app.user_programs.status': '状態',
    'app.user_programs.completedOn': '完了日',
    'app.user_programs.invalid_reason': '無効原因',
    'app.listorganizations.input_username_placeholder': 'ユーザー名を記入',
    'app.listorganizations.input_useremail_placeholder': 'メールアドレスを記入',
    'app.create.batch_user_error': '複数受検者を作成する際、エラーが発生しました。詳細については、エラーログをダウンロードしてください。',
    'app.create.user.title': '新規受検者/プログラム管理者の作成',
    'app.create.user.single.user': 'メールアドレスを入力しユーザー（受検者/プログラム管理者）を作成',
    'app.create.user.send_welcome_email': 'ウェルカムメールを送信する',
    'app.create.user.multiple.users': 'メールアドレスをアップロードし複数受検者を作成',
    'app.create.user.choose.option.new.user': '以下から選択',
    'app.create.user.language_codes': '言語コード',
    'app.create.user.download.template.file': 'テンプレート･ファイルのダウンロード',
    'app.create.user.download.data.example': 'Download',
    'app.create.user.upload.file': 'Upload',
    'app.create.user.drag.n.drop.text': 'ファイルをドラッグ＆ドロップするか、クリックしてファイルを選択してください',
    'app.create.user.dran.n.drop.available.files': '(.xlsxおよび.xlsファイルのみ)',
    'app.create.user.remove': '削除',
    'app.create.user.selected.file': '選択: ',
    'app.create.user.create.multiple.user.title': '複数受検者の作成',
    'app.create.user.create.single.user.title': 'ユーザー作成',
    'app.create.user.table.column_actions': '削除',
    'app.create.user.edit.single.user.title': 'ユーザーの管理',
    'app.create.user.is.anonymous': 'Is Anonymous',
    'app.create.user.user.type': 'ユーザーの種類',
    'app.create.user.language': 'メールの言語',
    "app.create.user.capability_visualize_user": "ユーザーを表示する",
    "app.create.user.capability_add_user": "ユーザーを追加する",
    "app.create.user.capability_reset_password_user": "パスワードをリセットする - ユーザー",
    "app.create.user.capability_manage_user": "ユーザーを管理する",
    "app.create.user.capability_visualize_user_programs": "ユーザープログラムを表示する",
    "app.create.user.capability_visualize_program_and_experience": "プログラムとエクスペリエンスを表示する",
    "app.create.user.capability_add_program_and_experience": "プログラムとエクスペリエンスを追加する",
    "app.create.user.capability_rename_program_and_experience": "プログラムとエクスペリエンスを名前変更する",
    "app.create.user.capability_add_subprogram": "サブプログラムを追加する",
    "app.create.user.capability_edit_subprogram": "サブプログラムを編集する",
    "app.create.user.capability_manage_subprogram": "サブプログラムを管理する",
    "app.create.user.capability_show_qr_code_invite_new_users_subprogram": "QRコードを表示して新しいユーザーを招待する - サブプログラム",
    "app.create.user.capability_visualize_report": "レポートを表示する",
    "app.create.user.capability_add_report": "レポートを追加する",
    'app.user.profile.participant': '受検者',
    'app.user.profile.programAdmin': 'プログラム管理者',
    'app.user.profile.institutional': '機関管理者',
    'app.user.profile.accountManager': 'Account Manager',
    'app.user.profile.superAdmin': 'Super Admin',
    'app.user.language.message': '(ユーザーがメールを受信する言語)',
    'app.user.successfulMessage': 'ユーザーが作成されました。',
    "app.user.updateSuccessfulMessage": "ユーザーが正常に更新されました",
    'app.edit.user.confirmation_title_fail': '更新失敗',
    'app.edit.user.confirmation_body_fail': 'ユーザーの更新に失敗しました。入力データ、インターネット接続を確認し、再度試してみてください。問題が解決しない場合は、アカウン･トマネージャーに相談してください。',
    'app.edit.user.confirmation_body_fail_already_exists': 'メールアドレスが既に使用されているため、ユーザーを更新することができませんでした。',
    'app.edit.user.confirmation_body_fail_wrong_email': 'メールアドレスが無効なため、ユーザーを更新することができませんでした。',
    'app.edit.user.confirmation_title_success': '更新',
    'app.edit.user.confirmation_body_success': 'ユーザーが更新されました。レポートを作成することができます。',
    'app.create.user.confirmation_title_fail': '作成失敗',
    'app.create.user.confirmation_body_fail': 'ユーザーを作成できません。入力データ、インターネット接続を確認し、再度試してみてください。問題が解決しない場合は、アカウント･マネージャーに相談してください。',
    'app.create.user.confirmation_title_success': '作成',
    'app.create.user.confirmation_body_success': 'ユーザーが作成されました。',
    'app.create.user.confirmation_body_fail_already_exists': '他のユーザーにより、このメールアドレスが既に使用されているため、ユーザーを作成することができませんでした。',
    'app.create.user.confirmation_body_fail_wrong_email': 'メールアドレスが無効なため、ユーザーを作成することができませんでした。',
    'app.reset_password.title': 'パスワードのリセット',
    'app.reset_password.body': '「パスワードのリセット」をクリックすると、{username}のパスワードを一時的にリセットします。ユーザーは、次回システムにログインした際に、パスワードを変更することができます。',
    'app.reset_password.successful_message': '<b>{username}</b> のパスワードがリセットされました。',
    'app.reset_password.error_message': '問題が発生しました。数分後にもう一度お試しください。',
    'app.reset_password.new_temporary_password': '新しい仮パスワード',
    'app.reset_password.new_password': '新しいパスワード',
    'app.reset_password.button_reset': 'リセット',
    'app.users_type.select': 'Select a User Type',
    "app.users_type.all": "All",
    "app.users_type.participant": "Participant",
    "app.users_type.program_admin": "Program Administrator",
    "app.users_type.institutional": "Institutional",
    "app.users_type.account_manager": "Account Manager",
    "app.users_type.super_admin": "Super Administrator",
    "app.users_type.external_partner": "External Partner",
    "app.users.listuser.clear_2fa": "二要素認証をクリア",
    "app.users.confirm_deletion_body": "2FA削除プロセスを強制しますか？ 強制する場合、ユーザーはGoogle認証システムにコードを再入力するように求められます。"
}

export const usersJA: UsersMessages =
    { ...users }
