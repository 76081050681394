import { TimeMessages } from '../types';

export const timeSW: TimeMessages = {
	'app.time.day': 'siku',
	'app.time.days': 'siku',
	'app.time.hour': 'saa',
	'app.time.hours': 'Masaa',
	'app.time.minute': 'dakika',
	'app.time.minutes': 'dakika',
	'app.time.second': 'sekunde',
	'app.time.seconds': 'sekunde',
	'app.time.and': 'na',
}
