import { LoginPageMessages } from '../types';

export const loginPageSW: LoginPageMessages = {
	'app.login.text_welcome': 'Karibu kwenye Orodha ya Imani, Matukio na Maadili (BEVI)',
	'app.login.text_information': 'Ili kufikia tovuti hii, lazima uwe umealikwa kufanya hivyo na taasisi, shirika, au msimamizi aliyeidhinishwa na BEVI.',
	'app.login.text_login_requirement': 'Tafadhali toa jina la mtumiaji na nenosiri linalohitajika (uliyopewa).',
	'app.login.lbl_username': 'Jina la mtumiaji:',
	'app.login.lbl_password': 'Nenosiri:',
	'app.login.btn_submit': 'Ingia',
	'app.login.lbl_new_password': 'Nenosiri jipya',
	'app.login.lbl_new_password_confirmation': 'Weka tena Nenosiri',
	'app.login.btn_submit_new_password': 'Badilisha Nenosiri',
	'app.login.placeholder.login': 'baruapepe@mfano.com',
	'app.login.placeholder.password': 'nenosiri lako',
	'app.login.invalid_credentials': 'La! Kitambulisho chako hakionekani kulingana na rekodi zetu. Tafadhali, jaribu tena au weka upya akaunti yako kwa kubofya Umesahau Nenosiri hapa chini.',
	'app.login.forgot_password.invalid_credentials': 'Barua pepe si sahihi. Tafadhali jaribu tena.',
	'app.login.forgot_password': 'Umesahau Nenosiri?',
	'app.login.forgot_password_confirmation_title': 'Weka upya nenosiri',
	'app.login.forgot_password_confirmation_body': 'Barua pepe imetumwa kwa anwani ya barua pepe iliyotolewa. Fuata maagizo katika barua pepe ili kubadilisha nenosiri lako.',
	'app.login.btn_submit_forgot_password': 'Rejesha nenosiri',
	'app.login.passwords_dont_match': 'Nenosiri hazilingani.',
	'app.login.change_password.expired_confirmation_title': 'Ukurasa ulioisha muda wake',
	'app.login.change_password.expired_confirmation_body': 'Ruhusa yako ya kufikia ukurasa umeisha muda wake. Tafadhali omba ruhusa ya kufikia tena au ingia ili kufikia BEVI.',
	'app.login.change_password.success_confirmation_title': 'Nenosiri limesasishwa',
	'app.login.change_password.success_confirmation_body': 'Nenosiri lako limebadilishwa kwa mafanikio. Tumia nenosiri lako jipya kuingia kwenye ukurasa wa nyumbani, ambapo utaelekezwa kwingine.',
	'app.login.first_access.title': 'Tafadhali, badilisha nenosiri lako kabla ya kutumia mfumo.',

	'app.login.auth.qrcode': 'Skena Msimbo huu wa QR kwa Google Authenticator',
	'app.login.auth.type': 'Weka Msimbo wa Google Authenticator ili kuthibitisha',
	'app.login.auth.backLogin': 'Rudi kwenye Kuingia',
	'app.login.auth.invalid_code': 'Msimbo batili',
	'app.login.auth.validate_success': 'Uthibitishaji wa Authenticator umekamilika kwa mafanikio'
}
