import { DiscursiveQuestionsPageMessages } from '../types';

export const discursiveQuestionsSW: DiscursiveQuestionsPageMessages = {
	'app.discursive_questions.introduction': 'Unapotafakari juu ya uzoefu ambao unakamilisha BEVI, tafadhali jibu maswali matatu yafuatayo.',
	'app.discursive_questions.question1': '1. Ni tukio gani au hali gani ya matumizi yako yalikuwa na athari kubwa kwako na kwa nini?',
	'app.discursive_questions.question2': '2. Je, kulikuwa na baadhi ya vipengele vya "ubinafsi" wako au "utambulisho" wako (k.m., jinsia, kabila, mwelekeo wa kijinsia, asili ya kidini au kisiasa, n.k.) ambavyo vilikuwa wazi au muhimu kwako au wengine kutokana na uzoefu huu?' ,
	'app.discursive_questions.question3': '3. Umejifunza nini au wewe ni tofauti kivipi kutokana na uzoefu huu?',
	'app.discursive_questions.thank_you': 'Asante kwa kukamilisha BEVI!',
	'app.discursive_questions.please_note': 'Tafadhali kumbuka kuwa:',
	'app.discursive_questions.go_to_report': 'Ikiwa unataka kuona ripoti yako sasa, tafadhali bofya kitufe kilicho hapa chini. Pia unaweza kukagua ripoti yako baadaye katika dashibodi yako.',
	'app.discursive_questions.do_not_show_report': 'Kwa bahati mbaya, kwa sababu kadhaa zinazowezekana (k.m., majibu yalikosekana kwa maswali mengi), hatuwezi kutoa ripoti yako. Ukipenda, unaweza kujaribu kuchukua BEVI tena.',
	'app.discursive_questions.visualize_report': 'Soma Ripoti',
}
