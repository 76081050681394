import { FinancialReportMessages } from '../types';

export const financialReportSW: FinancialReportMessages = {
	'app.financialreport.title': 'Ripoti ya Matumizi',
	'app.financialreport.organization_lbl': 'Shirika',
	'app.financialreport.start_data_lbl': 'Tarehe ya kuanza',
	'app.financialreport.end_data_lbl': 'Tarehe ya mwisho',
	'app.financialreport.created_forms_title': 'IDADI YA WALIOALIKWA KUCHUKUA BEVI',
	'app.financialreport.finished_forms_title': 'IDADI ILIYOKAMILISHA BEVIS',
	'app.financialreport.valid_forms_title': 'IDADI YA BEVIS INAYOFAA ',
	'app.financialreport.invalid_forms_title': 'IDADI YA BEVIS ISIYOFAA ',
	'app.financialreport.unanswered_forms_title': 'IDADI YA BEVIS AMBAZO HAZIJAJIBIWA',
	'app.financialreport.error_header': 'Hitilafu',
	'app.financialreport.error_text': 'Hitilafu imetokea na data ya ripoti haikuweza kupakiwa, tafadhali jaribu tena.'
}
