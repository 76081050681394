import { CreateProgramMessages } from '../types';

export const createProgramSW: CreateProgramMessages = {
	'app.create_program.btn_submit': 'Hifadhi',
	'app.create_program.btn_submit_and_send_email': 'Hifadhi/Tuma Barua pepe',
	'app.create_program.btn_unselect_all_users': 'Ondoa wote',
	'app.create_program.select_individual_manually': 'Chagua Watu Binafsi',
	'app.create_program.table.column_name': 'Jina',
	'app.create_program.table.column_email': 'Barua pepe',
	'app.create_program.table.column_actions': 'Ondoa Watu Binafsi',
	'app.create_program.table.column_get_last_users': 'Watu kutoka kwa usimamizi uliopita.',
	'app.create_program.tooltip.action_remove': 'Ondoa',
	'app.create_program.tooltip.btn_get_last_users': 'Leta watu binafsi kutoka kwa utawala uliopita.',
	'app.create_program.tooltip.btn_download_users': 'Pakua / dhibiti nani atachukua BEVI.',
	'app.create_program.tooltip.btn_download_users_empty': 'Pakua / dhibiti nani atachukua BEVI(Kiolezo Tupu).',
	'app.create_program.excel.name_column': 'Jina',
	'app.create_program.excel.login_column': 'Barua pepe',
	'app.create_program.excel.active_column': 'Inatumika',
	'app.create_program.confirmation_title_success': 'Imeundwa kwa ufanisi',
	'app.create_program.confirmation_body_success_program': 'Programu au uzoefu sasa umeundwa. Unaweza kuikagua au kuidhibiti kwa kubofya "sawa" hapa chini.',
	'app.create_program.confirmation_body_success_subprogram': 'Umeunda na kuupa jina usimamizi wa BEVI. Unaweza kuikagua au kuidhibiti kwa kubofya "sawa" hapa chini.',
	'app.create_program.confirmation_title_fail': 'La! Kwa bahati mbaya, hatukuweza kuiunda',
	'app.create_program.confirmation_body_fail_program': 'Programu haikuweza kuundwa. Tafadhali, kagua data iliyotolewa, muunganisho wa intaneti na ujaribu tena. Tatizo likiendelea, zungumza na msimamizi wa akaunti.',
	'app.create_program.confirmation_body_fail_subprogram': 'Programu Ndogo haikuweza kuundwa. Tafadhali, kagua data iliyotolewa, muunganisho wa intaneti na ujaribu tena. Tatizo likiendelea, zungumza na msimamizi wa akaunti.',
	'app.edit_sub_program.confirmation_title_success': 'Imesasishwa kwa mafanikio',
	'app.edit_sub_program.confirmation_body_success': 'Kikundi kilisasishwa na sasa unaweza kukiona katika uorodheshaji wa programu za shirika, ambapo unaelekezwa kwingine.',
	'app.edit_sub_program.confirmation_title_fail': 'La! Kwa bahati mbaya, hatukuweza kuisasisha',
	'app.edit_sub_program.confirmation_body_fail': 'Programu Ndogo haikuweza kusasishwa. Tafadhali, kagua data iliyotolewa, muunganisho wa intaneti na ujaribu tena. Tatizo likiendelea, zungumza na msimamizi wa akaunti.',
	'app.create_program.lbl_associate_users': 'Chagua watu binafsi kuchukua BEVI.',
	'app.create_program.lbl_organization_name': 'Jina la Shirika',
	'app.create_program.lbl_program_name': 'Jina la Mpango au Uzoefu',
	'app.create_program.lbl_sub_program_name': 'Jina la usimamizi wa BEVI',
	'app.create_program.lbl_year': 'Mwaka',
	'app.create_program.lbl_due_date': 'Tarehe ya Mwisho ya usimamizi wa BEVI',
	'app.create_program.lbl_alert_all_users': 'Watahadharishe watu wote kwa barua pepe',
	'app.details_sub_program.progress': 'Maendeleo ya Kikundi',
	'app.details_sub_program.participant_details': 'Maelezo ya Mshiriki',
	'app.details_sub_program.remind_alert': 'Kumbusha Arifa',
	'app.details_sub_program.remind_alert_text': 'Arifa ya ukumbusho iliratibiwa na itatumwa baada ya dakika chache.',
	'app.create_program.lbl_email_message': 'Andika ujumbe hapa chini ambao utatumwa kwa anwani za barua pepe za washiriki wote binafsi.',
	'app.create_program.tooltip.btn_upload_users': 'Pakia nani atachukua BEVI.',
	'app.create_program.upload_files_error_title': 'Hitilafu ya Kupakia Faili',
	'app.create_program.upload_rejected_files_error_body_prefix': 'Faili zifuatazo zilikataliwa kwa sababu haziko katika umbizo unaotarajiwa:',
	'app.create_program.upload_parsed_files_error_body_prefix': 'Faili inatarajiwa kuwa na safu wima {0}, {1} na {2}. Faili zifuatazo hazikusomwa ipasavyo:',
	'app.create_program.upload_parsed_files_error_body': '{0} ina {1} makosa.',
	'app.create_program.upload_parsed_files_unknow_error_body_prefix': 'Hitilafu katika kujaribu kuchanganua faili zifuatazo:',
	'app.create_program.select_individuals_title': 'Chagua watu binafsi ili kutumia BEVI kupitia mojawapo ya mbinu tatu:',
	'app.create_program.import_individuals_from_previous_administration': '1. Ingiza watu binafsi kutoka kwa utawala uliopita.',
	'app.create_program.download_manage_who_will_take_the_bevi': '2. Pakua / dhibiti ni nani atachukua BEVI',
	'app.create_program.upload_who_will_take_the_bevi': ', na kisha upakie nani atachukua BEVI',
	'app.create_program.add_individuals_manually': '3. Ongeza watu binafsi/mmoja baada ya mmoja kwa kubofya menyu kunjuzi hapa chini.',
}
