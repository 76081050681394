import { AppMessages } from '../types';

export const appDE: AppMessages = {
    'app.unknown_error': 'Ein unbekannter Fehler ist aufgetreten. Bitte kontaktieren Sie die Verantwortlichen.',
    'app.btn_start': 'Start',
    'app.btn_restart': 'Neustart',
    'app.btn_cancel': 'Abbrechen',
    'app.btn_next': 'Weiter',
    'app.btn_ok': 'Ok',
    'app.btn_confirm' : 'Bestätigen',
    'app.btn_finish': 'Fertig',
    'app.btn_back': 'Zurück',
    'app.dropdown_select': 'Auswählen...',
    'app.loading': 'Lädt',
    'app.response_yes': 'Ja',
    'app.response_no': 'Nein',
    'app.bevi_copyright_text_1': 'Das Beliefs, Events, and Values Inventory (Überzeugungen, Ereignisse, und Werte Selbstberichtsinventar) oder kurz BEVI ist ein urheberrechtlich geschützter Test. Das BEVI und die BEVI-Berichte, Items, Item-Inhalte oder Skalen dürfen ohne die schriftliche und ausdrückliche Genehmigung von Craig N. Shealy, Ph.D., weder ganz noch teilweise verändert, kopiert, verbreitet oder veröffentlicht werden. Shealy, Ph.D.',
    'app.bevi_copyright_text_2': '',
    'app.empty.data': 'Keine Daten',
    'app.systemerro': 'Ups! Das System ist instabil, versuchen Sie es in ein paar Minuten erneut.',
    'app.loading_document': 'Dokument lädt...',
    'app.download_document': 'PDF downloaden',
    'app.download_excel': 'XLSX downloaden',
    'app.btn_search': 'Suchen',
    'app.english': 'Englisch',
    'app.catalan': 'Katalanisch',
    'app.spanish': 'Spanisch',
    'app.japanese': 'Japanisch',
    'app.chinese-t': 'Traditionelles Chinesisch',
    'app.chinese-s': 'Vereinfachtes Chinesisch',
    'app.true': 'Richtig',
    'app.false': 'Falsch',
    'app.save': 'Speichern',
    'app.btn_reset': 'Zurücksetzen',
    'app.btn_submit': 'Absenden',
    'app.search_placeholder': 'Suchtext hier einfügen',
    'app.notification.error_title': 'Fehler',
    'app.error.user_not_found': 'Benutzer nicht gefunden',
    'app.save_with_success': 'Erfolgreich gespeichert',
    'app.swahili': 'Suaheli',
    'app.german': 'Deutsch',
    'app.hindi': 'Hindi'
}
